const graduation = [
    {
        label:'Sem sequelas',
        value: 0,
    },
    {
        label:'10% - Residual',
        value: 10,
    },
    {
        label:'25% - Leve',
        value: 25,
    },
    {
        label:'50% - Média',
        value: 50,
    },
    {
        label:'75% - Intensa',
        value: 75,
    },
    {
        label:'100% - Total',
        value: 100,
    },
]

export { graduation }
