const components = [
  {
    value: 'text',
    label: 'Texto',
  },
  {
    value: 'textarea',
    label: 'Area de Texto',
  },
  {
    value: 'number',
    label: 'Numérico',
  },
  {
    value: 'decimal',
    label: 'Numérico com Decimal',
  },
  {
    value: 'imc',
    label: 'IMC',
  },
  {
    value: 'imc_gestacional',
    label: 'IMC Gestacional',
  },
  {
    value: 'select',
    label: 'Seleção',
  },
  {
    value: 'multiselect',
    label: 'Seleção múltipla',
  },
  {
    value: 'radio',
    label: 'Grupo com seleção única',
  },
  {
    value: 'checkbox',
    label: 'Grupo com seleção múltipla',
  },
  {
    value: 'date',
    label: 'Data',
  },
  {
    value: 'time',
    label: 'Horário',
  },
  {
    value: 'datetime',
    label: 'Data e Hora',
  },
  {
    value: 'physicalexam',
    label: 'Exame Físico',
  },
  {
    value: 'simple_date',
    label: 'Data Simples',
  },
]

const needOptions = ['select', 'radio', 'multiselect', 'checkbox']

const needMask = ['number', 'text']

const typeMetrics = [
  {
    value: 'bolt',
    label: 'Bolt',
  },
  {
    value: 'media',
    label: 'Média simples',
  },
  {
    value: 'somatorio',
    label: 'Somatório',
  },
  {
    value: 'min',
    label: 'Menor valor',
  },
  {
    value: 'max',
    label: 'Maior valor',
  },
]

const typeText = 'label';

export { components, needOptions, needMask, typeMetrics, typeText }
