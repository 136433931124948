import { useMutation } from 'react-query'
import { createProjectToken } from 'api/services/project'

export const useCreateProjectToken = (projectId, options = {}) => {
  const { onSuccess = () => {} } = options

  return useMutation((data) => createProjectToken(projectId, data), {
    ...options,
    onSuccess: (data) => {
      onSuccess(data)
    },
  })
}
