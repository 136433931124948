import React, { useState, useEffect } from 'react'
import ExamTabs from './components/ExamTabs'
import AvatarBody from './components/AvatarBody'

import Informations from "../../pages/builder/informations/Informations";
import { makeStyles } from '@material-ui/core/styles'
import {Box, Button, Toolbar} from "@material-ui/core";
import {useFormContext} from "react-hook-form";
import { isEmpty } from 'lodash';
import { bodyParts } from './enum/bodyParts';
import { useBreakpoint } from 'use-breakpoint';

const BREAKPOINTS = { responsive: 0, desktop: 982 }

const PhysicalExam = ({id, isPreview, helperText, defaultAnswers = []}) =>{
    const [items, setItems] = useState([]);
    const [tabsOpen, setTabsOpen] = useState(false);
    const {getValues, setValue, unregister} = useFormContext();
    const classes = useStyles()

    const { breakpoint } = useBreakpoint(
        BREAKPOINTS,
        'desktop',
    )

    console.log('bp', breakpoint);

    const addItems = (item) => {
        if ( items.find(it => it.name == item.name) ) {
            return null;
        } else {
            setItems([item, ...items])
			setTabsOpen(true);
        }
    }
    const removeItems = (itemName, tag) => {
        setItems( prevItems => prevItems.filter(item => item.name !== itemName));
        unregister(`${id}.${tag}`);
		if(items.length === 0) setTabsOpen(false);
    }

    useEffect(() => {
        if (defaultAnswers && !isEmpty(defaultAnswers)) {
            setValue(id, defaultAnswers[id])
            const avatarParts = defaultAnswers[id]
            if (avatarParts) {
                const avatarKeys = Object.keys(avatarParts);
                const itemsToAdd = avatarKeys.map(val => {
                    const part = bodyParts.find(bp => bp.id == val)
                    if (part) return {
                        name: part.label,
                        component: <part.component id={id} segment={part.id} segmentName={part.label} removeItems={removeItems} />
                    }
                })
                setItems(itemsToAdd);
            }
        }
    }, [id, defaultAnswers, getValues, setValue])

	const tabsHandler = {
		isOpen: tabsOpen,
		setOpen: setTabsOpen
	}

	const portalStyle = {
		position: 'absolute',
        left: '-62px',
        top: '120px'
	}

    const showAvatar = (!tabsOpen && breakpoint == 'responsive') || breakpoint == 'desktop'
    const showFields = (tabsOpen && breakpoint == 'responsive') || breakpoint == 'desktop'

    return (
        <>
        <Toolbar style={{fontFamily:'Roboto, sans-serif', fontWeight: '300'}}>Abaixo, descreva o exame realizado:</Toolbar>
        <div id="oi" style={{textAlign:"center", position: 'relative'}} className={classes.wrapper}>
            { showAvatar && <AvatarBody id={id} isPreview={isPreview} addItems={addItems} removeItems={removeItems} tabsHandler={tabsHandler}/> }
            {
                !isPreview && <>
                    {
                        showFields ?
                            <ExamTabs items={items} tabsHandler={tabsHandler} />
                        : <Box style={portalStyle}>
							{
								items.length > 0 &&
									<Button size='small' color='primary' className="form-button" variant='contained' onClick={() => setTabsOpen(true)}>
										Formularios ({ items.length })
									</Button>
							}
						</Box>
                    }
                </>
            }
        </div>
        </>
    )
}

const useStyles = makeStyles(() =>({
wrapper:{
    display: 'grid',
    gridTemplateColumns: '2fr 1fr',
    gridGap: '16px'
}
}))

export default PhysicalExam
