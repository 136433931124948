import { useState } from 'react'
import PropTypes from 'prop-types'
import { Tabs as MUITabs, Tab, AppBar } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import TabContent from './TabContent'

const Tabs = ({ items }) => {
  const classes = useStyles()
  const [value, setValue] = useState(0)

  return (
    <div className={classes.root}>
      <AppBar position='static' color='default'>
        <MUITabs
          value={value}
          onChange={(e, newValue) => setValue(newValue)}
          indicatorColor='primary'
          textColor='primary'
          variant='fullWidth'
          centered
        >
          {items.map((item, index) => (
            <Tab key={item.name} label={item.name} id={`tab-${index}`} />
          ))}
        </MUITabs>
      </AppBar>
      {items.map((item, index) => (
        <TabContent key={item.name} value={value} index={index}>
          {item.component}
        </TabContent>
      ))}
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    flexGrow: 1,
  },
}))

Tabs.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
}

Tabs.defaultProps = {}

export default Tabs
