import { useMutation, useQueryClient } from 'react-query'
import { createUser } from 'api/services/user'

export const useCreateUser = (options = {}) => {
  const queryClient = useQueryClient()
  const { onSuccess = () => {} } = options

  return useMutation(createUser, {
    ...options,
    onSuccess: (data) => {
      queryClient.invalidateQueries('users')
      onSuccess(data)
    },
  })
}
