import { createContext, useContext, useReducer } from 'react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { createPreferences, fetchPreferences, updatePreferences } from 'api/services/preferences'

const ACTION_TYPES = {
  VIEW: 'VIEW_PREFERENCE',
  ADD: 'ADD_PREFERENCE',
  UPDATE: 'UPDATE_PREFERENCE',
}

const preferencesReducer = (state, action) => {
  switch (action.type) {
    case ACTION_TYPES.VIEW: {
      return { preferences: action.data }
    }
    case ACTION_TYPES.ADD: {
      return { preferences: action.data }
    }
    case ACTION_TYPES.UPDATE: {
      return { preferences: action.data }
    }
    default: {
      return state
    }
  }
}

const PreferencesStateContext = createContext()
const PreferencesDispatchContext = createContext()

const PreferencesProvider = ({ children }) => {
  const { projectId } = useParams()
  const [state, dispatch] = useReducer(preferencesReducer, { preferences: [] })

  const { isLoading, isFetching } = useQuery(
    ['preferences', projectId],
    () => fetchPreferences(projectId),
    {
      onSuccess: (data) => {
        dispatch({ type: ACTION_TYPES.VIEW, data: data.data })
      },
    }
  )

  return (
    <PreferencesStateContext.Provider
      value={{ isLoading, isFetching, preferences: state.preferences }}
    >
      <PreferencesDispatchContext.Provider value={dispatch}>
        {children}
      </PreferencesDispatchContext.Provider>
    </PreferencesStateContext.Provider>
  )
}

PreferencesProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

const usePreferences = (projectId) => {
  const context = useContext(PreferencesStateContext)

  if (!context) {
    throw new Error('usePreferences must be used within an PreferencesProvider')
  }

  if (projectId) {
    const { preferences } = context
    return {
      ...context,
      preferences: preferences,
    }
  }

  return context
}

const useDispatch = () => {
  const dispatch = useContext(PreferencesDispatchContext)
  if (dispatch === undefined) {
    throw new Error('useDispatch must be used within a PreferencesProvider')
  }
  return dispatch
}

const useUpdatePreference = (projectId, options = {}) => {
  const dispatch = useDispatch()
  const { onError, onSuccess } = options

  return useMutation((data) => updatePreferences(projectId, data), {
    ...options,
    onSuccess: (data) => {
      dispatch({ type: ACTION_TYPES.UPDATE, data })
    },
    onError: (data) => {
      onError(data)
    },
  })
}

const useCreatePreference = (projectId, options = {}) => {
  const queryClient = useQueryClient()

  const dispatch = useDispatch()
  const { onError, onSuccess } = options

  return useMutation((data) => createPreferences(projectId, data), {
    ...options,
    onSuccess: (data) => {
      onSuccess(data)
      dispatch({ type: ACTION_TYPES.ADD, data })
      queryClient.invalidateQueries('preferences')
    },
    onError: (data) => {
      onError(data)
    },
  })
}

export { PreferencesProvider, usePreferences, useCreatePreference, useUpdatePreference }
