import { makeStyles } from '@material-ui/core/styles'
import { FormProvider } from 'react-hook-form'
import { Dialog } from 'components'

const ErroDialog = ({ isOpen, setOpen, errorMessage }) => {
  const classes = useStyles()

  const getErrors = (errorList) => { 
    let errorMessage = ''
    if (errorList.length > 0) 
      errorList.map(error => errorMessage += error)

    return errorMessage
  }

  return (
    <>
      <FormProvider>
        <Dialog
          title='Ocorreu um erro'
          contentText={getErrors(errorMessage)}
          open={isOpen}
          onCancel={() => setOpen(false)}
          submitDisabled={true}
          noSubmit={true}
        >
        </Dialog>
      </FormProvider>
    </>
  )
}

const useStyles = makeStyles(() => ({
  button: {
    display: 'flex',
    margin: '20px auto',
  },
}))

export default ErroDialog
