import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { Paper, Snackbar } from '@material-ui/core'
import { useForm, FormProvider } from 'react-hook-form'
import { useUpdateDevolutive, useRemoveDevolutive } from 'hooks/devolutives'
import { Accordion, Loader } from 'components'
import DevolutiveForm from './devolutive-form/DevolutiveForm'
import { Alert } from '@material-ui/lab'
import { useState } from 'react'

const Group = ({ item, isDragging, project }) => {
  const [toast, setToast] = useState({ open: false, severity: 'error', content: ''});

  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: item,
  })
  const { id, title } = item

  const { mutate: onSubmit, isLoading: isLoadingUpdate } = useUpdateDevolutive(id)
  const { mutate: onRemove, isLoading: isLoadingRemove } = useRemoveDevolutive(id)

  const classes = useStyles()


  const replaceSpecialCharacters = (value) => {
    const template = value.template
    const newTemplate = template?.replaceAll('≠', '!=')
      .replaceAll('≥', '>=')
      .replaceAll('≤', '<=')

    value.template = newTemplate
    
    return value;
  }

  const validateTags = (text) => {
    const tags = ["<strong>", "<italic>", "<stroke>", "<ul>", "<ol>", "<li>", "<u>"];
    const tagsRegex = new RegExp(tags.join("|"), "i");
    const regex = /{%(.*?)%}/g; 

    let found;
    let match;
    while ((match = regex.exec(text)) !== null) {
        const textFound = match[1];

        if (tagsRegex.test(textFound)) {
            found = textFound;
            break;
        }
    }

    if(found) {
      setToast({ ...toast, open: true, content: 'Erro de validação encontrado em: ' + found})
      return true
    } else {
      return false
    }
  }

  const replaceBeforeSubmit = (value) => {
    if(validateTags(value.template)) {
      return
    } else {
      const newValue = replaceSpecialCharacters(value)
      onSubmit(newValue);
    }
  }

  return (
    <Accordion
      key={id}
      id={id}
      title={title}
      onSave={methods.handleSubmit(replaceBeforeSubmit)}
      onDelete={() => onRemove(id)}
      isDragging={isDragging}
      summaryProps={{
        id,
        item,
      }}
    >
      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={toast.open} autoHideDuration={6000} onClose={() => setToast({ ...toast, open: false })}>
        <Alert severity={toast.severity} variant="filled" onClose={() => setToast({ ...toast, open: false })}>
          {toast.content}
        </Alert>
      </Snackbar>
      <FormProvider {...methods}>
        <Paper className={classes.paper}>
          <div className={classes.content}>
            {isLoadingUpdate || isLoadingRemove ? <Loader size={80} /> : <DevolutiveForm data={item} project={project} />}
          </div>
        </Paper>
      </FormProvider>
    </Accordion>
  )
}

const useStyles = makeStyles(() => ({
  paper: {
    padding: '20px',
    paddingBottom: '5px',
  },
  content: {
    '& > :first-child': {
      minHeight: '255px',
      marginBottom: '20px',
    },
  },
}))

Group.propTypes = {
  isDragging: PropTypes.bool.isRequired,
  item: PropTypes.object.isRequired,
  project: PropTypes.object.isRequired,
}

Group.defaultProps = {}

export default Group
