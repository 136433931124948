import { useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useSteps, useReorderSteps, useSelectStep } from 'hooks/steps'
import { Drawer } from 'components'

import StepDrawerItem from './step-drawer-item/StepDrawerItem'
import StepDialog from '../step-dialog/StepDialog'
import { isEmpty } from 'lodash'

const StepDrawer = ({ handleDrawer, open }) => {
  const { steps } = useSteps()
  const [isOpenDialog, setOpenDialog] = useState(false)
  const selectStep = useSelectStep()

  const order = useMemo(() => {
    if (isEmpty(steps)) return 0
    const order = steps.map(({ order }) => order).reduce((a, b) => Math.max(a, b))
    
    return order + 1
  }, [steps])
  const { mutate: onReorder } = useReorderSteps()

  return (
    <>
      <Drawer
        open={open}
        handleDrawer={handleDrawer}
        items={steps.sort((a, b) => ( a.order > b.order ? 1 : -1)) }
        onReorder={onReorder}
        onAdd={() => setOpenDialog(true)}
        onClick={({ id }) => selectStep(id)}
      >
        {(props) => <StepDrawerItem {...props} />}
      </Drawer>
      <StepDialog open={isOpenDialog} onClose={() => setOpenDialog(false)} order={order}  />
    </>
  )
}

StepDrawer.propTypes = {
  handleDrawer: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
}

StepDrawer.defaultProps = {}

export default StepDrawer
