import React from 'react'
import { createContext, useContext, useReducer } from 'react'
import { useQuery, useMutation } from 'react-query'
import { isEmpty } from 'lodash'

import {
    GET_ATTRIBUTES,
    POST_ATTRIBUTE,
    UPDATE_ATTRIBUTE,
    DELETE_ATTRIBUTE
} from 'api/services/customerAttributes'
import { useParams } from 'react-router-dom'

const ACTION_TYPES = {
    LIST: 'LIST_ATTRIBUTES',
    VIEW: 'VIEW_ATTRIBUTE',
    ADD: 'ADD_ATTRIBUTE',
    UPDATE: 'UPDATE_ATTRIBUTE',
    REMOVE: 'REMOVE_ATTRIBUTE',
}

const attributesReducer = (state, action) => {
	switch (action.type) {
		case ACTION_TYPES.LIST: {
			return { 
				...state, 
				attributes: action.data.data
			}
		}
		case ACTION_TYPES.ADD: {
			return { 
				...state, 
				attributes: [ ...state.attributes, action.data ] 
			}
		}
		case ACTION_TYPES.VIEW: {
			return { 
				...state, 
				attribute: action.data
			}
		}
		case ACTION_TYPES.UPDATE: {
			return {
				...state,
				attributes: state?.attributes?.length > 0 ? state.attributes.map((item) => (item.id === action.data.id ? action.data : item)) : [],
			}
		}
		case ACTION_TYPES.REMOVE: {
			return { 
				...state, 
				attributes: state?.attributes?.filter(({ id }) => id !== action.id) 
			}
		}
		default: {
			return state
		}
	}
}

const AttributesStateContext = createContext()
const AttributesDispatchContext = createContext()

const AttributesProvider = ({ children }) => {
    const { projectId } = useParams();
    const [state, dispatch] = useReducer(attributesReducer, { attributes: [] })
  
    const { isLoading, isFetching } = useQuery(['attributes'], () => GET_ATTRIBUTES(projectId), {
			onSuccess: (data) => {
				if (isEmpty(data)) {
					return dispatch({ type: ACTION_TYPES.LIST, data })
				}
				dispatch({ type: ACTION_TYPES.LIST, data })
			},
    })
  
    return (
			<AttributesStateContext.Provider value={{ isLoading, isFetching, ...state }}>
					<AttributesDispatchContext.Provider value={dispatch}>{children}</AttributesDispatchContext.Provider>
			</AttributesStateContext.Provider>
    )
}

const useAttributes = () => {
	const context = useContext(AttributesStateContext)

	if (!context)
		throw new Error('useAttributes must be used within an AttributesProvider')

	return context
}
  
const useDispatch = () => {
	const dispatch = useContext(AttributesDispatchContext)

	if (dispatch === undefined)
			throw new Error('useDispatch must be used within a AttributesProvider')

	return dispatch
}

const useCreateAttribute = (options = {}) => {
	const { projectId } = useParams();
	const dispatch = useDispatch()
	const { onSuccess } = options

	return useMutation((data) => POST_ATTRIBUTE(projectId, data), {
			...options,
			onSuccess: (data) => {
					onSuccess(data)
					dispatch({ type: ACTION_TYPES.ADD, data })
			},
	})
}

const useUpdateAttribute = (id, options = {}) => {
	const dispatch = useDispatch()

	return useMutation((data) => UPDATE_ATTRIBUTE(id, data), {
			...options,
			onSuccess: (data) => {
				dispatch({ type: ACTION_TYPES.UPDATE, data })
			},
	})
}

const useDeleteAttribute = (id, options = {}) => {
	const dispatch = useDispatch()
	const { onSuccess } = options

	return useMutation(() => DELETE_ATTRIBUTE(id), {
			...options,
			onSuccess: () => {
				onSuccess()
				dispatch({ type: ACTION_TYPES.REMOVE, id })
			},
	})
}

export {
	AttributesProvider,
	useAttributes,
	useCreateAttribute,
	useUpdateAttribute,
	useDeleteAttribute
}
