import PropTypes from 'prop-types'
import { FormsDisplayProvider } from './formsDisplay'
import { StepsProvider } from './steps'
import { FieldsProvider } from './fields'

const FormDisplayProvider = ({ children }) => {
  return (
    <FormsDisplayProvider>
      <StepsProvider>
        <FieldsProvider>
          {children}
        </FieldsProvider>
      </StepsProvider>
    </FormsDisplayProvider>
  )
}

FormDisplayProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default FormDisplayProvider
