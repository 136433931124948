import PropTypes from 'prop-types'
import { useFormContext, useFieldArray } from 'react-hook-form'
import { makeStyles } from '@material-ui/core/styles'
import { InputField } from 'components'


const CustomerAttributeForm = ({ data }) => {
  const { id: metric_id } = data
  const { control, watch } = useFormContext()
  const {
    fields: fieldRules,
    append,
    remove,
  } = useFieldArray({
    control,
    name: 'results',
  })
  const classes = useStyles()

  return (
    <div className={classes.wrapper}>
      <div className={classes.inputs}>
        <InputField
          className={classes.inputMetrics}
          id='tag'
          name='tag'
          label='Tag'
          variant='standard'
          fullWidth
        />
        <InputField
          className={classes.inputMetrics}
          id='title'
          name='title'
          label='Nome'
          variant='standard'
          fullWidth
        />
      </div>
    </div>
  )
}

const useStyles = makeStyles(() => ({
  inputs: {
    display: 'flex',
    marginBottom: '20px',
    '& > :first-child': {
      marginRight: '20px',
    },
  },
  inputMetrics: {
    '&.MuiTextField-root': {
      maxWidth: '250px',
    },
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  button: {
    margin: '10px 0px',
    maxWidth: '250px',
  },
  buttonAdd: {
    marginTop: 'auto',
    maxWidth: '250px',
  },
  root: {
    '&.MuiListItemIcon-root': {
      width: '33px',
      minWidth: 'auto',
    },
  },
  inputOperator: {
    maxWidth: '130px',
  },
  inputValue: {
    maxWidth: '100px',
  },
  inputResult: {
    maxWidth: '200px',
  },
}))

CustomerAttributeForm.propTypes = {
  data: PropTypes.object.isRequired,
  project: PropTypes.object.isRequired,
}

CustomerAttributeForm.defaultProps = {}

export default CustomerAttributeForm
