import React from 'react'

const Title = ({children, newTitle}) => {
	React.useEffect(() => {
		if (newTitle) document.title = newTitle;
	}, [newTitle])

	return <>{children}</>
}

export default Title