import PropTypes from 'prop-types'
import { useFormContext, useFieldArray } from 'react-hook-form'
import { isEmpty, get } from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import { makeStyles } from '@material-ui/core/styles'
import {
  List,
  ListItem,
  ListItemIcon,
  Button,
  IconButton,
  Typography,
  MenuItem,
} from '@material-ui/core'
import { Add as AddIcon, Delete as DeleteIcon } from '@material-ui/icons/'

import { useFields } from 'hooks/fields'
import { operators } from 'enum/operators'
import { genderFields } from 'enum/customerFields'
import { InputField } from 'components'



const DisplayRules = ({ project, data }) => {
  const { control, watch, getValues } = useFormContext()
  const { fields } = useFields()
  const {
    fields: fieldRules,
    append,
    remove,
  } = useFieldArray({
    control,
    name: 'display_rules',
  })

  const { customer } = project

  const customerFields = Object.keys(customer ?? {});
  const classes = useStyles()

  if (isEmpty(fieldRules)) {
    return (
      <div>
        <Typography variant='h6' color='inherit' noWrap>
          Nenhuma regra cadastrada
        </Typography>

        <Button
          className={classes.button}
          variant='contained'
          size='small'
          color='primary'
          startIcon={<AddIcon />}
          onClick={() => {
            append({
              description: '',
              value_of_id: uuidv4(),
              operator: '',
              field: '',
              value: '',
            })
          }}
        >
          Adicionar Regra
        </Button>
      </div>
    )
  }

  const displayRules = watch('display_rules', [])

  const getOptions = (index) => {
    const { field } = displayRules[index]

    return get(
      fields.find((item) => item.id === field),
      'options',
      []
    )
  }
  return (
    <div>
      <Typography variant='h6' color='inherit' noWrap>
        Regras para exibição do campo
      </Typography>

      <List disablePadding>
        {fieldRules.map((rule, index) => (
          <ListItem key={rule.id} disableGutters>
            <ListItemIcon className={classes.root}>
              <IconButton onClick={() => remove(index)} edge='start' aria-label='delete'>
                <DeleteIcon />
              </IconButton>
            </ListItemIcon>
            <InputField
              id='type'
              size='small'
              fullWidth
              name={`display_rules.${index}.type`}
              label='Tipo'
              defaultValue={getValues(`display_rules.${index}.type`)}
              disableMargin
              select
            >
              <MenuItem value='Customer'>
                Usuário
              </MenuItem>
              <MenuItem value='Field'>
                Campo
              </MenuItem>
            </InputField>
            <InputField
              id='field'
              size='small'
              fullWidth
              name={`display_rules.${index}.field`}
              label='Campo'
              defaultValue={getValues(`display_rules.${index}.field`)}
              disableMargin
              select
            >
              {
                displayRules[index].type === 'Customer'
                ?
                customerFields.map(
                  (opt) =>
                    opt.id !== data.id && (
                      <MenuItem key={opt} value={opt}>
                        {opt}
                      </MenuItem>
                    )
                )
                :
                fields.map(
                  (opt) =>
                    opt.id !== data.id && (
                      <MenuItem key={opt.id} value={opt.id}>
                        {opt.title}
                      </MenuItem>
                    )
                )
              }
            </InputField>
            <InputField
              id='operador'
              name={`display_rules.${index}.operator`}
              control={control}
              defaultValue={getValues(`display_rules.${index}.operator`)}
              size='small'
              variant='outlined'
              label='Operador'
              disableMargin
              fullWidth
              select
            >
              {operators.map((opt) => (
                <MenuItem key={opt.value} value={opt.value}>
                  {opt.label}
                </MenuItem>
              ))}
            </InputField>
            <InputField
              size='small'
              variant='outlined'
              fullWidth
              label='Valor'
              select={!isEmpty(getOptions(index)) || displayRules[index].field == 'gender'}
              name={`display_rules.${index}.value`}
              control={control}
              defaultValue={getValues(`display_rules.${index}.value`)}
              disableMargin
            >
              <MenuItem value={""}>
                Nenhum valor
              </MenuItem>
              {
                displayRules[index].field == 'gender' ?
                genderFields.map((opt) => (
                  <MenuItem key={opt.value} value={opt.value}>
                    {opt.title}
                  </MenuItem>
                ))
                :
                getOptions(index).map((opt) => (
                  <MenuItem key={opt.value} value={opt.value}>
                    {opt.label}
                  </MenuItem>
                ))
              }
            </InputField>
          </ListItem>
        ))}
      </List>
      <Button
        variant='contained'
        size='small'
        color='primary'
        startIcon={<AddIcon />}
        onClick={() => {
          append({
            description: '',
            value_of_id: uuidv4(),
            operator: '',
            field: '',
            value: '',
          })
        }}
      >
        Adicionar Regra
      </Button>
    </div>
  )
}

const useStyles = makeStyles(() => ({
  button: {
    margin: '10px 0px',
  },
  root: {
    '&.MuiListItemIcon-root': {
      minWidth: 'auto',
    },
  },
}))

DisplayRules.propTypes = {
  data: PropTypes.object.isRequired,
  project: PropTypes.object.isRequired,
}

DisplayRules.defaultProps = {}

export default DisplayRules
