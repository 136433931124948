import {useFormContext} from 'react-hook-form'
import {graduation} from "../../enum/physicalExamOptions";
import SimpleSelectField from "../SimpleSelectField";
import MultiOptionSelectField from "../MultiOptionSelectField";
import {Button, Grid} from "@material-ui/core";
import {CheckboxList, InputField} from "components";
import React from "react";

const affectedArea = [
    {id: 'palpebra', label: 'Pálpebra', value: 'pálpebra'},
    {id: 'globo_ocular', label: 'Globo Ocular', value: 'globo ocular'},
    {id: 'conjuntiva', label: 'Conjuntiva', value: 'conjuntiva'},
    {id: 'pupilas', label: 'Pupilas', value: 'pupilas'},
    {id: 'fundo_do_olho', label: 'Fundo do Olho', value: 'fundo do olho'},
    {id: 'sem_alteracoes', label: 'Sem Alterações', value: 'sem alterações'},
]

const BREAKPOINTS = { mobile: 0, tablet: 768, desktop: 1280 }

const Olho = ({id, segment, segmentName, removeItems}) => {
    const visualAcuity = [
        {label: '20/20 ', value: '20/20'},
        {label: '20/25 ', value: '20/25'},
        {label: '20/30 ', value: '20/30'},
        {label: '20/40 ', value: '20/40'},
        {label: '20/50 ', value: '20/50'},
        {label: '20/70 ', value: '20/70'},
        {label: '20/100', value: '20/100'},
        {label: '20/400', value: '20/400'},
    ];

    const correctiveLenses = [
        {label: 'Ausentes', value: 'ausentes'},
        {label: 'Óculos', value: 'óculos'},
        {label: 'Lentes de contato', value: 'lentes de contato'},
    ];

    const {control} = useFormContext()


    return (
        <>
            <Button style={{width: '100%', fontWeight: '900', fontSize: 'medium', backgroundColor: 'red'}}
                    variant={'contained'}
                    size='large'
                    color='secondary'
                    onClick={() => removeItems(segmentName,segment)}
            >
                Excluir Exame
            </Button>
            <Grid container spacing={3} className="form-grid" justifyContent="center">
                <Grid item xs={8}>
                    <CheckboxList
                        id={id}
                        name={`${id}.${segment}.01-affectedArea`}
                        label={'Região Afetada'}
                        options={affectedArea}
                    />
                </Grid>
                <Grid item sm={10} md={5}>
                    <InputField
                        id={id}
                        multiline={true}
                        name={`${id}.${segment}.02-otherAffectedArea`}
                        label={'Outras Alterações'}
                        control={control}/>
                </Grid>

                <Grid item sm={10} md={5}>
                    <SimpleSelectField
                        segment={segment}
                        id={id}
                        name={'03-colorTest'}
                        label={'Teste para cores'}
                        trueLabel={'Normal'}
                        falseLabel={'Alterado'}
                        control={control}/>
                </Grid>

                <Grid item sm={10} md={5}>
                    <SimpleSelectField
                        segment={segment}
                        id={id}
                        name={'04-viewFromAfar'}
                        label={'Visão de longe'}
                        trueLabel={'Normal'}
                        falseLabel={'Alterada'}
                        control={control}/>
                </Grid>

                <Grid item sm={10} md={5}>
                    <SimpleSelectField
                        segment={segment}
                        id={id}
                        name={'05-closeView'}
                        label={'Visão de perto'}
                        trueLabel={'Normal'}
                        falseLabel={'Alterada'}
                        control={control}/>
                </Grid>

                <Grid item sm={10} md={5}>
                    <SimpleSelectField
                        segment={segment}
                        id={id}
                        name={'06-visualArea'}
                        label={'Campo visual'}
                        trueLabel={'Normal'}
                        falseLabel={'Alterado'}
                        control={control}/>
                </Grid>

                <Grid item sm={10} md={5}>
                    <MultiOptionSelectField
                        segment={segment}
                        id={id}
                        name={'07-visualAcuity'}
                        label={'Acuidade Visual'}
                        control={control}
                        options={visualAcuity}/>
                </Grid>

                <Grid item sm={10} md={5}>
                    <MultiOptionSelectField
                        segment={segment}
                        id={id}
                        name={'08-correctiveLenses'}
                        label={'Lentes Corretivas'}
                        control={control}
                        options={correctiveLenses}/>
                </Grid>

                <Grid item sm={10} md={5}>
                    <SimpleSelectField
                        segment={segment}
                        id={id}
                        name={'09-operativeScar'}
                        label={'Cicatriz operatória'}
                        trueLabel={'Existente'}
                        falseLabel={'Inexistente'}
                        control={control}/>
                </Grid>

                <Grid item sm={10} md={5}>
                    <SimpleSelectField
                        segment={segment}
                        id={id}
                        name={'10-sensitivity'}
                        label={'Sensibilidade'}
                        trueLabel={'Normal'}
                        falseLabel={'Anormal'}
                        control={control}/>
                </Grid>

                <Grid item sm={10} md={5}>
                    <SimpleSelectField
                        segment={segment}
                        id={id}
                        name={'11-coloring'}
                        label={'Coloração'}
                        trueLabel={'Normal'}
                        falseLabel={'Anormal'}
                        control={control}/>
                </Grid>

                <Grid item sm={10} md={5}>
                    <SimpleSelectField
                        segment={segment}
                        id={id}
                        name={'12-temperature'}
                        label={'Temperatura'}
                        trueLabel={'Normal'}
                        falseLabel={'Anormal'}
                        control={control}/>
                </Grid>

                <Grid item sm={10} md={5}>
                    <SimpleSelectField
                        segment={segment}
                        id={id}
                        name={'13-inflammatorySigns'}
                        label={'Sinais Inflamatórios'}
                        trueLabel={'Existente'}
                        falseLabel={'Inexistente'}
                        control={control}/>
                </Grid>

                <Grid item sm={10} md={5}>
                    <SimpleSelectField
                        segment={segment}
                        id={id}
                        name={'14-muscleChanges'}
                        label={'Alterações Musculares'}
                        trueLabel={'Existente'}
                        falseLabel={'Inexistente'}
                        control={control}/>
                </Grid>

                <Grid item sm={10} md={5}>
                    <SimpleSelectField
                        segment={segment}
                        id={id}
                        name={'15-motorChanges'}
                        label={'Alterações Motoras'}
                        trueLabel={'Existente'}
                        falseLabel={'Inexistente'}
                        control={control}/>
                </Grid>

                <Grid item sm={10} md={5}>
                    <MultiOptionSelectField
                        segment={segment}
                        id={id}
                        name={'16-graduation'}
                        label={'Graduação'}
                        control={control}
                        options={graduation}/>
                </Grid>
            </Grid>
        </>
    )
}

export default Olho
