import { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import { yupResolver } from '@hookform/resolvers/yup'
import { useRecoverUserPassword } from 'hooks/useRecoverUserPassword'
import { Dialog, InputField } from 'components'
import * as yup from 'yup'

const schema = yup.object().shape({
    email: yup.string().email('Por favor, insira um email válido').required('Esse campo é obrigatório'),
  })
  
const RecoverPassword = () => {
  const classes = useStyles()
  const [open, setOpenDialog] = useState(false)
  const methods = useForm({
    mode: 'onTouched',
    defaultValues: {
      email: ''
    },
    resolver: yupResolver(schema),
  })
  const {
    setError,
    clearErrors,
    handleSubmit,
    formState: { errors, isValid, isDirty },
  } = methods
  const [success, setSuccess] = useState(null);

  const clean = () => {
    clearErrors();
    setOpenDialog(false);
    setSuccess(null);
  }

  const handlesuccess = (message) => {
    setSuccess(message);
    setTimeout(clean, 2500);
  }

  const { mutate, isLoading } = useRecoverUserPassword({
    onSuccess: (data) => handlesuccess(data.message),
    onError: (data) => setError("email",{ message: data.email })
  })

  return (
    <>
      <span className={classes.recover} onClick={() => setOpenDialog(true)}>
        Recuperar senha
      </span>
      <FormProvider {...methods}>
        <Dialog
          title='Recuperar senha'
          contentText='Preencha seu e-mail para enivarmos a recuperação de senha.'
          open={ open }
          onCancel={ clean }
          isLoading={isLoading}
          submitDisabled={!isValid || !isDirty || success}
          onSubmit={handleSubmit((data) => mutate(data))}
        >
          <InputField
            id='email'
            name='email'
            label='E-mail'
            variant='outlined'
            error={errors?.email}
            required
            fullWidth
          />
          { errors?.email && <Typography color="error" variant='span'>{errors.email.message}</Typography>}
          { <Typography color="primary" variant='span'>{success}</Typography> }
        </Dialog>
      </FormProvider>
    </>
  )
}

const useStyles = makeStyles(() => ({
  recover: {
    marginRight: 'auto',
    cursor: 'pointer'
  },
}))

RecoverPassword.propTypes = {}

RecoverPassword.defaultProps = {}

export default RecoverPassword
