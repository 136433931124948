import 'date-fns'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import DateFnsUtils from '@date-io/date-fns'
import { useFormContext, Controller } from 'react-hook-form'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import { makeStyles } from '@material-ui/core/styles'
import inputStyle from './input-style'

const InputDate = ({ defaultValue, name, variant, disableMargin, ...rest }) => {
  const {
    control,
    formState: { isSubmitting },
  } = useFormContext()
  const classes = useStyles()

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <div
        className={clsx({
          [classes.customTextField]: !disableMargin,
        })}
      ></div>
      <Controller
        render={({ field }) => (
          <KeyboardDatePicker
            {...field}
            {...rest}
            name={name}
            value={field.value || null}
            disableToolbar
            fullWidth
            autoOk
            inputVariant={variant}
            InputLabelProps={ { style: { position: 'relative', marginBottom: '5px', transform: 'translate(0, 0) scale(1)' }, shrink: false }}
            variant='inline'
            format='dd/MM/yyyy'
            invalidDateMessage='Formato de data inválido'
            maxDateMessage='A data não deve ser posterior à data máxima'
            minDateMessage='A data não deve ser anterior à data mínima'
          />
        )}
        name={name}
        control={control}
        defaultValue={defaultValue}
        disabled={isSubmitting}
      />
    </MuiPickersUtilsProvider>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '10px 0px',
  },
  customTextField: inputStyle(theme)
}))

InputDate.propTypes = {
  defaultValue: PropTypes.string,
  disableMargin: PropTypes.bool,
  name: PropTypes.string.isRequired,
  variant: PropTypes.string,
}

InputDate.defaultProps = {
  defaultValue: null,
  disableMargin: false,
  variant: 'outlined',
}

export default InputDate
