import PropTypes from 'prop-types'
import { DevolutivesProvider } from './devolutives'

const DevolutiveProvider = ({ children }) => {
  return (
    <DevolutivesProvider>{children}</DevolutivesProvider>
  )
}

DevolutiveProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default DevolutiveProvider
