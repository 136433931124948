import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Input,
  Dialog as MUIDialog,
} from '@material-ui/core'
import { devolutiveDoc } from 'api/services/devolutiveDoc'
import { InputField } from 'components'
import { useForm } from 'react-hook-form'
import { useMutation } from 'react-query'

const ImportDocModal = ({ isOpen = false, setIsOpen, setDocContent }) => {
  const { register, handleSubmit, reset } = useForm()

  const { mutate: uploadFile } = useMutation(devolutiveDoc, {
    onSuccess: (data) => {
	  const { content } = data;
      reset()
	  setDocContent(content)
	  setIsOpen(false);
	  
    },
    onError: (error) => {
      console.log('Error uploading file:', error)
    },
  })

  const onSubmit = (data) => uploadFile(data.file[0])

  return (
    <MUIDialog open={isOpen}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>Importar Arquivo Doc</DialogTitle>
        <DialogContent>
          <div>
            <label htmlFor='file'>Selecione um documento:</label><br></br>
            <input type='file' id='file' {...register('file', { required: true })} />
          </div>
        </DialogContent>
        <DialogActions>
          <Button disabled={false} onClick={() => setIsOpen(false)} color='secondary'>
            Cancelar
          </Button>
          <Button
            type='submit'
            disabled={false}
            autoFocus
            color='primary'
            variant='contained'
          >
            Enviar
          </Button>
        </DialogActions>
      </form>
    </MUIDialog>
  )
}

export default ImportDocModal
