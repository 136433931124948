import api from "api"

export const replicateForm = async (replicatedId, newName, results = false, project_id = null) => {
  const { data } = await api.post(
    `/form/replicate/${replicatedId}`, {
      name: newName,
      results,
      project_id
    }
  );

  return data;
}