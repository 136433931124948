import {useFormContext} from 'react-hook-form'
import {graduation} from "../../enum/physicalExamOptions";
import SimpleSelectField from "../SimpleSelectField";
import MultiOptionSelectField from "../MultiOptionSelectField";
import React from "react";
import {Button, Grid} from "@material-ui/core";

const EstruturaPelvica = ({id, segmentName, removeItems}) => {

    const segment = 'pelvicStructure'
    const {control} = useFormContext()

    return (
        <>
            <Button style={{width: '100%', fontWeight: '900', fontSize: 'medium', backgroundColor: 'red'}}
                    variant={'contained'}
                    size='large'
                    color='secondary'
                    onClick={() => removeItems(segmentName,segment)}
            >
                Excluir Exame
            </Button>
            <Grid container spacing={3} className="form-grid" justifyContent="center">
                <Grid item sm={10} md={5}>
                    <SimpleSelectField
                        segment={segment}
                        id={id}
                        name={'01-impairedTrunkSupport'}
                        label={'Prejuízo na sustentação do tronco'}
                        control={control}/>
                </Grid>

                <Grid item sm={10} md={5}>
                    <SimpleSelectField
                        segment={segment}
                        id={id}
                        name={'02-vulnerabilityVisceraInternalOrgans'}
                        label={'Vulnerabilidade na proteção das vísceras e órgãos internos'}
                        control={control}/>
                </Grid>

                <Grid item sm={10} md={5}>
                    <SimpleSelectField
                        segment={segment}
                        id={id}
                        name={'03-painOnPalpation'}
                        label={'Dor à palpação de orgão e ou estrutura'}
                        control={control}/>
                </Grid>

                <Grid item sm={10} md={5}>
                    <SimpleSelectField
                        segment={segment}
                        id={id}
                        name={'04-hipMobility'}
                        label={'Mobilidade de quadril'}
                        trueLabel={'Normal'}
                        falseLabel={'Anormal'}
                        control={control}/>
                </Grid>

                <Grid item sm={10} md={5}>
                    <SimpleSelectField
                        segment={segment}
                        id={id}
                        name={'05-operativeScar'}
                        label={'Cicatriz operatória'}
                        trueLabel={'Existente'}
                        falseLabel={'Inexistente'}
                        control={control}/>
                </Grid>

                <Grid item sm={10} md={5}>
                    <SimpleSelectField
                        segment={segment}
                        id={id}
                        name={'06-sensitivity'}
                        label={'Sensibilidade'}
                        trueLabel={'Normal'}
                        falseLabel={'Anormal'}
                        control={control}/>
                </Grid>

                <Grid item sm={10} md={5}>
                    <SimpleSelectField
                        segment={segment}
                        id={id}
                        name={'07-coloring'}
                        label={'Coloração'}
                        trueLabel={'Normal'}
                        falseLabel={'Anormal'}
                        control={control}/>
                </Grid>

                <Grid item sm={10} md={5}>
                    <SimpleSelectField
                        segment={segment}
                        id={id}
                        name={'08-temperature'}
                        label={'Temperatura'}
                        trueLabel={'Normal'}
                        falseLabel={'Anormal'}
                        control={control}/>
                </Grid>

                <Grid item sm={10} md={5}>
                    <SimpleSelectField
                        segment={segment}
                        id={id}
                        name={'09-inflammatorySigns'}
                        label={'Sinais Inflamatórios'}
                        trueLabel={'Existente'}
                        falseLabel={'Inexistente'}
                        control={control}/>
                </Grid>

                <Grid item sm={10} md={5}>
                    <SimpleSelectField
                        segment={segment}
                        id={id}
                        name={'10-muscleChanges'}
                        label={'Alterações Musculares'}
                        trueLabel={'Existente'}
                        falseLabel={'Inexistente'}
                        control={control}/>
                </Grid>

                <Grid item sm={10} md={5}>
                    <SimpleSelectField
                        segment={segment}
                        id={id}
                        name={'11-motorChanges'}
                        label={'Alterações Motoras'}
                        trueLabel={'Existente'}
                        falseLabel={'Inexistente'}
                        control={control}/>
                </Grid>

                <Grid item sm={10} md={5}>
                    <MultiOptionSelectField
                        segment={segment}
                        id={id}
                        name={'12-graduation'}
                        label={'Graduação'}
                        control={control}
                        options={graduation}/>
                </Grid>
            </Grid>
        </>
    )
}

export default EstruturaPelvica
