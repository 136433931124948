import { useMemo, useState } from 'react'
import { CircularProgress, IconButton, Tooltip, Snackbar } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/styles'
import { Send } from '@material-ui/icons';
import { useFields } from 'hooks/fields'
import { useSteps } from 'hooks/steps'
import Preview from './preview/Preview'
import Builder from './builder/Builder'
import AppBar from './appBar/AppBar'
import { useForm, usePublishForm } from 'hooks/forms';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useFetchProject } from 'hooks/useProject';

const MenuPublishPreview = ({ formId = '', version = null }) => {
  const [state, setSnackbar] = useState({ open: false, severity: 'success'});
  const { formId: formIdRouter } = useParams()

  const { mutate: onSubmit } = usePublishForm(formId ? formId: formIdRouter, {
    onSuccess: () => { 
      setSnackbar({ open: true, severity: 'success'})
    },
    onError: () => {
      setSnackbar({ open: true, severity: 'error'})
    }
  })

  const { open, severity } = state

  return (
    <>
      <Tooltip title={version ? `Ultima publicação ${version.created_at }` : 'Publicar pela primeira vez'}>
        <IconButton 
          onClick={onSubmit}
          aria-label='account of current user'
          aria-controls='menu-appbar'
          aria-haspopup='true'
          color='inherit'
        >
          <Send/>
        </IconButton> 
      </Tooltip>
      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={open} autoHideDuration={6000} onClose={() => setSnackbar({ ...state, open: false })}>
        <Alert variant="filled" onClose={() => setSnackbar({ ...state, open: false })} severity={severity}>
          {severity === 'success' ? 
            'Publicação efetuada com sucesso!' :
            'Esta versão de formulário já foi publicada.'
          }
        </Alert>
      </Snackbar>
    </>
  );
}

const Playground = () => {
  const classes = useStyles()
  const { projectId } = useParams();

  const { isLoading: isLoadingForms, selectedForm, complementForm } = useForm()
  const { isLoading: isLoadingFields } = useFields()
  const { isLoading: isLoadingSteps } = useSteps()
  const { isLoading: isLoadingProject, data: project } = useFetchProject(projectId, { all: true })
  console.log('form', selectedForm);
  console.log( 'project', project);

  const component = useMemo(() => {
    return isLoadingFields || isLoadingSteps || isLoadingForms || isLoadingProject ? (
      <div className={classes.loading}>
        <CircularProgress size={150} />
      </div>
    ) : (
      <div className={classes.content}>
        <Builder project={project} form={selectedForm}/>
        <Preview />
      </div>
    )
  }, [classes, isLoadingFields, isLoadingSteps, isLoadingForms, isLoadingProject ])
  if (isLoadingForms) 
    return "carregando";
    
  return (
    <div className={classes.root}>
      <AppBar project={selectedForm?.title ?? ""} isLoading={isLoadingSteps && isLoadingForms} menuLeft={(<MenuPublishPreview formId={selectedForm?.id} version={complementForm?.last_published}/>)} />
      {component}
    </div>
  )
}

const useStyles = makeStyles(() => ({
  root: {
    minHeight: '80vh',
  },
  content: {
    backgroundColor: '#eff2f7',
    display: 'flex',
  },
  loading: {
    display: 'flex',
    height: 'calc(100vh - 175px)',
    alignItems: 'center',
    justifyContent: 'center',
  },
  pallette: {
    success: {
      
    }
  }
}))

Playground.propTypes = {
}

Playground.defaultProps = {
}
MenuPublishPreview.propTypes = {
  formId: PropTypes.string,
  version: PropTypes.object
}
MenuPublishPreview.defaultProps = {
  formId: '',
  version: null
}
export default Playground
