import { makeStyles } from '@material-ui/core/styles'
import WithFormHooks from './with-form-hooks/WithFormHooks';
import PropTypes from 'prop-types'

const DisplayRules = ({forms, customer, metrics, formHooks}) => {


    return  (
        <WithFormHooks
            forms={forms}
            customer={customer}
            metrics={metrics}
            path={formHooks.basePath}
            style={styles()}
        />
    ) 
    // : (
    //     // TODO: a ideia é que neste item ele utilize um callback e retorne o array completo 
    //     //       não como o anterior que ele preenche um formArray 
    //     <> Precisa ser feito</> 
    // );
}

DisplayRules.propTypes = {
  customer: PropTypes.object.isRequired,
  formHooks: PropTypes.object.isRequired,
  forms: PropTypes.object.isRequired,
  metrics: PropTypes.object,
}

DisplayRules.defaultProps = {
  metrics: {}
}

const styles = makeStyles((theme) => ({
  inputs: {
    display: 'flex',
    marginBottom: '20px',
    '& > :first-child': {
      marginRight: '20px',
    },
  },
  inputMetrics: {
    '&.MuiTextField-root': {
      maxWidth: '250px',
    },
  },
  button: {
    marginTop: '4px',
    marginBottom: '20px',
  },
  root: {
    '&.MuiListItemIcon-root': {
      minWidth: 'auto',
    },
  },
  inputValue: {
    maxWidth: '80px',
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}))
export default DisplayRules;
