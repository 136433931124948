import React, { createContext, useContext, } from 'react'
import PropTypes from 'prop-types'

const AccordionStateContext = createContext()
const AccordionDispatchContext = createContext()

const AccordionProvider = ({ children }) => {
  const [lockAccordion, setLockAccordion] = React.useState(true)

  return (
    <AccordionStateContext.Provider value={{ lockAccordion, setLockAccordion }}>
      <AccordionDispatchContext.Provider >{children}</AccordionDispatchContext.Provider>
    </AccordionStateContext.Provider>
  )
}

AccordionProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

const useAccordion = () => {
  const context = useContext(AccordionStateContext)

  if (!context) {
    throw new Error('useAccordion must be used within an AccordionProvider')
  }

  return context
}

export {
  AccordionProvider,
  useAccordion
}
