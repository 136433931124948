import PropTypes from 'prop-types'
import { BottomNavigation as MUIBottomNavigation, BottomNavigationAction } from '@material-ui/core'
import {
  TextFields as TextFieldsIcon,
  Assignment as AssignmentIcon,
  Visibility as VisibilityIcon,
} from '@material-ui/icons'

const BottomNavigation = ({ value, onChange, disabled }) => (
  <MUIBottomNavigation value={value} onChange={onChange} showLabels>
    <BottomNavigationAction
      disabled={disabled}
      value='field'
      label='Campo'
      icon={<AssignmentIcon />}
    />
    <BottomNavigationAction
      disabled={disabled}
      value='display-rule'
      label='Exibição'
      icon={<VisibilityIcon />}
    />
    <BottomNavigationAction
      disabled={disabled}
      value='texts'
      label='Textos'
      icon={<TextFieldsIcon />}
    />
  </MUIBottomNavigation>
)

BottomNavigation.propTypes = {
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
}

BottomNavigation.defaultProps = {
  disabled: false,
}

export default BottomNavigation
