import { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useFormContext, useFieldArray } from 'react-hook-form'
import { isEmpty } from 'lodash'
import { makeStyles } from '@material-ui/core/styles'
import { List, ListItem, ListItemIcon, Button, IconButton, MenuItem, Typography } from '@material-ui/core'
import { Add as AddIcon, Delete as DeleteIcon } from '@material-ui/icons/'
import { typeMetrics } from 'enum/form-field'
import DisplayRules from 'components/display-rules/DisplayRules'
import { results } from 'enum/operators'
import { InputField } from 'components'


const MetricForm = ({ data, project }) => {
  const { forms, customer, metrics } = project
  const { id: metric_id } = data
  const { control, watch } = useFormContext()
  const {
    fields: fieldRules,
    append,
    remove,
  } = useFieldArray({
    control,
    name: 'results',
  })
  const classes = useStyles()
  
const resultsFields = watch('results', [])

  return (
    <div className={classes.wrapper}>
      <div className={classes.inputs}>
        <InputField
          className={classes.inputMetrics}
          id='tag'
          name='tag'
          label='Tag da Métrica'
          variant='standard'
          fullWidth
        />
        <InputField
          className={classes.inputMetrics}
          id='name'
          name='name'
          label='Nome da Métrica'
          variant='standard'
          fullWidth
        />
         <InputField
          className={classes.inputMetrics}
          id='priority'
          name='priority'
          label='Prioridade da Métrica'
          variant='standard'
          fullWidth
        />
        <InputField
          className={classes.inputMetrics}
          id='type'
          name='type'
          label='Tipo da Métrica'
          variant='standard'
          select
          fullWidth
        >
        {typeMetrics.map((opt) => (
          <MenuItem key={opt.label} value={opt.value}>
            {opt.label}
          </MenuItem>
        ))}
        </InputField>
      </div>

      {isEmpty(fieldRules) ? (
         <div>
          <Typography variant='h6' color='inherit' noWrap>
            Nenhum Resultado cadastrado
          </Typography>

          <Button
            className={classes.button}
            variant='contained'
            size='small'
            color='primary'
            startIcon={<AddIcon />}
            onClick={() => {
              append({
                metric_id: metric_id,
                label: '',
                operator: '',
                val1: '',
                val2: '',
              })
            }}
          >
            Adicionar Resultado
          </Button>
        </div>
      ) : (
      <>
        <List disablePadding>
          {fieldRules.map((rule, index) => (
            <div key={rule.id}>
              <ListItem disableGutters dense>
                <ListItemIcon className={classes.root}>
                  <IconButton onClick={() => remove(index)} edge='start' aria-label='delete'>
                    <DeleteIcon />
                  </IconButton>
                </ListItemIcon>
                <InputField
                  id='operator'
                  size='small'
                  className={classes.inputOperator}
                  fullWidth
                  name={`results.${index}.operator`}
                  label='Operador'
                  disableMargin
                  select
                >
                  {results.map((opt) => (
                    <MenuItem key={opt.value} value={opt.value}>
                      {opt.label}
                    </MenuItem>
                  ))}
                </InputField>
                <InputField
                  fullWidth
                  className={classes.inputValue}
                  size='small'
                  type='number'
                  variant='outlined'
                  label='Valor'
                  name={`results.${index}.val1`}
                  control={control}
                  disableMargin
                />
                {
                  resultsFields[index].operator === 'between' &&
                  (
                    <InputField
                      fullWidth
                      className={classes.inputValue}
                      size='small'
                      type='number'
                      variant='outlined'
                      label='Valor'
                      name={`results.${index}.val2`}
                      control={control}
                      disableMargin
                    />
                  )
                }
                <InputField
                  fullWidth
                  className={classes.inputResult}
                  size='small'
                  variant='outlined'
                  label='Resultado'
                  name={`results.${index}.label`}
                  control={control}
                  disableMargin
                />
              </ListItem>
              <DisplayRules formHooks={{basePath: `results.${index}`}} forms={forms} customer={customer} metrics={metrics} />
            </div>
          ))}
        </List>
        <Button
          className={classes.buttonAdd}
          variant='contained'
          size='small'
          color='primary'
          startIcon={<AddIcon />}
          onClick={() => {
            append({
              metric_id: metric_id,
              label: '',
              operator: '',
              val1: '',
              val2: '',
            })
          }}
        >
          Adicionar Resultado
        </Button>
      </>
    )}
    </div>
  )
}

const useStyles = makeStyles(() => ({
  inputs: {
    display: 'flex',
    marginBottom: '20px',
    '& > :first-child': {
      marginRight: '20px',
    },
  },
  inputMetrics: {
    '&.MuiTextField-root': {
      maxWidth: '250px',
    },
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  button: {
    margin: '10px 0px',
    maxWidth: '250px',
  },
  buttonAdd: {
    marginTop: 'auto',
    maxWidth: '250px',
  },
  root: {
    '&.MuiListItemIcon-root': {
      width: '33px',
      minWidth: 'auto',
    },
  },
  inputOperator: {
    maxWidth: '130px',
  },
  inputValue: {
    maxWidth: '100px',
  },
  inputResult: {
    maxWidth: '200px',
  },
}))

MetricForm.propTypes = {
  data: PropTypes.object.isRequired,
  project: PropTypes.object.isRequired,
}

MetricForm.defaultProps = {}

export default MetricForm
