import { useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Fab, Button, MenuItem, Typography } from '@material-ui/core'
import { Add as AddIcon } from '@material-ui/icons'
import { useCreateMetric } from 'hooks/metrics'
import { Dialog, InputField } from 'components'
import { typeMetrics } from 'enum/form-field'
import * as yup from 'yup'
import { useParams } from 'react-router-dom'

const schema = yup.object().shape({
  name: yup.string().required('Esse campo é obrigatório').min(4, 'O nome deve ter no mínimo 4 caracteres'),
  type: yup.string().required('Esse campo é obrigatório'),
})

const AddResult = ({ isEmpty }) => {
  const { projectId } = useParams();
  const [open, setOpenDialog] = useState(false)
  const classes = useStyles()

  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    shouldUnregister: true,
  })

  const {
    handleSubmit,
    formState: { errors, isValid, isDirty },
  } = methods

  const { mutate: onSubmit, isLoading } = useCreateMetric(projectId, {
    onSuccess: () => setOpenDialog(false),
  })

  return (
    <>
      {isEmpty ? (
        <Button
          className={classes.button}
          onClick={() => setOpenDialog(true)}
          variant='contained'
          startIcon={<AddIcon />}
          color='primary'
        >
          Adicionar a primeira Métrica
        </Button>
      ) : (
        <Fab color='primary' aria-label='add' onClick={() => setOpenDialog(true)}>
          <AddIcon />
        </Fab>
      )}

      <FormProvider {...methods}>
        <Dialog
          title='Adicionar Métrica'
          contentText='Preencha as informações para cadastrar a nova Métrica. Essas informações poderão ser alteradas depois.'
          open={open}
          onCancel={() => setOpenDialog(false)}
          isLoading={isLoading}
          submitDisabled={!isValid || !isDirty}
          onSubmit={handleSubmit(onSubmit)}
        >
          <InputField id='name' name='name' label='Métrica' error={errors?.name} required fullWidth />
          { errors?.name && <Typography color="error" variant="span">{errors.name.message}</Typography> }

          <InputField id='type' name='type' label='Tipo da Métrica' error={errors?.type} required fullWidth select>
            {typeMetrics.map((opt) => (
              <MenuItem key={opt.label} value={opt.value}>
                {opt.label}
              </MenuItem>
            ))}
          </InputField>
          { errors?.type && <Typography color="error" variant="span">{errors.type.message}</Typography> }

        </Dialog>
      </FormProvider>
    </>
  )
}

const useStyles = makeStyles(() => ({
  button: {
    display: 'flex',
    margin: '20px auto',
  },
}))

AddResult.propTypes = {
  isEmpty: PropTypes.bool.isRequired,
}

AddResult.defaultProps = {}

export default AddResult
