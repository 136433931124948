import PropTypes from 'prop-types'
import { FormProvider, useForm } from 'react-hook-form'
import { useCreateStep } from 'hooks/steps'
import { yupResolver } from '@hookform/resolvers/yup'
import { Dialog, InputField } from 'components'
import { Typography } from '@material-ui/core'
import * as yup from 'yup'

const schema = yup.object().shape({
  title: yup.string().required('Esse campo é obrigatório').min(2, 'O título deve ter no mínimo 2 caracteres').max(255, 'O título deve ter no máximo 255 caracteres')
})

const StepDialog = ({ open, onClose, order }) => {
  const methods = useForm({
    mode: 'onTouched',
    shouldUnregister: true,
    defaultValues: {
      title: '',
    },
    resolver: yupResolver(schema),
  })
  const {
    handleSubmit,
    formState: { errors, isValid, isDirty },
  } = methods

  const { mutate, isLoading } = useCreateStep({
    onSuccess: onClose,
  })

  return (
    <FormProvider {...methods}>
      <Dialog
        title='Adicionar Etapa'
        contentText='Escolha um nome para a nova etapa. Essa informação é apenas para visualização e você poderá alterar depois.'
        open={open}
        onCancel={onClose}
        isLoading={isLoading}
        submitDisabled={!isValid || !isDirty}
        onSubmit={handleSubmit((data) => mutate({ ...data, order }))}
      >
        <InputField
          id='title'
          name='title'
          label='Nome da Etapa'
          variant='outlined'
          error={errors?.title}
          inputProps={{maxLength:255}}
          required
          fullWidth
        />
        { errors?.title && <Typography color="error" variant='span'>{errors.title.message}</Typography>}
      </Dialog>
    </FormProvider>
  )
}

StepDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  order: PropTypes.number.isRequired,
}

StepDialog.defaultProps = {}

export default StepDialog
