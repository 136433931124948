import axios from 'axios'

const defaultHeaders = {
    'Content-Type': 'application/json',
}
const queryString = require('query-string');
const parsed = queryString.parse(location.search);

if (typeof parsed.apikey !== 'undefined') {
    window.localStorage.setItem('apikey', parsed.apikey)
    defaultHeaders['Authorization'] = `Bearer ${parsed.apikey}`
} else if (window.localStorage.getItem('apikey')) {
    const apikey = window.localStorage.getItem('apikey');
    defaultHeaders['Authorization'] = `Bearer ${apikey}`
}

if (typeof parsed.theme !== 'undefined') {
    window.localStorage.setItem('theme', parsed.theme)
}

const route = axios.create({
    headers: defaultHeaders,
    baseURL: process.env.REACT_APP_BASE_URL_FILL,
    withCredentials: true,
})

export default route;
