import React from 'react'
import AvatarBodyMap from "./AvatarBodyMap";
import {Button} from "@material-ui/core";
import { useState } from "react";
import ExamTabs from "./ExamTabs";

const ForWard = 'ForWard'
const BackWard = 'BackWard'
const AvatarBody = ({id, isPreview, addItems, removeItems}) =>{
    const [btnValue, setBtnValue] = useState(ForWard);
    const changeBodySelect = (body) =>{
            setBtnValue(body)
    }

    return(
        <div>
            {
                !isPreview && <>
                    <Button style={{margin: '5px'}}
                            variant={btnValue === ForWard ? 'contained' : 'outlined'}
                            size='large'
                            color='primary'
                            onClick={() => {
                                changeBodySelect(ForWard)
                            }}
                    >
                        Frente
                    </Button>
                    <Button style={{margin: '5px'}}
                            variant={btnValue !== ForWard ? 'contained' : 'outlined'}
                            size='large'
                            color='primary'
                            onClick={() => {
                                changeBodySelect(BackWard);
                            }}
                    >
                        Costas
                    </Button>
                </>

            }
            <AvatarBodyMap id={id} addItems={addItems} removeItems={removeItems} WardActive={btnValue} />
        </div>
    )
}

export default AvatarBody
