import { Link,  Button, Box, Typography, Container } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Link as RouterLink, useParams } from 'react-router-dom'
import { useState } from 'react'
import { useNewUserPassword } from 'hooks/useNewUserPassword'
import { Footer, InputField } from 'components'
import * as yup from 'yup'

const schema = yup.object().shape({
    password: yup.string().required(),
    password_confirmation: yup.string().test('passwords-match', 'Senhas devem coincidir', function(value) {
        return this.parent.password === value
    })
  })

export default function NewPassword() {
  const classes = useStyles()
  const { token, email } = useParams() 
  const [valid, setValid] = useState(true);
  const [submited, setSubmited] = useState(false);


  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: {
        token: token,
        email: email,
        password: '',
    },
    resolver: yupResolver(schema),
  })

  const {
    handleSubmit,
    formState: { isValid, isSubmitting },
  } = methods

  const { mutateAsync: onSubmit } = useNewUserPassword({
    onSuccess: () => setSubmited(true),
    onError: () =>setValid(false)
  })

  return (
    <Container component='main' maxWidth='xs'>
      <div className={classes.paper}>
          {
                valid ?
                <>
                    <Typography component='h1' variant='h5'>
                    Recuperar senha
                    </Typography>
                    <FormProvider {...methods}>
                        <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
                            <InputField
                                id='password'
                                name='password'
                                label='Senha'
                                variant='outlined'
                                type="password"
                                required
                                fullWidth
                            />
                            <InputField
                                id='password_confirmation'
                                name='password_confirmation'
                                label='Confirmar Senha'
                                variant='outlined'
                                type="password"
                                required
                                fullWidth
                            />
                            {
                                !submited ?
                                    <Button
                                    type='submit'
                                    fullWidth
                                    variant='contained'
                                    color='primary'
                                    className={classes.submit}
                                    disabled={!isValid || isSubmitting}
                                    >
                                        Recuperar
                                    </Button>
                                :
                                <Typography component='h5' variant='h5'>
                                    Senha alterada com sucesso!.<br/>
                                    Clique <Link component={RouterLink} to={`/login`}>aqui</Link> para entrar no sistema
                                </Typography>

                            }
                        </form>
                    </FormProvider>
                </>
                :
                <Typography component='h5' variant='h5'>
                    Link de recuperação inválido ou expirado.
                </Typography>
          }
        
      </div>
      <Box mt={8}>
        <Footer />
      </Box>
    </Container>
  )
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))
