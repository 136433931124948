import { createContext, useReducer, useContext } from "react";
import { useMutation, useQuery } from "react-query";
import { useParams } from "react-router-dom";
import PropTypes from 'prop-types'
import { fetchForms } from "api/services";
import { fetchForm } from "api/services/fetchForm";
import { publishForm } from "api/services/publishForm";
import { replicateForm } from "api/services/replicateForm";
import { updateConclusionMessage } from "api/services/updateConclusionMessage";

const ACTION_TYPES = {
    LIST: 'LIST_FORM',
    ADD: 'ADD_FORM',
    COMPLEMENT: 'COMPLEMENET_DATA_FORM',
    SELECT: 'SELECT_FORM',
    REMOVE: 'REMOVE_FORM',
    UPDATE: 'UPDATE_FORM',
    ADD_VERSION: 'ADD_VERSION_FORM',
  }
  

  const formsReducer = (state, action) => {
      switch (action.type) {
        case ACTION_TYPES.LIST: {
            return { 
              ...state, 
              forms: action.data }
          }
          case ACTION_TYPES.ADD: {
            return { 
              ...state, 
              forms: [...state.forms, action.data] }
          }
          case ACTION_TYPES.COMPLEMENT: {
            return {
              ...state,
              complementForm: action.data.data
            }
          }
          case ACTION_TYPES.ADD_VERSION: {
            return { 
              ...state, 
              complementForm: {
                ...state.complementForm,
                last_published: action.data
              }
            }
          }
          case ACTION_TYPES.REMOVE: {
            return { 
              ...state, 
              forms: state.forms.filter(({ id }) => id !== action.id) }
          }
          case ACTION_TYPES.SELECT: {
            return {
              ...state, 
              selected: action.formId,
              selectedForm: state.forms.filter((item) => item.id === action.formId)[0]
            }
          }
          case ACTION_TYPES.UPDATE: {
            return {
              ...state, 
              forms: state.forms.map((item) =>
                item.id === action.data.id ? { ...item, ...action.data } : item
              ),
            }
          }
          default: {
            return state
          }
      }
  }

  const FormsStateContext = createContext();
  const FormsDispatchContext = createContext();

  const FormsProvider = ({ children }) => {
      const { formId, projectId } = useParams();
      const [state, dispatch] = useReducer(formsReducer, {forms: [], selected: null, selectedForm: {}, complementForm: {last_published: null}})
    
      const { isLoading: formsLoading } = useQuery(['forms', projectId], () => fetchForms(projectId), {
          onSuccess: (data) => {
              dispatch({type: ACTION_TYPES.LIST, data: data.data})
              dispatch({type: ACTION_TYPES.SELECT,  formId})
          } 
      })
      const {isLoading: isLoadingComplementData} = useQuery(['selected-form', formId],() => fetchForm(formId), {
        onSuccess: (data) => {
          dispatch({type: ACTION_TYPES.COMPLEMENT,  data})
        }
      })
      const loading = formsLoading && isLoadingComplementData
      return (
          <FormsStateContext.Provider value={{ loading, ...state }}>
              <FormsDispatchContext.Provider value={dispatch}>{children}</FormsDispatchContext.Provider>
          </FormsStateContext.Provider>
      );
  }

  FormsProvider.propTypes = {
    children: PropTypes.node.isRequired,
  }

  const useForm = () => {
    const context = useContext(FormsStateContext)
    
    if (!context) {
      throw new Error('useForms must be used within an FormsProvider')
    }
  
    return context
  }

const usePublishForm = (id, options = {}) => {
  const dispatch = useDispatch();
  const { onSuccess, onError } = options

  return useMutation(() => publishForm(id), {
    onSuccess: (data) => {
      onSuccess(data)
      dispatch({type: ACTION_TYPES.ADD_VERSION, data: data.data})
    }, 
    onError: (err) => {
      onError(err.response.data)
    }
  })
}
const useFormDuplicate = (options = {}) => {
  const {onSuccess, onError} = options

  return useMutation(({formId, newName, results, projectId}) => replicateForm(formId, newName, results, projectId), {
    onSuccess: (data) => {
      if (onSuccess)
        onSuccess(data)
    },
    onError: (err) => {
      if (onError)
        onError(err.response.data)
    }
  })
}

const useDispatch = () => {
    const dispatch = useContext(FormsDispatchContext)
    if (dispatch === undefined) {
      throw new Error('useDispatch must be used within a FormsProvider')
    }
    return dispatch
}

const useUpdateConclusionMessage = (options = {}) => {
  const { onSuccess, onError } = options
  
  return useMutation(({id, text}) => updateConclusionMessage(id, text), {
    ...options,
    onSuccess: (data) => {
      if (onSuccess){
        onSuccess(data)
      }
    },
    onError: (err) => {
      if (onError)
        onError(err.response.data)
    }
  })
}

export {
  FormsProvider,
  useForm,
  usePublishForm,
  useUpdateConclusionMessage,
  useFormDuplicate,
  useDispatch
};