import api from 'api'

export const fetchResults = async (projectId) => {
  const { data } = await api.get(`/project/${projectId}/result`)

  return data
}

export const fetchResult = async (id) => {
    const { data } = await api.get(`/result/${id}`)

    return data
}

export const createResult = async (projectId, payload) => {
    const { data } = await api.post(`/project/${projectId}/result`, payload)

    return data
}

export const updateResult = async (projectId, payload) => {
    const { data } = await api.put(`/metric/${projectId}`, payload)

    return data
}