import { useState } from 'react'
import PropTypes from 'prop-types'
import FormContext from '../contexts/FormContext'

const FormProvider = ({steps = 0, children}) => {
    const [activeStep, setActiveStep] = useState(0);
    const [nextStepLast, setNextStepIsLast] = useState(false);

    return (
        <FormContext.Provider value={{activeStep, setActiveStep, nextStepLast, setNextStepIsLast, steps}}>
            {children}
        </FormContext.Provider>
    );
}

export default FormProvider

FormProvider.propTypes = {
    children: PropTypes.any.isRequired,
    steps: PropTypes.number.isRequired,
}

FormProvider.defaultProps = {}