
import { useQuery } from 'react-query'
import fetchAllProjects from 'api/services/fetchAllProjects'

export default (options ={}) => {
    const { onSuccess } = options

    return useQuery('projects', () => fetchAllProjects(), {
      ...options,
      onSuccess: (data) => {
          if (onSuccess)
            onSuccess(data)
      },
    })
}