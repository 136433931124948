import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { Paper } from '@material-ui/core'
import { useForm, FormProvider } from 'react-hook-form'
import { useUpdateMetric, useRemoveMetric } from 'hooks/metrics'
import { Accordion, Loader } from 'components'
import MetricForm from './metric-form/MetricForm'
// import GroupForm from './group-form/GroupForm'

import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

const schema = yup.object().shape({
  tag: yup.string().required().matches(/^[^0-9][\w&._]+$/, "A tag não pode começar com números e não pode possuir caracteres especiais ou espaços."),
})

const Group = ({ item, isDragging, project }) => {
  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: item,
    resolver: yupResolver(schema),
  })
  const {
    handleSubmit,
    formState: { isValid },
} = methods
  const { id, name } = item

  const { mutate: onSubmit, isLoading: isLoadingUpdate } = useUpdateMetric(id)
  const { mutate: onRemove, isLoading: isLoadingRemove } = useRemoveMetric(id)

  const classes = useStyles()

  return (
    <Accordion
      key={id}
      id={id}
      title={name}
      onSave={handleSubmit((data) => onSubmit(data))}
      onDelete={() => onRemove(id)}
      isDragging={isDragging}
      disabled={!isValid}
      summaryProps={{
        id,
        item,
      }}
    >
      <FormProvider {...methods}>
        <Paper className={classes.paper}>
          <div className={classes.content}>
            {isLoadingUpdate || isLoadingRemove ? <Loader size={80} /> : <MetricForm data={item} project={project} />}
          </div>
        </Paper>
      </FormProvider>
    </Accordion>
  )
}

const useStyles = makeStyles(() => ({
  paper: {
    padding: '20px',
    paddingBottom: '5px',
  },
  content: {
    '& > :first-child': {
      minHeight: '255px',
      marginBottom: '20px',
    },
  },
}))

Group.propTypes = {
  isDragging: PropTypes.bool.isRequired,
  item: PropTypes.object.isRequired,
  project: PropTypes.object.isRequired,
}

Group.defaultProps = {}

export default Group
