import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'
import { Route, Redirect } from 'react-router-dom'
import { useAuth } from 'hooks/auth'
import { MainLayout, Loader } from 'components'

const PrivateRoute = ({ provider: Provider, component: Component, wrapper: Wrapper, ...props }) => {
  const { user, isLoading } = useAuth()

  if (isLoading) {
    return <Loader size={100} minHeight='100vh' minWidth='550px' />
  }

  return (
    <Route
      {...props}
      render={({ location }) =>
        isEmpty(user) ? (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        ) : (
          <Provider>
            <MainLayout>
              <Wrapper>
                <Component />
              </Wrapper>
            </MainLayout>
          </Provider>
        )
      }
    />
  )
}

PrivateRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  provider: PropTypes.elementType,
  wrapper: PropTypes.elementType,
}

PrivateRoute.defaultProps = {
  provider: ({ children }) => children,
  wrapper: ({ children }) => children,
}

export default PrivateRoute
