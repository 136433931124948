/* eslint-disable eqeqeq */
import { get, isEmpty } from 'lodash'
import { array } from 'prop-types'

const validate = {
  '>': (x, y) => x > y,
  '<': (x, y) => x < y,
  '==': (x, y) => (typeof x !== 'object' || x == null) ? x == y : (y != '' && y != null) ? x.includes(y) : isEmpty(x),
  '!=': (x, y) => (typeof x !== 'object' || x == null) ? x != y : (y != '' && y != null) ? !x.includes(y) : !isEmpty(x),
}

const validateDisplayRules = (values, field) => {
  const displayRules = get(field, 'display_rules', [])
  const component = get(field, 'component', null)
  if (component === 'label') return false
  if (isEmpty(displayRules)) return true

  return displayRules.filter(rule => rule.type == "Field").every((rule) => validate[rule.operator](values[rule.field], rule.value))
}

export default validateDisplayRules

const validateDisplayRulesCustomer = (values, field) => {
  const displayRules = get(field, 'display_rules', [])
  const component = get(field, 'component', null)
  if (component === 'label') return false
  if (isEmpty(displayRules)) return true
  if (isEmpty(values)) return true
  return displayRules.filter(rule => rule.type == "Customer").every((rule) => validate[rule.operator](values[rule.field], rule.value))
}

export { validateDisplayRulesCustomer }