import { useState } from 'react'
import { useParams } from 'react-router-dom'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Fab, Button } from '@material-ui/core'
import { Add as AddIcon } from '@material-ui/icons'
import { useCreateAttribute } from 'hooks/customerAttributes'
import { Dialog, InputField } from 'components'
import * as yup from 'yup'

const schema = yup.object().shape({
  title: yup.string().required(),
  tag: yup.string().required().matches(/^[^0-9][\w&._]+$/, "A tag não pode começar com números e não pode possuir caracteres especiais ou espaços."),
})

const AddAtribute = ({ isEmpty }) => {
  const { projectId } = useParams();
  const [open, setOpenDialog] = useState(false)
  const classes = useStyles()
  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    shouldUnregister: true,
  })

  const {
    handleSubmit,
    formState: { isValid, isDirty },
  } = methods

  const { mutate: onSubmit, isLoading } = useCreateAttribute({
    onSuccess: () => setOpenDialog(false),
  })

  return (
    <>
      {isEmpty ? (
        <Button
          className={classes.button}
          onClick={() => setOpenDialog(true)}
          variant='contained'
          startIcon={<AddIcon />}
          color='primary'
        >
          Adicionar atributo para integração
        </Button>
      ) : (
        <Fab color='primary' aria-label='add' onClick={() => setOpenDialog(true)}>
          <AddIcon />
        </Fab>
      )}

      <FormProvider {...methods}>
        <Dialog
          title='Adicionar atributo'
          contentText='Preencha as informações para cadastrar o atributo que será usado na integração dos formulários.'
          open={open}
          onCancel={() => setOpenDialog(false)}
          isLoading={isLoading}
          submitDisabled={!isValid || !isDirty}
          onSubmit={handleSubmit(onSubmit)}
        >
          <InputField id='title' name='title' label='Nome do atributo' required fullWidth />
          <InputField id='tag' name='tag' label='Tag' required fullWidth />
        </Dialog>
      </FormProvider>
    </>
  )
}

const useStyles = makeStyles(() => ({
  button: {
    display: 'flex',
    margin: '20px auto',
  },
}))

AddAtribute.propTypes = {
  isEmpty: PropTypes.bool,
}

AddAtribute.defaultProps = {
  isEmpty: false
}

export default AddAtribute
