import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import TabContent from "../../../tabs/TabContent";
import {Button, Grid} from "@material-ui/core";
import SimpleSelectField from "../SimpleSelectField";
import MultiOptionSelectField from "../MultiOptionSelectField";
import {graduation} from "../../enum/physicalExamOptions";
import {InputField} from "../../../index";
import {useFormContext} from "react-hook-form";
import generateProgression from "../../functions/generateProgression";


const TabPanel = (props) => {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

const a11yProps = (index) => {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        maxWidth: '480px',
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
}));

const FingersTabs = ({items}, ...props) => {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className={classes.root}>
            <AppBar position="static" color="default" style={{width: '65.5vh', paddingRight: '40vh!mportant'}}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                >
                    {items.map((item, index) => (
                        <Tab key={item.name} label={item.name} {...a11yProps(0)} />
                    ))}
                </Tabs>
            </AppBar>
            {items.map((item, index) => (
                <TabContent key={item.name} value={value} index={index}
                            style={{width: '61.1vh', boxShadow: 'rgba(0, 0, 0, 0.1) 0px 6px 9px 1px'}}>
                    {item.component}
                </TabContent>
            ))}
        </div>
    );
}

const FingerForm = ({id, segmentSession}) => {
    const {control, watch, setValue} = useFormContext()
    const segment = segmentSession;
    const watchAmputationField = watch(`${id}.${segment}.01-amputation`)

    useEffect(()=>{
                    setValue(`${id}.${segment}.02-amputationLevel`, null);
    },[watchAmputationField]);


    return (
        <>
            <Grid container spacing={3} className="form-grid" justifyContent="center">
                <Grid item sm={10} md={5}>
                    <SimpleSelectField
                        segment={segment}
                        id={id}
                        name={'01-amputation'}
                        label={'Amputação'}
                        control={control}/>
                </Grid>

                {
                    watchAmputationField === 1 &&
                    <Grid item sm={10} md={5}>
                        <InputField
                            id={id}
                            name={`${id}.${segment}.02-amputationLevel`}
                            label={'Nível de Amputação'}
                            control={control}/>
                    </Grid>
                }

                <Grid item sm={10} md={5}>
                    <SimpleSelectField
                        segment={segment}
                        id={id}
                        name={'03-march'}
                        label={'Marcha'}
                        trueLabel={'Normal'}
                        falseLabel={'Alterado'}
                        control={control}/>
                </Grid>

                <Grid item sm={10} md={5}>
                    <SimpleSelectField
                        segment={segment}
                        id={id}
                        name={'04-squat'}
                        label={'Agachamento'}
                        trueLabel={'Normal'}
                        falseLabel={'Alterado'}
                        control={control}/>
                </Grid>

                <Grid item sm={10} md={5}>
                    <SimpleSelectField
                        segment={segment}
                        id={id}
                        name={'05-shortening'}
                        label={'Encurtamento'}
                        trueLabel={'Normal'}
                        falseLabel={'Alterado'}
                        control={control}/>
                </Grid>

                <Grid item sm={10} md={5}>
                    <SimpleSelectField
                        segment={segment}
                        id={id}
                        name={'06-flexion'}
                        label={'Flexão'}
                        trueLabel={'Normal'}
                        falseLabel={'Alterado'}
                        control={control}/>
                </Grid>

                <Grid item sm={10} md={5}>
                    <SimpleSelectField
                        segment={segment}
                        id={id}
                        name={'07-extension'}
                        label={'Extensão'}
                        trueLabel={'Normal'}
                        falseLabel={'Alterado'}
                        control={control}/>
                </Grid>

                <Grid item sm={10} md={5}>
                    <SimpleSelectField
                        segment={segment}
                        id={id}
                        name={'08-operativeScar'}
                        label={'Cicatriz operatória'}
                        trueLabel={'Existente'}
                        falseLabel={'Inexistente'}
                        control={control}/>
                </Grid>

                <Grid item sm={10} md={5}>
                    <SimpleSelectField
                        segment={segment}
                        id={id}
                        name={'09-sensitivity'}
                        label={'Sensibilidade'}
                        trueLabel={'Normal'}
                        falseLabel={'Anormal'}
                        control={control}/>
                </Grid>

                <Grid item sm={10} md={5}>
                    <SimpleSelectField
                        segment={segment}
                        id={id}
                        name={'10-coloring'}
                        label={'Coloração'}
                        trueLabel={'Normal'}
                        falseLabel={'Anormal'}
                        control={control}/>
                </Grid>

                <Grid item sm={10} md={5}>
                    <SimpleSelectField
                        segment={segment}
                        id={id}
                        name={'11-temperature'}
                        label={'Temperatura'}
                        trueLabel={'Normal'}
                        falseLabel={'Anormal'}
                        control={control}/>
                </Grid>

                <Grid item sm={10} md={5}>
                    <SimpleSelectField
                        segment={segment}
                        id={id}
                        name={'12-inflammatorySigns'}
                        label={'Sinais Inflamatórios'}
                        trueLabel={'Existente'}
                        falseLabel={'Inexistente'}
                        control={control}/>
                </Grid>

                <Grid item sm={10} md={5}>
                    <SimpleSelectField
                        segment={segment}
                        id={id}
                        name={'13-muscleChanges'}
                        label={'Alterações Musculares'}
                        trueLabel={'Existente'}
                        falseLabel={'Inexistente'}
                        control={control}/>
                </Grid>

                <Grid item sm={10} md={5}>
                    <SimpleSelectField
                        segment={segment}
                        id={id}
                        name={'14-motorChanges'}
                        label={'Alterações Motoras'}
                        trueLabel={'Existente'}
                        falseLabel={'Inexistente'}
                        control={control}/>
                </Grid>

                <Grid item sm={10} md={5}>
                    <SimpleSelectField
                        segment={segment}
                        id={id}
                        name={'15-segmentOrMember'}
                        label={'A lesão se limita ao segmento ou repercute para todo o pé?'}
                        trueLabel={'Segmento'}
                        falseLabel={'Pé'}
                        control={control}/>
                </Grid>

                <Grid item sm={10} md={5}>
                    <MultiOptionSelectField
                        segment={segment}
                        id={id}
                        name={'16-graduation'}
                        label={'Graduação'}
                        control={control}
                        options={graduation}/>
                </Grid>
            </Grid>
        </>
    )
}
const DedosPe = ({id, segment, removeItems, segmentName}) => {
    return (
        <>
            <Button style={{width: '100%', fontWeight: '900', fontSize: 'medium', backgroundColor: 'red'}}
                    variant={'contained'}
                    size='large'
                    color='secondary'
                    onClick={() => removeItems(segmentName,segment)}
            >
                Excluir Exame
            </Button>
            <FingersTabs items={[
                {name: '1º Dedo', component: <FingerForm id={id} segmentSession={segment + '.01-firstFinger'}/>},
                {name: '2º Dedo', component: <FingerForm id={id} segmentSession={segment + '.02-secondFinger'}/>},
                {name: '3º Dedo', component: <FingerForm id={id} segmentSession={segment + '.03-thirdFinger'}/>},
                {name: '4º Dedo', component: <FingerForm id={id} segmentSession={segment + '.04-fourthFinger'}/>},
                {name: '5º Dedo', component: <FingerForm id={id} segmentSession={segment + '.05-fifthFinger'}/>},
            ]}/>
        </>
    )
}
export default DedosPe

