import { makeStyles } from '@material-ui/core/styles'
import { isEmpty } from 'lodash'
import { useParams } from 'react-router-dom'
import { useMetrics } from 'hooks/metrics'
import { useResults } from 'hooks/results'
import { DraggableList, Loader, EmptyState } from 'components'
import { useFetchProject } from 'hooks/useProject'
import Group from './group/Group'
import AddResult from './add-result/AddResult'

const Results = () => {
  const { projectId } = useParams();
  const { isLoading, metrics } = useMetrics(projectId)
  const { isLoadingResults, results } = useResults(projectId)
  const { data, isLoading: isLoadingProject } = useFetchProject(projectId, { all: true })
  const classes = useStyles()

  if (isLoading || isLoadingProject || isLoadingResults) {
    return <Loader size={100}/>
  }

  if (isEmpty(metrics)) {
    return (
      <>
        <EmptyState />
        <AddResult isEmpty />
      </>
    )
  }
  
  return (
    <>
      <DraggableList items={metrics}>
        {({ ref, item, snapshotProps, ...rest }) => (
          <div className={classes.item} ref={ref} {...rest}>
            <Group item={item} isDragging={snapshotProps.isDragging} project={data} results={results} />
          </div>
        )}
      </DraggableList>
    </>
  )
}

const useStyles = makeStyles(() => ({
  item: {
    marginBottom: '20px',
  },
}))

Results.propTypes = {}

Results.defaultProps = {}

export default Results
