import PropTypes from 'prop-types'
import { useFormContext, Controller } from 'react-hook-form'
import { FormControlLabel, Checkbox as MUICheckbox } from '@material-ui/core'

const Checkbox = ({ defaultValue, name, label, ...rest }) => {
  const {
    control,
    formState: { isSubmitting },
  } = useFormContext()

  return (
    <Controller
      render={({ field }) => (
        <FormControlLabel label={label} control={<MUICheckbox {...field} {...rest} checked={field.value} />} />
      )}
      name={name}
      control={control}
      defaultValue={defaultValue}
      disabled={isSubmitting}
    />
  )
}

Checkbox.propTypes = {
  defaultValue: PropTypes.string,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
}

Checkbox.defaultProps = {
  defaultValue: '',
}

export default Checkbox
