import { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

import { InputField } from 'components'
import DevolutiveTemplate from '../devolutive-template/DevolutiveTemplate'
import { Button } from '@material-ui/core'
import ImportDocModal from './ImportDocModal'

const returnRecursiveTrace = (object = null, key = "", ret = []) => {
  if (typeof object !== "object" || object == null) return ret.push(key);
  const keysIndex = Object.keys(object);
  for (let i =0; i < keysIndex.length; i++) {
    const keyAux = key;
    returnRecursiveTrace(object[keysIndex[i]] || null, key += "." + keysIndex[i], ret)
    key = keyAux;
  }
  return ret;
}

const DevolutiveForm = ({ project }) => {
  const classes = useStyles()
  const [isOpen, setIsOpen] = useState(false);
  const [docContent, setDocContent] = useState('');

  const options = useMemo(()=> {
      const newIds = [];
      Object.keys(project)
      .forEach(formKey =>  {
        console.log('a', formKey);
        let field = returnRecursiveTrace(project[formKey], formKey)
        console.log('b', field);
        field.forEach(i => newIds.push(`{{${i}}}`))
      })
      return newIds
  }, [project])

  return (
    <div>
      <div className={classes.inputs}>
        <InputField
          className={classes.input}
          id='title'
          name='title'
          label='Nome da Devolutiva'
          variant='standard'
          fullWidth
        />
        <InputField
          className={classes.input}
          id='tag'
          name='tag'
          label='Tag da Devolutiva'
          variant='standard'
          required
          fullWidth
        />
        <Button 
          size='small' 
          color='primary' 
          variant='contained'
          onClick={() => setIsOpen(true)}
          style={{height: '40px'}}
        >Importar Documento</Button>
      </div>  
      <DevolutiveTemplate id='template' name='template' options={options} docContent={docContent} />
      <ImportDocModal isOpen={isOpen} setIsOpen={setIsOpen} setDocContent={setDocContent} />
    </div>
  )
}

const useStyles = makeStyles(() => ({
  inputs: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '20px',
    '& > :first-child': {
      marginRight: '20px',
    },
  },
  input: {
    '&.MuiTextField-root': {
      maxWidth: '250px',
    },
  },
}))

DevolutiveForm.propTypes = {
  project: PropTypes.object.isRequired,
}

DevolutiveForm.defaultProps = {}

export default DevolutiveForm
