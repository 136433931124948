import {useFormContext} from 'react-hook-form'
import {graduation} from "../../enum/physicalExamOptions";
import SimpleSelectField from "../SimpleSelectField";
import MultiOptionSelectField from "../MultiOptionSelectField";
import {Button, Grid} from "@material-ui/core";
import React from "react";

const Neurologico = ({id, removeItems, segmentName}) => {
    const segment = 'neurologic'
    const {control} = useFormContext()
    return (
        <>
            <Button style={{width: '100%', fontWeight: '900', fontSize: 'medium', backgroundColor: 'red'}}
                    variant={'contained'}
                    size='large'
                    color='secondary'
                    onClick={() => removeItems(segmentName,segment)}
            >
                Excluir Exame
            </Button>
            <Grid container spacing={3} className="form-grid" justifyContent="center">
                <Grid item sm={10} md={5}>
                    <SimpleSelectField
                        segment={segment}
                        id={id}
                        name={'01-recentMemory'}
                        label={'Memória recente'}
                        trueLabel={'Normal'}
                        falseLabel={'Alterada'}
                        control={control}/>
                </Grid>

                <Grid item sm={10} md={5}>
                    <SimpleSelectField
                        segment={segment}
                        id={id}
                        name={'02-realityJudgment'}
                        label={'Juízo de Realidade'}
                        trueLabel={'Normal'}
                        falseLabel={'Alterado'}
                        control={control}/>
                </Grid>

                <Grid item sm={10} md={5}>
                    <SimpleSelectField
                        segment={segment}
                        id={id}
                        name={'03-abstractReasoning'}
                        label={'Raciocínio Abstrato'}
                        trueLabel={'Normal'}
                        falseLabel={'Alterado'}
                        control={control}/>
                </Grid>

                <Grid item sm={10} md={5}>
                    <SimpleSelectField
                        segment={segment}
                        id={id}
                        name={'04-involuntaryMovements'}
                        label={'Movimentos Involuntários'}
                        control={control}/>
                </Grid>

                <Grid item sm={10} md={5}>
                    <SimpleSelectField
                        segment={segment}
                        id={id}
                        name={'05-atrophies'}
                        label={'Presença Atrofias'}
                        control={control}/>
                </Grid>

                <Grid item sm={10} md={5}>
                    <SimpleSelectField
                        segment={segment}
                        id={id}
                        name={'06-asymmetries'}
                        label={'Presença Assimetrias'}
                        trueLabel={'Normal'}
                        falseLabel={'Alterado'}
                        control={control}/>
                </Grid>

                <Grid item sm={10} md={5}>
                    <SimpleSelectField
                        segment={segment}
                        id={id}
                        name={'07-march'}
                        label={'Marcha'}
                        trueLabel={'Normal'}
                        falseLabel={'Alterado'}
                        control={control}/>
                </Grid>

                <Grid item sm={10} md={5}>
                    <SimpleSelectField
                        segment={segment}
                        id={id}
                        name={'08-muscleForce'}
                        label={'Força Muscular'}
                        trueLabel={'Normal'}
                        falseLabel={'Alterada'}
                        control={control}/>
                </Grid>

                <Grid item sm={10} md={5}>
                    <SimpleSelectField
                        segment={segment}
                        id={id}
                        name={'09-remoteMemory'}
                        label={'Memória Remota'}
                        trueLabel={'Normal'}
                        falseLabel={'Anormal'}
                        control={control}/>
                </Grid>

                <Grid item sm={10} md={5}>
                    <SimpleSelectField
                        segment={segment}
                        id={id}
                        name={'10-attention'}
                        label={'Atenção'}
                        trueLabel={'Normal'}
                        falseLabel={'Anormal'}
                        control={control}/>
                </Grid>

                <Grid item sm={10} md={5}>
                    <SimpleSelectField
                        segment={segment}
                        id={id}
                        name={'11-language'}
                        label={'Linguagem'}
                        trueLabel={'Normal'}
                        falseLabel={'Anormal'}
                        control={control}/>
                </Grid>

                <Grid item sm={10} md={5}>
                    <SimpleSelectField
                        segment={segment}
                        id={id}
                        name={'12-humor'}
                        label={'Humor'}
                        trueLabel={'Normal'}
                        falseLabel={'Anormal'}
                        control={control}/>
                </Grid>

                <Grid item sm={10} md={5}>
                    <SimpleSelectField
                        segment={segment}
                        id={id}
                        name={'13-affection'}
                        label={'Afeto'}
                        trueLabel={'Normal'}
                        falseLabel={'Anormal'}
                        control={control}/>
                </Grid>

                <Grid item sm={10} md={5}>
                    <MultiOptionSelectField
                        segment={segment}
                        id={id}
                        name={'14-speech'}
                        label={'Discurso'}
                        control={control}
                        options={[
                            {label: 'Falante', value: 'falante'},
                            {label: 'Fluente', value: 'fluente'},
                            {label: 'Pouco espontâneo', value: 'pouco espontâneo'},
                            {label: 'Lento', value: 'lento'},
                            {label: 'Confuso', value: 'confuso'}
                        ]}/>
                </Grid>

                <Grid item sm={10} md={5}>
                    <MultiOptionSelectField
                        segment={segment}
                        id={id}
                        name={'15-perception'}
                        label={'Percepção'}
                        control={control}
                        options={[
                            {label: 'Normal', value: 'normal'},
                            {label: 'Alucinações', value: 'apresentando alucinações'},
                            {label: 'Ilusões', value: 'apresentando ilusões'},
                            {label: 'Desrealização', value: 'apresentando desrealização'},
                            {label: 'Despersonalização', value: 'apresentando despersonalização'}
                        ]}/>
                </Grid>

                <Grid item sm={10} md={5}>
                    <MultiOptionSelectField
                        segment={segment}
                        id={id}
                        name={'16-thought'}
                        label={'Pensamento'}
                        control={control}
                        options={[
                            {label: 'Lógico e Coerente', value: 'lógico e coerente'},
                            {label: 'Pensamento ilógico e incoerente', value: 'ilógico e incoerente'},
                            {label: 'Fuga de ideias', value: 'fuga de ideias'},
                            {label: 'Lento', value: 'lento'},
                            {label: 'Vazio', value: 'vazio'}
                        ]}/>
                </Grid>

                <Grid item sm={10} md={5}>
                    <SimpleSelectField
                        segment={segment}
                        id={id}
                        name={'17-impulsivity'}
                        label={'Impulsividade'}
                        trueLabel={'Controlada'}
                        falseLabel={'Não Controlada'}
                        control={control}/>
                </Grid>


                <Grid item sm={10} md={5}>
                    <MultiOptionSelectField //check this-field
                        segment={segment}
                        id={id}
                        name={'18-neurologicalInjuryCoursingWith'}
                        label={'Lesão Neurológica cursando com'}
                        control={control}
                        options={[
                            {
                                label: 'Dano cognitivo-comportamental alienante',
                                value: 'dano cognitivo-comportamental alienante',
                            },
                            {
                                label: 'Impedimento do senso de orientação espacial e/ou do livre deslocamento corporal',
                                value: 'impedimento do senso de orientação espacial e/ou do livre deslocamento corporal'
                            },
                            {
                                label: 'Perda completa do controle esfincteriano',
                                value: 'perda completa do controle esfincteriano',
                            },
                            {
                                label: 'Comprometimento de função vital ou autonômica',
                                value: 'comprometimento de função vital ou autonômica'
                            }
                        ]}/>
                </Grid>

                <Grid item sm={10} md={5}>
                    <MultiOptionSelectField
                        segment={segment}
                        id={id}
                        name={'19-graduation'}
                        label={'Graduação'}
                        control={control}
                        options={graduation}/>
                </Grid>
            </Grid>
        </>
    )
}

export default Neurologico
