import { useMutation, useQueryClient } from 'react-query'
import { updateForm } from 'api/services/updateForm'

export const useUpdateForm = (formId, options = {}) => {
  const queryClient = useQueryClient()
  const { onSuccess = () => {} } = options

  return useMutation((data) => updateForm(formId, data), {
    ...options,
    onSuccess: (data) => {
      queryClient.invalidateQueries('forms')
      onSuccess(data)
    },
  })
}
