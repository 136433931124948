const brandings = {
    'clic_regulação': {
        color_theme: '#329c82',
        font_family: 'Verdana, sans-serif',
        font_size: 16,
        text_alignment: 'justify',
        border_radius: 5,
        columns: 1,
    },
    'health_id': {
        color_theme: '#216D40',
        font_family: 'Roboto, sans-serif',
        font_size: 16,
        text_alignment: 'justify',
        border_radius: 5,
        columns: 1,
    },
    'notrelife': {
        color_theme: '#F49E00',
        font_family: 'Roboto, sans-serif',
        font_size: 16,
        text_alignment: 'justify',
        border_radius: 12,
        columns: 1,
    },
    'experweb': {
        color_theme: '#2B6CB0',
        color_theme_dark: '#2B6CB0',
        color_background_theme_dark: '#1F2733',
        font_family: 'Roboto, sans-serif',
        font_size: 16,
        text_alignment: 'justify',
        border_radius: 12,
        columns: 1,

    },
}

export default brandings;