import physicalExamAvatarFront from "../assets/mini-human-body-front.png";
import physicalExamAvatarBack from "../assets/mini-human-body-back.png";
import React from "react";
import Neurologico from "./forms/Neurologico";
import Olho from "./forms/Olho";
import EstruturaCranioFacial from "./forms/EstruturaCranioFacial";
import Fonacao from "./forms/Fonacao";
import Ouvido from "./forms/Ouvido";
import EstruturaCervical from "./forms/EstruturaCervical";
import Ombro from "./forms/Ombro";
import EstruturaToracica from "./forms/EstruturaToracica";
import EstruturaAbdominal from "./forms/EstruturaAbdominal";
import Quadril from "./forms/Quadril";
import EstruturaPelvica from "./forms/EstruturaPelvica";
import Punho from "./forms/Punho";
import Mao from "./forms/Mao";
import DedosMao from "./forms/DedosMao";
import Joelho from "./forms/Joelho";
import Tornozelo from "./forms/Tornozelo";
import Pe from "./forms/Pe";
import DedosPe from "./forms/DedosPe";
import Cervical from "./forms/Cervical";
import Toracico from "./forms/Toracico";
import Cotovelo from "./forms/Cotovelo";
import Lombar from "./forms/Lombar";
import Retroperitoneal from "./forms/Retroperitoneal";
import Cardiovascular from "./forms/Cardiovascular";

const AvatarFrontMap = ({id, addItems, removeItems}) => {
    return (
        <div>
            <img useMap={"#image-map"} alt=""/>
            <map name={"image-map"}>
                <area style={{cursor: "pointer"}} alt={"Neurológico"} coords={"125,19,10"}
                      shape={"circle"} target="" title={"Neurológico"}
                      onClick={() => addItems({name:'Neurológico', component: <Neurologico removeItems={removeItems} segmentName={'Neurológico'} id={id}/> })}/>

                <area style={{cursor: "pointer"}} alt={"Olho Esquerdo"} coords={"136,42,7"}
                      shape={"circle"} target="" title={"Olho Esquerdo"}
                      onClick={() => addItems({name:'Olho Esquerdo', component: <Olho segment={'leftEyes'} removeItems={removeItems} segmentName={'Olho Esquerdo'} id={id}/> })}/>

                <area style={{cursor: "pointer"}} alt={"Olho Direito"} coords={"115,41,9"}
                      shape={"circle"} target="" title={"Olho Direito"}
                      onClick={() => addItems({name:'Olho Direito', component: <Olho segment={'rightEyes'} removeItems={removeItems} segmentName={'Olho Direito'} id={id}/> })}/>

                <area style={{cursor: "pointer"}} alt={"Estrutura Crânio Facial"} coords={"125,50,8"}
                      shape={"circle"} target="" title={"Estrutura Crânio Facial"}
                      onClick={() => addItems({name:'Estrutura Crânio Facial', component: <EstruturaCranioFacial removeItems={removeItems} segmentName={'Estrutura Crânio Facial'} id={id}/> })}/>

                <area style={{cursor: "pointer"}} alt={"Ouvido Esquerdo"} coords={"151,58,7"}
                      shape={"circle"} target="" title={"Ouvido Esquerdo"}
                      onClick={() => addItems({name:'Ouvido Esquerdo', component: <Ouvido segment={'leftEar'} removeItems={removeItems} segmentName={'Ouvido Esquerdo'} id={id}/> })}/>

                <area style={{cursor: "pointer"}} alt={"Ouvido Direito"} coords={"99,57,8"}
                      shape={"circle"} target="" title={"Ouvido Direito"}
                      onClick={() => addItems({name:'Ouvido Direito', component: <Ouvido segment={'rightEar'} removeItems={removeItems} segmentName={'Ouvido Direito'} id={id}/> })}/>

                <area style={{cursor: "pointer"}} alt={"Exame da Fonação"} coords={"125,60,8"}
                      shape={"circle"} target="" title={"Exame da Fonação"}
                      onClick={() => addItems({name:'Exame da Fonação', component: <Fonacao removeItems={removeItems} segmentName={'Exame da Fonação'} id={id}/> })}/>

                <area style={{cursor: "pointer"}} alt={"Estrutura Cervical"} coords={"125,83,8"}
                      shape={"circle"} target="" title={"Estrutura Cervical"}
                      onClick={() => addItems({name:'Estrutura Cervical', component: <EstruturaCervical removeItems={removeItems} segmentName={'Estrutura Cervical'} id={id}/> })}/>

                <area style={{cursor: "pointer"}} alt={"Ombro Esquerdo"} coords={"183,103,11"}
                      shape={"circle"} target="" title={"Ombro Esquerdo"}
                      onClick={() => addItems({name:'Ombro Esquerdo', component: <Ombro segment={'leftShoulder'} removeItems={removeItems} segmentName={'Ombro Esquerdo'} id={id}/> })}/>

                <area style={{cursor: "pointer"}} alt={"Ombro Direito"} coords={"66,102,9"}
                      shape={"circle"} target="" title={"Ombro Direito"}
                      onClick={() => addItems({name:'Ombro Direito', component: <Ombro segment={'rightShoulder'} removeItems={removeItems} segmentName={'Ombro Direito'} id={id}/> })}/>

                <area style={{cursor: "pointer"}} alt={"Estrutura Torácica"} coords={"124,124,9"}
                      shape={"circle"} target="" title={"Estrutura Torácica"}
                      onClick={() => addItems({name:'Estrutura Torácica', component: <EstruturaToracica removeItems={removeItems} segmentName={'Estrutura Torácica'} id={id}/> })}/>

                <area style={{cursor: "pointer"}} alt={"Cardiovascular "} coords={"144,115,9"}
                      shape={"circle"} target="" title={"Cardiovascular"}
                      onClick={() => addItems({name:'Cardiovascular', component: <Cardiovascular removeItems={removeItems} segmentName={'Cardiovascular'} id={id}/> })}/>

                <area style={{cursor: "pointer"}} alt={"Estrutura Abdominal"} coords={"126,176,9"}
                      shape={"circle"} target="" title={"Estrutura Abdominal"}
                      onClick={() => addItems({name:'Estrutura Abdominal', component: <EstruturaAbdominal removeItems={removeItems} segmentName={'Estrutura Abdominal'} id={id}/> })}/>

                <area style={{cursor: "pointer"}} alt={"Quadril Esquerdo"} coords={"154,204,10"}
                      shape={"circle"} target="" title={"Quadril Esquerdo"}
                      onClick={() => addItems({name:'Quadril Esquerdo', component: <Quadril segment={'leftHip'} removeItems={removeItems} segmentName={'Quadril Esquerdo'} id={id} /> })}/>

                <area style={{cursor: "pointer"}} alt={"Quadril Direito"} coords={"96,204,10"}
                      shape={"circle"} target="" title={"Quadril Direito"}
                      onClick={() => addItems({name:'Quadril Direito', component: <Quadril segment={'rightHip'} removeItems={removeItems} segmentName={'Quadril Direito'} id={id} />} )}/>

                <area style={{cursor: "pointer"}} alt={"Estrutura Pélvica"} coords={"125,227,10"}
                      shape={"circle"} target="" title={"Estrutura Pélvica"}
                      onClick={() => addItems({name:'Estrutura Pélvica', component: <EstruturaPelvica removeItems={removeItems} segmentName={'Estrutura Pélvica'} id={id}/> })}/>

                <area style={{cursor: "pointer"}} alt={"Punho Esquerdo"} coords={"225,216,9"}
                      shape={"circle"} target="" title={"Punho Esquerdo"}
                      onClick={() => addItems({name:'Punho Esquerdo', component: <Punho segment={'leftFist'} removeItems={removeItems} segmentName={'Punho Esquerdo'} id={id}/> })}/>

                <area style={{cursor: "pointer"}} alt={"Punho Direito"} coords={"27,217,9"}
                      shape={"circle"} target="" title={"Punho Direito"}
                      onClick={() => addItems({name:'Punho Direito', component: <Punho segment={'rightFist'} removeItems={removeItems} segmentName={'Punho Direito'} id={id}/> })}/>

                <area style={{cursor: "pointer"}} alt={"Mão Esquerda"} coords={"226,234,6"}
                      shape={"circle"} target="" title={"Mão Esquerda"}
                      onClick={() => addItems({name:'Mão Esquerda', component: <Mao segment={'leftHand'} removeItems={removeItems} segmentName={'Mão Esquerda'} id={id} /> })}/>

                <area style={{cursor: "pointer"}} alt={"Mão Direita"} coords={"20,234,7"}
                      shape={"circle"} target="" title={"Mão Direita"}
                      onClick={() => addItems({name:'Mão Direita', component: <Mao segment={'rightHand'} removeItems={removeItems} segmentName={'Mão Direita'} id={id}/> })}/>

                <area style={{cursor: "pointer"}} alt={"Dedos da Mão Esquerda"} coords={"232,251,9"}
                      shape={"circle"} target="" title={"Dedos da Mão Esquerda"}
                      onClick={() => addItems({name:'Dedos da Mão Esquerda', component: <DedosMao segment={'leftHandFingers'} removeItems={removeItems} segmentName={'Dedos da Mão Esquerda'} id={id} />})}/>

                <area style={{cursor: "pointer"}} alt={"Dedos da Mão Direita"} coords={"16,250,9"}
                      shape={"circle"} target="" title={"Dedos da Mão Direita"}
                      onClick={() => addItems({name:'Dedos da Mão Direita', component: <DedosMao segment={'rightHandFingers'} removeItems={removeItems} segmentName={'Dedos da Mão Direita'} id={id} /> })}/>

                <area style={{cursor: "pointer"}} alt={"Joelho Esquerdo"} coords={"157,331,10"}
                      shape={"circle"} target="" title={"Joelho Esquerdo"}
                      onClick={() => addItems({name:'Joelho Esquerdo', component: <Joelho segment={'leftKness'} removeItems={removeItems} segmentName={'Joelho Esquerdo'} id={id}/> })}/>

                <area style={{cursor: "pointer"}} alt={"Joelho Direito"} coords={"91,330,8"}
                      shape={"circle"} target="" title={"Joelho Direito"}
                      onClick={() => addItems({name:'Joelho Direito', component: <Joelho segment={'rightKness'} removeItems={removeItems} segmentName={'Joelho Direito'} id={id}/> })}/>

                <area style={{cursor: "pointer"}} alt={"Tornozelo Esquerdo"} coords={"165,426,9"}
                      shape={"circle"} target="" title={"Tornozelo Esquerdo"}
                      onClick={() => addItems({name:'Tornozelo Esquerdo', component: <Tornozelo segment={'leftAnkle'} removeItems={removeItems} segmentName={'Tornozelo Esquerdo'} id={id}/> })}/>

                <area style={{cursor: "pointer"}} alt={"Tornozelo Direito"} coords={"82,425,8"}
                      shape={"circle"} target="" title={"Tornozelo Direito"}
                      onClick={() => addItems({name:'Tornozelo Direito', component: <Tornozelo segment={'rightAnkle'} removeItems={removeItems} segmentName={'Tornozelo Direito'} id={id}/> })}/>

                <area style={{cursor: "pointer"}} alt={"Pé Esquerdo"} coords={"170,440,7"}
                      shape={"circle"} target="" title={"Pé Esquerdo"}
                      onClick={() => addItems({name:'Pé Esquerdo', component: <Pe segment={'leftFoot'} removeItems={removeItems} segmentName={'Pé Esquerdo'} id={id}/> })}/>

                <area style={{cursor: "pointer"}} alt={"Pé Direito"} coords={"76,441,8"}
                      shape={"circle"} target="" title={"Pé Direito"}
                      onClick={() => addItems({name:'Pé Direito', component: <Pe segment={'rightFoot'} removeItems={removeItems} segmentName={'Pé Direito'} id={id}/> })}/>

                <area style={{cursor: "pointer"}} alt={"Dedos do Pé Esquerdo"} coords={"179,455,8"}
                      shape={"circle"} target="" title={"Dedos do Pé Esquerdo"}
                      onClick={() => addItems({name:'Dedos do Pé Esquerdo', component: <DedosPe segment={'leftFootFingers'} removeItems={removeItems} segmentName={'Dedos do Pé Esquerdo'} id={id}/> })}/>

                <area style={{cursor: "pointer"}} alt={"Dedos do Pé Direito"} coords={"63,456,8"}
                      shape={"circle"} target="" title={"Dedos do Pé Direito"}
                      onClick={() => addItems({name:'Dedos do Pé Direito', component: <DedosPe segment={'rightFootFingers'} removeItems={removeItems} segmentName={'Dedos do Pé Direito'} id={id}/> })}/>
            </map>
        </div>
    )
}

const AvatarBackMap = ({id, addItems, removeItems}) => {
    return (
        <div>
            <img useMap={"#image-map"} alt=""/>
            <map name={"image-map"}>
                <area style={{cursor: "pointer"}} alt={"Segmento Cervical"} coords={"115,82,10"}
                      shape={"circle"} target="" title={"Segmento Cervical"}
                      onClick={() => addItems({name:'Segmento Cervical', component: <Cervical removeItems={removeItems} segmentName={'Segmento Cervical'} id={id}/> })}/>

                <area style={{cursor: "pointer"}} alt={"Segmento Torácico"} coords={"115,128,10"}
                      shape={"circle"} target="" title={"Segmento Torácico"}
                      onClick={() => addItems({name:'Segmento Torácico', component: <Toracico removeItems={removeItems} segmentName={'Segmento Torácico'} id={id}/> })}/>

                <area style={{cursor: "pointer"}} alt={"Cotovelo Esquerdo"} coords={"38,175,13"}
                      shape={"circle"} target="" title={"Cotovelo Esquerdo"}
                      onClick={() => addItems({name:'Cotovelo Esquerdo', component: <Cotovelo segment={'leftElbow'} removeItems={removeItems} segmentName={'Cotovelo Esquerdo'} id={id}/> })}/>

                <area style={{cursor: "pointer"}} alt={"Cotovelo Direito"} coords={"193,174,11"}
                      shape={"circle"} target="" title={"Cotovelo Direito"}
                      onClick={() => addItems({name:'Cotovelo Direito', component: <Cotovelo segment={'rightElbow'} removeItems={removeItems} segmentName={'Cotovelo Direito'} id={id}/> })}/>

                <area style={{cursor: "pointer"}} alt={"Segmento Lombar"} coords={"114,198,12"}
                      shape={"circle"} target="" title={"Segmento Lombar"}
                      onClick={() => addItems({name:'Segmento Lombar', component: <Lombar removeItems={removeItems} segmentName={'Segmento Lombar'} id={id}/> })}/>

                <area style={{cursor: "pointer"}} alt={"Estrutura Retroperitoneal"} coords={"115,233,13"}
                      shape={"circle"} target="" title={"Estrutura Retroperitoneal"}
                      onClick={() => addItems({name:'Estrutura Retroperitoneal', component: <Retroperitoneal removeItems={removeItems} segmentName={'Estrutura Retroperitoneal'} id={id}/> })}/>
            </map>
        </div>
    )
}

const AvatarBodyMap = ({id, WardActive, addItems, removeItems}, ...props) => {

    return (
        <>
            <img src={WardActive === 'ForWard' ? physicalExamAvatarFront : physicalExamAvatarBack} useMap={"#image-map"} alt=""/>
            {
                WardActive === 'ForWard' ?
                    <AvatarFrontMap id={id} removeItems={removeItems} addItems={addItems}/> :
                    <AvatarBackMap id={id}  removeItems={removeItems} addItems={addItems}/>
            }
        </>)
}

export default AvatarBodyMap
