import { useState, useEffect } from 'react'
import { get } from 'lodash'
import { Box, Typography, Button, CircularProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Editor } from 'ckeditor5-custom-build/build/ckeditor'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import { useSteps } from 'hooks/steps'
import { useTexts, useUpdateText } from 'hooks/fields'
import { typeText } from 'enum/form-field'

const Informations = ({customer = {}}) => {
  const { selected: stepId } = useSteps()
  const { text } = useTexts(stepId)
  const id = get(text, 'id', null)
  const value = get(text, 'text', null)

  const { mutate: onSave, isLoading } = useUpdateText(id, stepId)

  const buildFeed = () => {
    const options = Object.keys(customer)
    if (options && options.length > 0) {
      return options.map(opt => {
        return `{{customer.${opt}}}`
      })
    }
    return []
  }

  const feedOptions = buildFeed()

  console.log('options', feedOptions)

  const [editorValue, changeEditorValue] = useState(value)

  const classes = useStyles()

  useEffect(() => {
    changeEditorValue(value || "")
  }, [value, stepId])

  const editorConfiguration = {
    toolbar: {
      shouldNotGroupWhenFull: true,
      items: [
        'heading',
        '|',
        'alignment',
        '|',
        'bold',
        'italic',
        'strikethrough',
        'underline',
        '|',
        'link',
        '|',
        'bulletedList',
        'numberedList',
        '-',
        'fontFamily',
        'fontSize',
        'fontColor',
        'fontBackgroundColor',
        '|',
        'outdent',
        'indent',
        '|',
        // 'imageInsert',
        // 'mediaEmbed',
        // '|',
        'undo',
        'redo',
        '|',
        'removeFormat',
        'horizontalLine',
      ],
    },
    image: {
      styles: ['alignLeft', 'alignCenter', 'alignRight'],
      resizeOptions: [
        {
          name: 'resizeImage:original',
          label: 'Original',
          value: null,
        },
        {
          name: 'resizeImage:50',
          label: '50%',
          value: '50',
        },
        {
          name: 'resizeImage:75',
          label: '75%',
          value: '75',
        },
      ],
      toolbar: [
        'imageStyle:alignLeft',
        'imageStyle:alignCenter',
        'imageStyle:alignRight',
        '|',
        'resizeImage',
        '|',
        'imageTextAlternative',
      ],
    },
    table: {
      contentToolbar: [
        'tableColumn',
        'tableRow',
        'mergeTableCells',
        'tableCellProperties',
        'tableProperties',
      ],
    },
    licenseKey: '',
    fontSize: {
      options: [12, 14, 16, 18, 21, 24],
      supportAllValues: true,
    },
    uicolor: { height: '800px' },
    mediaEmbed: {
      removeProviders: ['instagram', 'twitter', 'googleMaps', 'flickr', 'facebook'],
    },
    mention: {
      feeds: [
          {
              marker: '{',
              feed: feedOptions,
          }
      ]
    }
  }

  return (
    <Box className={classes.wrapper}>
      <Typography variant='h6' className={classes.text}>
        Editor de texto para criar as informações textuais da etapa selecionada.
      </Typography>

      <CKEditor
        disabled={isLoading}
        data={editorValue}
        editor={Editor}
        config={editorConfiguration}
        onChange={(e, editor) => {
          const data = editor.getData()
          changeEditorValue(data)
        }}
        onReady={(editor) => {
          editor.editing.view.change((writer) => {
            writer.setStyle('min-height', '150px', editor.editing.view.document.getRoot())
          })
        }}
      />
      <Button
        variant='contained'
        color='primary'
        disabled={isLoading}
        onClick={() => onSave({ step_id: stepId, order: 1, type: typeText, title: typeText, component: typeText, name: typeText, required: false, id, text: editorValue })}
        className={classes.button}
      >
        {isLoading ? <CircularProgress size={25} /> : 'Salvar'}
      </Button>
    </Box>
  )
}

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '650px',
  },
  button: {
    marginTop: '10px',
    width: '50%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  text: {
    marginBottom: '10px'
  }
}))

Informations.propTypes = {}

Informations.defaultProps = {
  texts: null,
}

export default Informations
