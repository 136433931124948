const fonts = [
    {
        id: 'arial',
        label: 'Arial',
        value: 'Arial, sans-serif'
    },
    {
        id: 'verdana',
        label: 'Verdana',
        value: 'Verdana, sans-serif'
    },
    {
        id: 'roboto',
        label: 'Roboto',
        value: "Roboto, sans-serif"
    },
    {
        id: 'georgia',
        label: 'Georgia',
        value: 'Georgia, serif'
    },
    {
        id: 'times',
        label: 'Times New Roman',
        value: "'Times New Roman', serif",
    },

]

export { fonts }