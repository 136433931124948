import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import {
  Button,
  Divider,
  Accordion as MUIAccordion,
  AccordionDetails,
  AccordionActions,
} from '@material-ui/core'

import Summary from './summary/Summary'

const Accordion = ({ summaryProps, onSave, onDelete, children, isDragging, disabled, handleCollapse }) => {
  const [expanded, setExpanded] = useState(false)
  const classes = useStyles({ isDragging })

  React.useEffect(() => {
    setExpanded(false)
  }, [handleCollapse])

  return (
    <MUIAccordion
      className={classes.accordion}
      expanded={expanded}
      onChange={() => setExpanded(!expanded)}
    >
      <Summary {...summaryProps} />
      <Divider />

      {expanded && (
        <AccordionDetails className={classes.details}>
          <div className={classes.column}>{children}</div>
        </AccordionDetails>
      )}

      <Divider />
      <AccordionActions>
        <Button size='small' color='secondary' onClick={onDelete}>
          Excluir
        </Button>
        <Button size='small' color='primary' onClick={onSave} disabled={disabled}>
          Salvar
        </Button>
      </AccordionActions>
    </MUIAccordion>
  )
}

const useStyles = makeStyles(() => ({
  details: {
    alignItems: 'center',
    backgroundColor: '#eff2f7',
  },
  column: {
    width: '100%',
  },
  accordion: (props) => ({
    ...(props.isDragging && {
      background: 'rgb(235,235,235)',
    }),
  }),
}))

Accordion.propTypes = {
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool.isRequired,
  handleCollapse: PropTypes.bool.isRequired,
  isDragging: PropTypes.bool.isRequired,
  onDelete: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  summaryProps: PropTypes.shape({
    item: PropTypes.object.isRequired,
    onSave: PropTypes.func,
    isLoading: PropTypes.bool,
  }).isRequired,
}

Accordion.defaultProps = {}

export default Accordion
