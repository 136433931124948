import { StrictMode } from 'react'
import ReactDOM from 'react-dom'
import reportWebVitals from './reportWebVitals'
import AppProvider from './hooks'
import Root from './pages/Root'

import './index.css'

// if (process.env.NODE_ENV === 'development' && ) {
//   const { worker } = require('./mocks/browser')
//   worker.start()
// }

ReactDOM.render(
  <StrictMode>
    <AppProvider>
      <Root />
    </AppProvider>
  </StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
