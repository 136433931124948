import {useFormContext} from 'react-hook-form'
import {graduation} from "../../enum/physicalExamOptions";
import SimpleSelectField from "../SimpleSelectField";
import MultiOptionSelectField from "../MultiOptionSelectField";
import {Button, Grid} from "@material-ui/core";
import React from "react";

const Ouvido = ({id, segment, segmentName, removeItems}) => {

    const pathophysiology = [
        {label: 'Condutiva', value: 'condutiva'},
        {label: 'Neurossensorial', value: 'neurossensorial'},
        {label: 'Mista', value: 'mista'},
        {label: 'Central', value: 'central'}
    ];

    const {control} = useFormContext()

    return (
        <>
            <Button style={{width: '100%', fontWeight: '900', fontSize: 'medium', backgroundColor: 'red'}}
                    variant={'contained'}
                    size='large'
                    color='secondary'
                    onClick={() => removeItems(segmentName,segment)}
            >
                Excluir Exame
            </Button>
            <Grid container spacing={3} className="form-grid" justifyContent="center">
                <Grid item sm={10} md={5}>
                    <SimpleSelectField
                        segment={segment}
                        id={id}
                        name={'01-passiveListening'}
                        label={'Escuta Passiva'}
                        trueLabel={'Normal'}
                        falseLabel={'Alterada'}
                        control={control}/>
                </Grid>

                <Grid item sm={10} md={5}>
                    <SimpleSelectField
                        segment={segment}
                        id={id}
                        name={'02-earPavilion'}
                        label={'Pavilhão Auricular'}
                        trueLabel={'Normal'}
                        falseLabel={'Anormal'}
                        control={control}/>
                </Grid>

                <Grid item sm={10} md={5}>
                    <SimpleSelectField
                        segment={segment}
                        id={id}
                        name={'03-eardrum'}
                        label={'Tímpano'}
                        trueLabel={'Normal'}
                        falseLabel={'Anormal'}
                        control={control}/>
                </Grid>

                <Grid item sm={10} md={5}>
                    <SimpleSelectField
                        segment={segment}
                        id={id}
                        name={'04-cerumen'}
                        label={'Cerúmen'}
                        control={control}/>
                </Grid>

                <Grid item sm={10} md={5}>
                    <SimpleSelectField
                        segment={segment}
                        id={id}
                        name={'05-collections'}
                        label={'Coleções'}
                        control={control}/>
                </Grid>

                <Grid item sm={10} md={5}>
                    <SimpleSelectField
                        segment={segment}
                        id={id}
                        name={'06-strangeBody'}
                        label={'Corpo Estranho'}
                        control={control}/>
                </Grid>

                <Grid item sm={10} md={5}>
                    <SimpleSelectField
                        segment={segment}
                        id={id}
                        name={'07-tumors'}
                        label={'Tumorações'}
                        control={control}/>
                </Grid>

                <Grid item sm={10} md={5}>
                    <SimpleSelectField
                        segment={segment}
                        id={id}
                        name={'08-operativeScar'}
                        label={'Cicatriz operatória'}
                        trueLabel={'Existente'}
                        falseLabel={'Inexistente'}
                        control={control}/>
                </Grid>

                <Grid item sm={10} md={5}>
                    <SimpleSelectField
                        segment={segment}
                        id={id}
                        name={'09-sensitivity'}
                        label={'Sensibilidade'}
                        trueLabel={'Normal'}
                        falseLabel={'Anormal'}
                        control={control}/>
                </Grid>

                <Grid item sm={10} md={5}>
                    <SimpleSelectField
                        segment={segment}
                        id={id}
                        name={'10-coloring'}
                        label={'Coloração'}
                        trueLabel={'Normal'}
                        falseLabel={'Anormal'}
                        control={control}/>
                </Grid>

                <Grid item sm={10} md={5}>
                    <SimpleSelectField
                        segment={segment}
                        id={id}
                        name={'11-temperature'}
                        label={'Temperatura'}
                        trueLabel={'Normal'}
                        falseLabel={'Anormal'}
                        control={control}/>
                </Grid>

                <Grid item sm={10} md={5}>
                    <SimpleSelectField
                        segment={segment}
                        id={id}
                        name={'12-inflammatorySigns'}
                        label={'Sinais Inflamatórios'}
                        trueLabel={'Existente'}
                        falseLabel={'Inexistente'}
                        control={control}/>
                </Grid>

                <Grid item sm={10} md={5}>
                    <MultiOptionSelectField
                        segment={segment}
                        id={id}
                        name={'13-graduation'}
                        label={'Graduação'}
                        control={control}
                        options={graduation}/>
                </Grid>

                <Grid item sm={10} md={5}>
                    <MultiOptionSelectField
                        segment={segment}
                        id={id}
                        name={'14-pathophysiology'}
                        label={'Fisiopatologia'}
                        control={control}
                        options={pathophysiology}/>
                </Grid>
            </Grid>
        </>
    )
}

export default Ouvido
