import api from 'api'

export const devolutiveDoc = async (file) => {
  const formData = new FormData();
  formData.append('file', file);

  const { data } = await api.post(`/devolutives/read-doc`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  return data
}