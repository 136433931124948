import PropTypes from 'prop-types'
import { PreferencesProvider } from './preferences'

const PreferenceProvider = ({ children }) => {
  return (
    <PreferencesProvider>{children}</PreferencesProvider>
  )
}

PreferenceProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default PreferenceProvider
