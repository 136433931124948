import { format as formatFNS, isValid, parseISO } from 'date-fns'

const formatDate = (value, { format = 'dd/MM/yyyy hh:mm' } = {}) => {
  const date = value instanceof Date ? value : parseISO(value)

  if (!isValid(date)) {
    return ''
  }

  return formatFNS(date, format)
}

export default formatDate
