import {useFormContext} from 'react-hook-form'
import {graduation} from "../../enum/physicalExamOptions";
import SimpleSelectField from "../SimpleSelectField";
import MultiOptionSelectField from "../MultiOptionSelectField";
import React from "react";
import {Button, Grid} from "@material-ui/core";
import generateProgression from "../../functions/generateProgression";

const Cardiovascular = ({id, segmentName, removeItems}) => {
    const segment = 'cardiovascular'
    const {control} = useFormContext()


    const symptoms = [
        {label: 'Ausentes', value: 'ausentes'},
        {label: 'Em atividades cotidianas', value: 'em atividades cotidianas'},
        {label: 'Aos pequenos esforços', value: 'aos pequenos esforços'},
        {label: 'Em repouso', value: 'em repouso'},
    ];

    return (
        <>
            <Button style={{width: '100%', fontWeight: '900', fontSize: 'medium', backgroundColor: 'red'}}
                    variant={'contained'}
                    size='large'
                    color='secondary'
                    onClick={() => removeItems(segmentName,segment)}
            >
                Excluir Exame
            </Button>
            <Grid container spacing={1} className="form-grid" justifyContent="center">
                <Grid item sm={10} md={10}>
                    <SimpleSelectField
                        segment={segment}
                        id={id}
                        name={'01-vitalSigns'}
                        label={'Sinais Vitais'}
                        trueLabel={'Normais'}
                        falseLabel={'Alterados'}
                        control={control}/>
                </Grid>

                <Grid item sm={10} md={10}>
                    <SimpleSelectField
                        segment={segment}
                        id={id}
                        name={'02-palpationAuscultationOfPulses'}
                        label={'Palpação e ausculta dos pulsos'}
                        trueLabel={'Normais'}
                        falseLabel={'Alterados'}
                        control={control}/>
                </Grid>

                <Grid item sm={10} md={10}>
                    <SimpleSelectField
                        segment={segment}
                        id={id}
                        name={'03-chestInspectionPalpation'}
                        label={'Inspeção e palpação torácica'}
                        trueLabel={'Normais'}
                        falseLabel={'Alterados'}
                        control={control}/>
                </Grid>

                <Grid item sm={10} md={10}>
                    <SimpleSelectField
                        segment={segment}
                        id={id}
                        name={'04-cardiacPercussionPalpationAuscultation'}
                        label={'Percussão, palpação e ausculta cardíacas'}
                        trueLabel={'Normais'}
                        falseLabel={'Alterados'}
                        control={control}/>
                </Grid>

                <Grid item sm={10} md={10}>
                    <SimpleSelectField
                        segment={segment}
                        id={id}
                        name={'05-lungPercussionPalpationAuscultation'}
                        label={'Percussão, palpação e ausculta pulmonar'}
                        trueLabel={'Normais'}
                        falseLabel={'Alterados'}
                        control={control}/>
                </Grid>

                <Grid item sm={10} md={10}>
                    <SimpleSelectField
                        segment={segment}
                        id={id}
                        name={'06-arrhythmia'}
                        label={'Arritmia'}
                        trueLabel={'Ausente'}
                        falseLabel={'Presente'}
                        control={control}/>
                </Grid>

                <Grid item sm={10} md={10}>
                    <MultiOptionSelectField
                        segment={segment}
                        id={id}
                        name={'07-symptoms'}
                        label={'Sintomas'}
                        control={control}
                        options={symptoms}/>
                </Grid>

            </Grid>
        </>
    )
}

export default Cardiovascular
