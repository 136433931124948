import 'date-fns'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import DateFnsUtils from '@date-io/date-fns'
import { useFormContext, Controller } from 'react-hook-form'
import { MuiPickersUtilsProvider, KeyboardTimePicker } from '@material-ui/pickers'
import { makeStyles } from '@material-ui/core/styles'
import inputStyle from './input-style'

const InputTime = ({ defaultValue, name, variant, disableMargin, ...rest }) => {
  const {
    control,
    formState: { isSubmitting },
  } = useFormContext()
  const classes = useStyles()

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <div
        className={clsx({
          [classes.customTextField]: !disableMargin,
        })}
      >
        <Controller
          render={({ field }) => (
            <KeyboardTimePicker
              {...field}
              {...rest}
              disableToolbar
              fullWidth
              name={name}
              value={field.value || null}
              ampm={false}
              inputVariant={variant}
              InputLabelProps={ { style: { position: 'relative', marginBottom: '5px', transform: 'translate(0, 0) scale(1)' }, shrink: false }}
              KeyboardButtonProps={{ disabled: true, style: { display: 'none' } }}
              invalidDateMessage='Formato de hora inválido'
              mask='__:__'
            />
          )}
          name={name}
          control={control}
          defaultValue={defaultValue}
          disabled={isSubmitting}
        />
      </div>
    </MuiPickersUtilsProvider>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '10px 0px',
  },
  customTextField: inputStyle(theme)
}))

InputTime.propTypes = {
  defaultValue: PropTypes.string,
  disableMargin: PropTypes.bool,
  name: PropTypes.string.isRequired,
  variant: PropTypes.string,
}

InputTime.defaultProps = {
  defaultValue: null,
  disableMargin: false,
  variant: 'outlined',
}

export default InputTime
