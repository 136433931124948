import PropTypes from 'prop-types'
import { FieldsProvider } from './fields'
import { StepsProvider } from './steps'
import { TextsProvider } from './texts'
import { MetricsProvider } from './metrics'
import {FormsProvider} from './forms'

const FormProvider = ({ children }) => {
  return (
    <FormsProvider>
      <StepsProvider>
        <TextsProvider>
          <FieldsProvider>
            <MetricsProvider>{children}</MetricsProvider>
          </FieldsProvider>
        </TextsProvider>
      </StepsProvider>
    </FormsProvider>
  )
}

FormProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default FormProvider
