import { useMutation, useQueryClient } from 'react-query'
import { recoverUserPassword } from 'api/services/user'

export const useRecoverUserPassword = (options = {}) => {
  const queryClient = useQueryClient()
  const { onError, onSuccess = () => {} } = options

  return useMutation((data) => recoverUserPassword(data), {
    ...options,
    onSuccess: (data) => {
      queryClient.invalidateQueries('users')
      onSuccess(data)
    },
    onError: (data) => {
      onError(data.response.data.errors)
    }
  })
}
