import { createContext, useContext, useReducer, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { fetchMe, signIn } from 'api/services'

const ACTION_TYPES = {
  SIGN_IN: 'SIGN_IN',
  SIGN_OUT: 'SIGN_OUT',
}

const authReducer = (state, action) => {
  switch (action.type) {
    case ACTION_TYPES.SIGN_IN: {
      return {
        ...state,
        user: action.data,
      }
    }
    case ACTION_TYPES.SIGN_OUT: {
      return {
        user: {},
      }
    }
    default: {
      return state
    }
  }
}

const AuthStateContext = createContext()
const AuthDispatchContext = createContext()

const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, {
    user: {},
  })

  const { isLoading } = useQuery('me', fetchMe, {
    retry: false,
    useErrorBoundary: false,
    onSuccess: (data) => {
      dispatch({ type: ACTION_TYPES.SIGN_IN, data })
    },
  })

  return (
    <AuthStateContext.Provider value={{ isLoading, user: state.user }}>
      <AuthDispatchContext.Provider value={dispatch}>{children}</AuthDispatchContext.Provider>
    </AuthStateContext.Provider>
  )
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

const useAuth = () => {
  const context = useContext(AuthStateContext)

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider')
  }

  return context
}

const useDispatch = () => {
  const dispatch = useContext(AuthDispatchContext)
  if (dispatch === undefined) {
    throw new Error('useDispatch must be used within a AuthProvider')
  }
  return dispatch
}

const useSignIn = (options = {}) => {
  const dispatch = useDispatch()

  const { onSuccess } = options

  return useMutation(signIn, {
    ...options,
    onSuccess: async (data) => {
      await dispatch({ type: ACTION_TYPES.SIGN_IN, data })

      if (onSuccess) {
        onSuccess(data)
      }
    },
  })
}

const useSignOut = () => {
  const dispatch = useDispatch()
  const queryClient = useQueryClient()

  return useCallback(async () => {
    await dispatch({ type: ACTION_TYPES.SIGN_OUT })
    queryClient.resetQueries()
    window.location.pathname = '/login'
  }, [queryClient, dispatch])
}

export { AuthProvider, useAuth, useSignIn, useSignOut }
