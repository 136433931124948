import api from 'api'

export const fetchProjectToken = async (projectId) => {
  const { data } = await api.get(`/project/${projectId}/token`)

  return data
}

export const createProjectToken = async (projectId, payload) => {
  const { data } = await api.post(`/project/${projectId}/token`, payload)

  return data
}