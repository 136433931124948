import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'
import { FormProvider, useForm } from 'react-hook-form'
import { Tooltip, IconButton, Typography } from '@material-ui/core'
import { VpnKey as VpnKeyIcon } from '@material-ui/icons'
import { useProjectToken } from 'hooks/useProjectToken'
import { useCreateProjectToken } from 'hooks/useCreateProjectToken'
import { Dialog, InputField } from 'components'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

const schema = yup.object().shape({
    name: yup.string().required('Esse campo é obrigatório').min(3, 'O nome ter no mínimo 3 caracteres'),
    url: yup.string().required('Esse campo é obrigatório').url('Por favor, insira uma url válida'),
  })

const CreateToken = ({ projectId }) => {
    const [open, setOpenDialog] = useState(false)
    const [token, setToken] = useState('')

    useEffect(() => {
        loadToken
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
  
    const methods = useForm({
        mode: 'onChange',
        resolver: yupResolver(schema),
    })

    const {
        handleSubmit,
        formState: { errors, isValid },
    } = methods

    const { loadToken } = useProjectToken(projectId, {
        onSuccess: (data) => setToken(data.token),
        onError: () => console.log('Erro')
      })
  
    const { mutate, isLoading } = useCreateProjectToken(projectId, {
      onSuccess: (data) => setToken(data.token),
      onError: () => console.log('Erro')
    })

    return (
        <>
            <Tooltip title="Criar token">
                <IconButton color='primary' size='small' onClick={() => setOpenDialog(true)} edge='end' aria-label='token'>
                    <VpnKeyIcon />
                </IconButton>
            </Tooltip>
            <FormProvider {...methods}>
                <Dialog
                    title='Criar token do projeto'
                    contentText='Crie um token para poder associar os formuários do projeto em outra aplicação.'
                    open={open}
                    onCancel={() => setOpenDialog(false)}
                    isLoading={isLoading}
                    onSubmit={handleSubmit((data) => mutate(data))}
                    submitLabel="Gerar Token"
                    submitDisabled={!isValid || !isEmpty(token)}
                >
                    <InputField
                        name="name"
                        label="Nome"
                        variant='outlined'
                        error={errors?.name}
                        fullWidth
                    />
                    { errors?.name && <Typography color="error" variant="span">{errors.name.message}</Typography> }
                    <InputField
                        name="url"
                        label="URL"
                        variant='outlined'
                        error={errors?.url}
                        fullWidth
                    />
                    { errors?.url && <Typography color="error" variant="span">{errors.url.message}</Typography> }
                    { token !== "" &&
                    <InputField
                        name="token"
                        label="Token Gerado"
                        variant='outlined'
                        value={token}
                        disabled
                        fullWidth
                    />
                    }
                </Dialog>
            </FormProvider>
        </>
    )
}

CreateToken.propTypes = {
    projectId: PropTypes.string.isRequired
}

export default CreateToken
