import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

const TabContent = (props) => {
  const { children, value, index, ...other } = props

  const classes = useStyles()

  return (
    <div
      className={classes.content}
      role='tabpanel'
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  )
}

const useStyles = makeStyles(() => ({
  content: {
    padding: '20px',
  },
}))

TabContent.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.number.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}

TabContent.defaultProps = {}

export default TabContent
