const operators = [
  {
    label: 'maior que',
    value: '>',
  },
  {
    label: 'menor que',
    value: '<',
  },
  {
    label: 'igual a',
    value: '==',
  },
  {
    label: 'diferente de',
    value: '!=',
  },
]

const results = [
  {
    label: 'maior que',
    value: 'gth',
  },
  {
    label: 'menor que',
    value: 'lth',
  },
  {
    label: 'igual a',
    value: 'equal',
  },
  {
    label: 'entre',
    value: 'between',
  },
]

export { operators, results }
