import PropTypes from 'prop-types'
import {
  Button,
  Dialog as MUIDialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core'
import { Loader } from 'components'

const Dialog = ({
  onCancel,
  onSubmit,
  open,
  cancelLabel,
  submitLabel,
  children,
  contentText,
  title,
  submitDisabled,
  isLoading,
  noSubmit,
}) => {
  return (
    <MUIDialog open={open} fullWidth > 
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {isLoading ? (
          <Loader size={100} minHeight='150px' minWidth='550px' />
        ) : (
          <>
            <DialogContentText>{contentText}</DialogContentText>
            {children}
          </>
        )}
      </DialogContent>

      <DialogActions>
        <Button disabled={isLoading} onClick={onCancel} color='secondary'>
          {cancelLabel}
        </Button>
        {
          !noSubmit &&
          <Button
            type='submit'
            onClick={onSubmit}
            disabled={submitDisabled || isLoading}
            autoFocus
            color='primary'
          >
            {submitLabel}
          </Button>
        }
      </DialogActions>
    </MUIDialog>
  )
}

Dialog.propTypes = {
  cancelLabel: PropTypes.string,
  children: PropTypes.node,
  contentText: PropTypes.string,
  isLoading: PropTypes.bool,
  noSubmit: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  open: PropTypes.bool.isRequired,
  submitDisabled: PropTypes.bool,
  submitLabel: PropTypes.string,
  title: PropTypes.string,
}

Dialog.defaultProps = {
  cancelLabel: 'Cancelar',
  children: null,
  contentText: null,
  isLoading: false,
  noSubmit: false,
  onSubmit: null,
  submitDisabled: false,
  submitLabel: 'Enviar',
  title: null,
}

export default Dialog
