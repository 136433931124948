import { useMutation, useQueryClient } from 'react-query'
import { createForm } from 'api/services'

export const useCreateForm = (projectId, options = {}) => {
  const queryClient = useQueryClient()
  const { onSuccess = () => {} } = options

  return useMutation((data) => createForm(projectId, data), {
    ...options,
    onSuccess: (data) => {
      queryClient.invalidateQueries('forms')
      onSuccess(data)
    },
  })
}
