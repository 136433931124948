// import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

import logoHealthId from 'assets/logo.png'
import logoPlayground from 'assets/playground_text.png'

const useStyles = makeStyles(() => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '20px'
  },
  logoHealthIdClass: {
    maxWidth: '139px',
    maxHeight: '79px'
  },
  logoPlaygroundClass: {
    maxWidth: '250px',
  }
}))

const Header = () => {
  const classes = useStyles()

  return (
    <header className={classes.header}>
      <img src={logoHealthId} alt='health id' className={classes.logoHealthIdClass} />
      <img src={logoPlayground} alt='playground' className={classes.logoPlaygroundClass} />
    </header>
  )
}

Header.propTypes = {}

Header.defaultProps = {}

export default Header
