import { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Button } from '@material-ui/core'
import { Add as AddIcon } from '@material-ui/icons'
import { useCreateUser } from 'hooks/useCreateUser'
import { Dialog, InputField } from 'components'
import * as yup from 'yup'

const schema = yup.object().shape({
    name: yup.string().required(),
    email: yup.string().email().required(),
    password: yup.string().required(),
    password_confirmation: yup.string().test('passwords-match', 'Senhas devem coincidir', function(value) {
        return this.parent.password === value
    })
  })

const NewUser = () => {
  const [open, setOpenDialog] = useState(false)

  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: {
      name: '',
      email: '',
      password: '',
    },
    resolver: yupResolver(schema),
  })
  const {
    handleSubmit,
    formState: { isValid, isDirty },
  } = methods

  const { mutate, isLoading } = useCreateUser({
    onSuccess: () => setOpenDialog(false),
  })

  return (
    <>
      <Button
        onClick={() => setOpenDialog(true)}
        variant='contained'
        startIcon={<AddIcon />}
        color='primary'
      >
        Novo Usuário
      </Button>
      <FormProvider {...methods}>
        <Dialog
          title='Novo Usuário'
          contentText='Cadastre um novo usuário para acessar o sistema.'
          open={open}
          onCancel={() => setOpenDialog(false)}
          isLoading={isLoading}
          submitDisabled={!isValid || !isDirty}
          onSubmit={handleSubmit((data) => mutate(data))}
        >
          <InputField
            id='name'
            name='name'
            label='Nome'
            variant='outlined'
            required
            fullWidth
          />
          <InputField
            id='email'
            name='email'
            label='E-mail'
            variant='outlined'
            required
            fullWidth
          />
          <InputField
            id='password'
            name='password'
            label='Senha'
            variant='outlined'
            type="password"
            required
            fullWidth
          />
          <InputField
            id='password_confirmation'
            name='password_confirmation'
            label='Confirmar Senha'
            variant='outlined'
            type="password"
            required
            fullWidth
          />
        </Dialog>
      </FormProvider>
    </>
  )
}

NewUser.propTypes = {}

NewUser.defaultProps = {}

export default NewUser
