const customerFields = [
    {
      id: 'gender',
      value: 'gender',
      title: 'Sexo'
    },
    {
      id: 'height',
      value: 'height',
      title: 'Altura'
    },
    {
      id: 'weight',
      value: 'weight',
      title: 'Peso'
    },
    {
      id: 'imc',
      value: 'imc',
      title: 'IMC'
    },
    {
      id: 'age',
      value: 'age',
      title: 'Idade'
    },
]

const genderFields = [
    {
      id: 'male',
      value: 'male',
      title: 'Masculino'
    },
    {
      id: 'male',
      value: 'female',
      title: 'Feminino'
    },
]

const customerResponse = [
  {
    id: 'name',
    value: 'name',
    label: 'Nome'
  },
  {
    id: 'age',
    value: 'age',
    label: 'Idade'
  },
  {
    id: 'cpf',
    value: 'cpf',
    label: 'CPF'
  },
  {
    id: 'gender',
    value: 'gender',
    label: 'Sexo'
  },
]

export { customerFields, genderFields, customerResponse }