 /* eslint-disable */
import { useState, useEffect } from 'react'
import { get } from 'lodash'
import { useLocation } from 'react-router-dom'
import { Box, Button, CircularProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Editor } from 'ckeditor5-custom-build/build/ckeditor'
import { useFormContext } from 'react-hook-form'
import { CKEditor } from '@ckeditor/ckeditor5-react'

const Devolutive = ({ options, docContent = '' }) => {
  const text = 'aa'
  const stepId = 1
  const { setValue, watch } = useFormContext()
  const { location } = useLocation()
  const id = get(text, 'id', null)
  const value = get(text, 'default_value', null)
  // const { mutate: onSave, isLoading } = useUpdateText(id, stepId)
  // const { fields } = useFields()
  // const feed = useFeeds(fields)
  const editorValue = watch('template')
  const classes = useStyles()
  // useEffect(() => {
  //   changeEditorValue(value || "")
  // }, [value, location])

  useEffect(() => {
    if(docContent !== '') setValue('template', docContent);
  }, [docContent, setValue])

  const editorConfiguration = {
    autoformat: {
        rules: [
            { search: '!=', replace: text => console.log('hey', text) }
        ]
    },
    toolbar: {
      shouldNotGroupWhenFull: true,
      items: [
        'heading',
        '|',
        'alignment',
        '|',
        'bold',
        'italic',
        'strikethrough',
        'underline',
        '|',
        'link',
        '|',
        'bulletedList',
        'numberedList',
        '|',
        'fontFamily',
        'fontSize',
        'fontColor',
        'fontBackgroundColor',
        '|',
        'outdent',
        'indent',
        '|',
        'undo',
        'redo',
        '|',
        'removeFormat',
        'horizontalLine',
      ],
    },
    image: {
      styles: ['alignLeft', 'alignCenter', 'alignRight'],
      resizeOptions: [
        {
          name: 'resizeImage:original',
          label: 'Original',
          value: null,
        },
        {
          name: 'resizeImage:50',
          label: '50%',
          value: '50',
        },
        {
          name: 'resizeImage:75',
          label: '75%',
          value: '75',
        },
      ],
      toolbar: [
        'imageStyle:alignLeft',
        'imageStyle:alignCenter',
        'imageStyle:alignRight',
        '|',
        'resizeImage',
        '|',
        'imageTextAlternative',
      ],
    },
    table: {
      contentToolbar: [
        'tableColumn',
        'tableRow',
        'mergeTableCells',
        'tableCellProperties',
        'tableProperties',
      ],
    },
    licenseKey: '',
    fontSize: {
      options: [12, 14, 16, 18, 21, 24],
      supportAllValues: true,
    },
    uicolor: { height: '800px' },
    mediaEmbed: {
      removeProviders: ['instagram', 'twitter', 'googleMaps', 'flickr', 'facebook'],
    },
    mention: {
      feeds: [
          {
              marker: '{',
              feed: options
          }
      ]
    }
  }

  return (
    <Box className={classes.wrapper}>
      <CKEditor
        data={editorValue}
        editor={Editor}
        config={editorConfiguration}
        onChange={(e, editor) => {
          const data = editor.getData()
          // changeEditorValue(data)
          setValue('template', data)
        }}
        onReady={(editor) => {
          editor.editing.view.change((writer) => {
            writer.setStyle('min-height', '40vh', editor.editing.view.document.getRoot())
            writer.setStyle('resize', 'vertical', editor.editing.view.document.getRoot())
          })
        }}
      />
    </Box>
  )
}

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '615px',
  },
  button: {
    marginTop: '10px',
    width: '50%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}))

Devolutive.propTypes = {}

Devolutive.defaultProps = {
  texts: null,
}

export default Devolutive
