import { useMutation, useQueryClient } from 'react-query'
import { updateUser } from 'api/services/user'

export const useUpdateUser = (id, options = {}) => {
  const queryClient = useQueryClient()
  const { onSuccess = () => {} } = options

  return useMutation((data) => updateUser(id, data), {
    ...options,
    onSuccess: (data) => {
      queryClient.invalidateQueries('users')
      onSuccess(data)
    },
  })
}
