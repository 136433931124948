import api from 'api'

export const fetchPreferences = async (projectId) => {
  const data = await api.get(`/project/${projectId}/preferences`)

  return data
}

export const createPreferences = async (projectId, payload) => {
  const { data } = await api.post(`/project/${projectId}/preferences`, payload)

  return data
}

export const updatePreferences = async (projectId, payload) => {
  const { data } = await api.put(`/project/${projectId}/preferences`, payload)

  return data
}