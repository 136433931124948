import { useEffect, useMemo } from 'react'
import { Skeleton } from '@material-ui/lab'
import { get, flattenDeep } from 'lodash'

export const checkEmptyState = ({ isLoading, isFetchingNextPage, hasNextPage, rows }) =>
  ![isLoading, isFetchingNextPage, hasNextPage, rows.length > 0].some(Boolean)

export const defaultRowIdGetter = (row, prefix) => `${prefix}-${row?.original?.id ?? row?.id}`

export const useTableColumns = ({ columns, isLoading }) =>
  useMemo(
    () =>
      isLoading
        ? columns.map((column) => ({
            ...column,
            Cell: <Skeleton animation='wave' />,
          }))
        : columns,
    [isLoading, columns]
  )

export const useTableData = ({ data, isLoading }) => {
  const resolvedData = useMemo(
    () => flattenDeep(get(data, 'pages', []).map(({ data = [] }) => data)),
    [data]
  )

  return useMemo(() => (isLoading ? Array(5).fill({}) : resolvedData), [isLoading, resolvedData])
}

export const useFetchNextPage = ({ pageIndex, pageCount, hasNextPage, fetchNextPage }) => {
  const shouldFetchNextPage = useMemo(
    () => pageIndex === pageCount && hasNextPage,
    [hasNextPage, pageCount, pageIndex]
  )

  useEffect(() => {
    if (shouldFetchNextPage) {
      fetchNextPage({ page: pageIndex })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchNextPage, shouldFetchNextPage])
}

export const useUpdateQueryParams = ({ setFetchInfo, pageSize,  }) =>
  useEffect(() => {
    setFetchInfo(fetchInfo => ({ ...fetchInfo, pageSize }))
  }, [pageSize, setFetchInfo])

  export const useResetPagination = ({ gotoPage, fetchInfo }) =>
  useEffect(() => {
    gotoPage(0)
  }, [gotoPage, fetchInfo])


export const useGetCount = (data) => {
  const total = get(data, ['pages', 0, 'total'], 0);
  return useMemo(() => total, [total])
}