import { Router, Route, Switch, Redirect } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import { PrivateRoute } from 'components'
import FormProvider from 'hooks/formProvider'
import MetricProvider from 'hooks/metricProvider'
import ResultProvider from 'hooks/resultProvider'
import DevolutiveProvider from 'hooks/devolutiveProvider'
import PreferenceProvider from 'hooks/preferenceProvider'
import FormDisplayProvider from 'hooks/formDisplayProvider'
import CustomerAttributesProvider from 'hooks/customerAttributesProvider'
import Login from './Login'
import Playground from './Playground'
import Projects from './projects/Projects'
import Menu from './menu/Menu'
import Metrics from './menu/metrics/Metrics'
import Results from './menu/results/Results'
import Devolutives from './menu/devolutives/Devolutives'
import CustomerAttributes from './menu/customer-attributes/CustomerAttributes'
import Preferences from './menu/preferences/Preferences'
import Forms from './menu/forms/Forms'
import Settings from './settings/Settings'
import Users from './users/Users'
import NewPassword from './users/new-password/NewPassword'
import FormDisplay from './forms/FormDisplay'

const history = createBrowserHistory()

const Root = () => (
  <Router history={history}>
    <Switch>
      <Route exact path='/login' component={Login} />
      <Route exact path='/recuperar-senha/:email/:token' component={NewPassword} />
      <Route exact path='/'>
        <Redirect to='/project' />
      </Route>
      <Route exact path='/form'>
        <FormDisplayProvider>
          <FormDisplay />
        </FormDisplayProvider>
      </Route>
      <PrivateRoute exact path='/project' component={Projects} />
      <PrivateRoute exact path='/project/:projectId/form' wrapper={Menu} component={Forms} />
      <PrivateRoute exact path='/project/:projectId/metrics' wrapper={Menu} provider={MetricProvider} component={Metrics} />
      <PrivateRoute exact path='/project/:projectId/results' wrapper={Menu} provider={ResultProvider} component={Results} />
      <PrivateRoute exact path='/project/:projectId/devolutives' wrapper={Menu} provider={DevolutiveProvider} component={Devolutives} />
      <PrivateRoute exact path='/project/:projectId/customer-attributes' wrapper={Menu} provider={CustomerAttributesProvider} component={CustomerAttributes} />
      <PrivateRoute exact path='/project/:projectId/preferences' wrapper={Menu} provider={PreferenceProvider} component={Preferences} />
      <PrivateRoute exact path='/settings' component={Settings} />
      <PrivateRoute exact path='/users' component={Users} />
      <PrivateRoute
        exact
        path='/project/:projectId/form/:formId'
        provider={FormProvider}
        component={Playground}
      />
      <Route path='*'>
        <Redirect to='/project' />
      </Route>
    </Switch>
  </Router>
)

Root.propTypes = {}

Root.defaultProps = {}

export default Root
