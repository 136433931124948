const inputStyle = (theme) => ({
	margin: '10px 0px',
	'& .MuiInputBase-root': {
	backgroundColor: theme.mode == 'light'? 'transparent' : '#FFF',
	borderColor: theme.mode == 'light'? 'transparent' : '#1A202C',
	fontColor: '#000',
	textColor: '#000',
	color: '#000'
	},
	'& .MuiInputBase-root:hover': {
	'& fieldset': {
		borderColor: theme.mode == 'light'? 'black' : '#718096',
	},
	},
})

export default inputStyle