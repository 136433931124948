import { useState } from 'react'
import { useInfiniteQuery, useQueryClient } from 'react-query'
import { fetchProjects } from 'api/services'
import { DEFAULT_PARAMS } from 'enum/infiniteQuery'

export const useProjects = (initialFetchInfo = {}) => {
  const queryClient = useQueryClient()
  const [fetchInfo, setFetchInfo] = useState({
    ...DEFAULT_PARAMS,
    ...initialFetchInfo,
  })

  return {
    ...useInfiniteQuery(
      ['projects', fetchInfo],
      ({ pageParam }) => fetchProjects(fetchInfo, pageParam),
      {
        onSuccess: () => {
          queryClient.invalidateQueries('forms')
        },
        getNextPageParam: ({current_page, last_page}) => {
          if (current_page < last_page) {
            return { page: current_page + 1 }
          }

          return false
        }
      }
    ),
    fetchInfo,
    setFetchInfo,
  }
}
