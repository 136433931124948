import { useState } from 'react'
import PropTypes from 'prop-types'
import { Tooltip, IconButton } from '@material-ui/core'
import { Delete as DeleteIcon } from '@material-ui/icons'
import { useRemoveProject } from 'hooks/useRemoveProject'
import { Dialog, } from 'components'

const RemoveProject = ({ id, title }) => {
  const [open, setOpenDialog] = useState(false)

  const { mutate: remove, isLoading } = useRemoveProject({
    onSuccess: () => setOpenDialog(false),
  })

  return (
    <>
      <Tooltip title="Excluir projeto">
        <IconButton size='small' onClick={() => setOpenDialog(true)} edge='end' aria-label='delete'>
          <DeleteIcon />
        </IconButton>
      </Tooltip>
      <Dialog
        title='Excluir Projeto'
        contentText={`Você tem certeza que deseja excluir o projeto "${title}"?`}
        open={open}
        onCancel={() => setOpenDialog(false)}
        isLoading={isLoading}
        onSubmit={() => remove(id)}
        submitLabel="Excluir"
      />
    </>
  )
}

RemoveProject.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

RemoveProject.defaultProps = {}

export default RemoveProject
