import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { useFormContext, Controller } from 'react-hook-form'
import {
	Select,
	FormControl,
	FormLabel,
	OutlinedInput,
	MenuItem,
	Box,
	Chip,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import inputStyle from './input-style'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
}

const MultipleSelect = ({ label, name, defaultValue = [], disableMargin, options }) => {
	const {
		control,
		getValues,
		setValue,
		watch,
	} = useFormContext()
	const classes = useStyles()

	const [mValue, setMValue] = React.useState(
		defaultValue && defaultValue !== null ?
			typeof defaultValue == 'object' ?
				defaultValue :
				[defaultValue]
			: [])

	console.log('multi default', defaultValue, mValue)

	React.useEffect(() => {
		setValue(name, mValue)
	}, [setValue, name, mValue])

	const onChange = (event) => {
		const {
			target: { value },
		} = event
		setMValue(value)
	}

	const getMultiValue = (name) => {
		if (!getValues(name)) return []

		if (getValues(name).length === 0) return []

		return getValues(name)
	}

	const optionName = (value) => {
		const option = options.find((option) => option.value == value)
		if (option) return option.label

		return null
	}

	return (
		<FormControl
			component='fieldset'
			className={clsx({
				[classes.customTextField]: !disableMargin,
			})}
		>
			<FormLabel component='legend'>{label}</FormLabel>
			<Controller
				render={({ field }) => (
					<Select
						labelId='demo-multiple-chip-label'
						id='demo-multiple-chip'
						multiple
						value={mValue}
						onChange={onChange}
						input={<OutlinedInput id='select-multiple-chip' label='Chip' />}
						renderValue={(selected) => (
							<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
								{selected?.map((value) => (
									<Chip key={value} label={optionName(value)} />
								))}
							</Box>
						)}
						MenuProps={MenuProps}
					>
						{options.map((option) => (
							<MenuItem
								key={option.id}
								value={option.value}
							// style={getStyles(name, personName, theme)}
							>
								{option.label}
							</MenuItem>
						))}
					</Select>
				)}
				control={control}
			/>
		</FormControl>
	)
}

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		margin: '10px 0px',
	},
	customTextField: inputStyle(theme),
	option: {
		marginBottom: '20px',
	},
	checkbox: {
		alignSelf: 'baseline',
	},
}))

MultipleSelect.propTypes = {
	defaultValue: PropTypes.array,
	disableMargin: PropTypes.bool,
	id: PropTypes.string,
	label: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	options: PropTypes.array.isRequired,
}

MultipleSelect.defaultProps = {
	defaultValue: [],
	disableMargin: false,
	id: '',
}

export default MultipleSelect
