import { useState } from 'react'
import { IconButton, Menu, MenuItem, ListItemIcon } from '@material-ui/core'
import {
  AccountCircle as AccountCircleIcon,
  ExitToApp as LogoutIcon,
  Settings as SettingsIcon,
  Person as UsersIcon,
  Assignment as ProjectIcon,
} from '@material-ui/icons'
import { Link as RouterLink } from 'react-router-dom'
import { useAuth } from 'hooks/auth'

const Avatar = () => {
  const { user } = useAuth()
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div>
      {user?.name}
      <IconButton
        aria-label='account of current user'
        aria-controls='menu-appbar'
        aria-haspopup='true'
        onClick={handleMenu}
        color='inherit'
      >
        <AccountCircleIcon />
      </IconButton>
      <Menu
        id='menu-appbar'
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        onClose={handleClose}
      >
        <MenuItem component={RouterLink} to={'/project'}>
          <ListItemIcon>
            <ProjectIcon fontSize='small' />
          </ListItemIcon>
          Projetos
        </MenuItem>
        <MenuItem component={RouterLink} to={'/settings'}>
          <ListItemIcon>
            <SettingsIcon fontSize='small' />
          </ListItemIcon>
          Configurações
        </MenuItem>
        <MenuItem component={RouterLink} to={'/users'}>
          <ListItemIcon>
            <UsersIcon fontSize='small' />
          </ListItemIcon>
          Usuários
        </MenuItem>
        <MenuItem component={RouterLink} to={'/login'}>
          <ListItemIcon>
            <LogoutIcon fontSize='small' />
          </ListItemIcon>
          Sair
        </MenuItem>
      </Menu>
    </div>
  )
}

export default Avatar
