import React from 'react'
import PropTypes from 'prop-types'
import { useForm, FormProvider } from 'react-hook-form'
import { isEmpty } from 'lodash'
import { useSteps } from 'hooks/steps'
import { useUpdateField, useRemoveField, useUpdateTitleField } from 'hooks/fields'
import { Accordion } from 'components'
import Section from './section/Section'

import ErrorDialog from 'components/error-dialog/ErrorDialog'

import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

const schema = yup.object().shape({
  tag: yup
    .string()
    .required('Esse campo é obrigatório')
    .min(2, 'A tag deve ter no mínimo 2 caracteres')
    .max(255, 'A tag deve ter no máximo 255 caracteres')
    .matches(
      /^[^0-9][\w&._]+$/,
      'A tag não pode começar com números e não pode possuir caracteres especiais ou espaços.'
    ),
  label: yup
    .string()
    .required('Esse campo é obrigatório')
    .max(255, 'O título deve ter no máximo 255 caracteres'),
  default_value: yup
    .string()
    .min(2, 'O valor default deve ter no mínimo 2 caracteres')
    .nullable(true)
    .transform(function (value, originalValue) {
      if (!value) {
        return null
      }
      return originalValue
    }),
  options: yup.array().of(
    yup.object().shape({
      label: yup
        .string()
        .required('A opção deve ter um valor')
        .min(2, 'A opção deve ter no mínimo 2 caracteres')
        .max(255, 'A opção deve ter no máximo 255 caracteres'),
    })
  ),
})

const RegisteredItem = ({ project, item, isDragging }) => {
  const { selected: stepId } = useSteps()
  const methods = useForm({
    mode: 'onTouched',
    defaultValues: item,
    resolver: yupResolver(schema),
  })
  const {
    handleSubmit,
    formState: { isValid, errors },
  } = methods

  const [handleCollapse, setHandleCollapse] = React.useState(false)

  const [errorMessage, setErrorMessage] = React.useState([])
  const [dialogOpen, setDialogOpen] = React.useState(false)

  const { id, title, tag } = item
  const { mutateAsync: onSubmit, isLoading: isLoadingUpdate } = useUpdateField(id, stepId, {
    onSuccess: () => setHandleCollapse(!handleCollapse),
    onError: (error) => {
      setErrorMessage(error), setDialogOpen(true)
    },
  })
  const { mutate: onChangeTitle, isLoading: isLoadingTitle } = useUpdateTitleField(id, stepId)
  const { mutate: onRemove, isLoading: isLoadingRemove } = useRemoveField(id, stepId)

  const orderOptions = (options) => {
    options.forEach((option, x) => {
      option.order = x
    })
    return options
  }

  const isDeleted = (data, value, prop) => {
    const elements = []
    const newData = data.filter((elem) => !value.some((newItem) => newItem[prop] === elem[prop]))

    value.forEach((elem) => {
      const newValue = data.find((newItem) => newItem[prop] === elem[prop])

      if (isEmpty(newValue)) {
        elements.push({ ...elem, deleted_at: new Date() })
      } else {
        elements.push({ ...elem, ...newValue })
      }
    })

    newData.forEach((el) => elements.push(el))

    return elements
  }

  return (
    <Accordion
      key={id}
      id={id}
      tag={tag}
      title={title}
      handleCollapse={handleCollapse}
      onSave={handleSubmit((data) => {
        const { options, display_rules } = item
        onSubmit({
          ...data,
          component: data.type,
          options: orderOptions(isDeleted(data.options, options, 'value')),
          display_rules: isDeleted(data.display_rules, display_rules, 'value_of_id'),
        })
      })}
      disabled={!isValid}
      onDelete={onRemove}
      isDragging={isDragging}
      summaryProps={{
        id,
        item,
        onSave: onChangeTitle,
        isLoading: isLoadingTitle,
      }}
    >
      <FormProvider {...methods}>
        <Section
          project={project}
          data={item}
          isSubmitting={isLoadingUpdate || isLoadingRemove}
          errors={errors}
        />
      </FormProvider>
      <ErrorDialog isOpen={dialogOpen} setOpen={setDialogOpen} errorMessage={errorMessage} />
    </Accordion>
  )
}

RegisteredItem.propTypes = {
  isDragging: PropTypes.bool.isRequired,
  item: PropTypes.object.isRequired,
  project: PropTypes.object.isRequired,
}

RegisteredItem.defaultProps = {}

export default RegisteredItem
