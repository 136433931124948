import { useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Paper } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Loader, BottomNavigation } from 'components'
import { FormField, DisplayRules, Texts } from './'

const getComponent = (type, data, errors, project) => {
  switch (type) {
    case 'fields':
      return <FormField project={project} data={data} errors={errors} />
    case 'display-rule':
      return <DisplayRules project={project} data={data} />
    case 'texts':
      return <Texts data={data} />
    default:
      return <FormField data={data} errors={errors} />
  }
}

const Section = ({ project, data, isSubmitting, errors }) => {
  const classes = useStyles()
  const [value, setValue] = useState('field')

  const component = useMemo(() => {
    return isSubmitting ? <Loader size={80} /> : getComponent(value, data, errors, project)
  }, [value, isSubmitting, data, errors, project])

  return (
    <Paper className={classes.paper}>
      <div className={classes.content}>{component}</div>
      <BottomNavigation
        disabled={isSubmitting}
        value={value}
        onChange={(e, newValue) => setValue(newValue)}
      />
    </Paper>
  )
}

const useStyles = makeStyles(() => ({
  paper: {
    padding: '20px',
    paddingBottom: '5px',
  },
  content: {
    '& > :first-child': {
      minHeight: '255px',
      marginBottom: '20px',
    },
  },
}))

Section.propTypes = {
  data: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  isSubmitting: PropTypes.bool,
  project: PropTypes.object.isRequired,
}

Section.defaultProps = {
  isSubmitting: false,
}

export default Section
