import Link from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  footer: {
    paddingTop: '20px',
  },
  link: {
    whiteSpace: 'nowrap',
  },
}))

const Footer = () => {
  const classes = useStyles()
  return (
    <Typography className={classes.footer} variant='body2' color='textSecondary' align='center'>
      {'©2021 - Clic Health ID - Direitos reservados - '}
      <Link
        color='inherit'
        className={classes.link}
        href='https://www.clichealthid.com/politica-de-privacidade'
      >
        Política de Privacidade
      </Link>
    </Typography>
  )
}

export default Footer
