
import { Dialog } from 'components'
import DisplayRules from 'components/display-rules/DisplayRules';
import { useFetchProject } from 'hooks/useProject';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';


const StepDialogDisplayRules = ({ open = false, onSubmit, showConditions, onClose }) => {
    const methods = useForm({
        defaultValues: {
            display_rules: showConditions
        }
    })
    const { projectId } = useParams();
    const { data, isLoading: isLoadingProject } = useFetchProject(projectId, { all: true })
    
    if (isLoadingProject) return "carregando...";

    const { customer, forms } = data
   
    return (
        <Dialog
            open={open}
            onCancel={onClose}
            onSubmit={
                methods.handleSubmit(onSubmit)
              }
        >
            <FormProvider {...methods} >
                <DisplayRules
                    forms={forms}
                    customer={customer}
                    formHooks={{
                        enable: true,
                        basePath: ""
                    }}
                />
            </FormProvider>
        </Dialog>
    );
}


export default StepDialogDisplayRules;