import styled, { css } from "styled-components";

export const StyledForm = styled.div`
    ${props => {
        const border_radius = props.preferences.border_radius ? `${props.preferences.border_radius}px` : '5px';
        const text_align = props.preferences.text_alignment ? props.preferences.text_alignment : 'left';

        let display = `
        form {
          display: flex;
          flex-direction: column;
        }
      `

        if (props.preferences.columns) {
            let columns = '';

            for (let i = 0; i < props.preferences.columns; i++) {
                columns += '1fr '
            }

            display = `
        form {
          display: grid;
          gap: .5rem;
          grid-template-columns: ${columns};
          min-width: ${props.preferences.columns * 100}px !important;
        }
      `
        }

        return css`
      ${display}

      div {
        border-radius: ${border_radius};
      }
      p {
        text-align: ${text_align};
      }
    `
    }
}`;