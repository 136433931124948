import { Avatar, Button, Box, Typography, Container, FormHelperText } from '@material-ui/core'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import { makeStyles } from '@material-ui/core/styles'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useLocation, useHistory } from 'react-router-dom'
import { get } from 'lodash'
import { useSignIn } from 'hooks/auth'
import { Footer, InputField } from 'components'
import * as yup from 'yup'
import RecoverPassword from './RecoverPassword'

const schema = yup.object().shape({
  password: yup.string().required(null),
  email: yup.string().email("Por favor, insira um email válido").required(null),
})
export default function Login() {
  const classes = useStyles()
  const location = useLocation()
  const history = useHistory()

  const methods = useForm({
    mode: 'onTouched',
    resolver: yupResolver(schema),
  })
  const {
    setError,
    handleSubmit,
    formState: { errors, isValid, isSubmitting },
  } = methods

  const { mutateAsync: onSubmit } = useSignIn({
    onSuccess: () => history.push(get(location, ['state', 'from', 'pathname'], '/')),
    onError: () => setError("login",{ message: "Credenciais inválidas, verifique o email ou a senha" })
  })

  return (
    <Container component='main' maxWidth='xs'>
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component='h1' variant='h5'>
          Form Builder
        </Typography>
        <FormProvider {...methods}>
          <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
            <InputField
              fullWidth
              margin='normal'
              id='email'
              label='Email'
              name='email'
              autoComplete='email'
              error={errors?.email}
              autoFocus
            />
            {errors?.email ? <Typography color="error" variant="span">{errors.email.message}</Typography> : null}
            <InputField
              fullWidth
              margin='normal'
              name='password'
              label='Senha'
              type='password'
              id='password'
              autoComplete='current-password'
            />
             {errors?.login ? (<Typography color="error" variant="span">{errors.login.message}</Typography>): null }
            <Button
              type='submit'
              fullWidth
              variant='contained'
              color='primary'
              className={classes.submit}
              disabled={!isValid || isSubmitting}
            >
              Entrar
            </Button>
          </form>
        </FormProvider>
        <RecoverPassword />
      </div>
      <Box mt={8}>
        <Footer />
      </Box>
    </Container>
  )
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))
