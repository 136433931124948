import { createContext, useContext, useReducer } from 'react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import { useQuery, useMutation } from 'react-query'
import { createMetric, fetchMetrics, updateMetric, removeMetric } from 'api/services'
import { createResult, updateResult } from 'api/services/results'

const ACTION_TYPES = {
  LIST: 'LIST_METRIC',
  ADD: 'ADD_METRIC',
  REMOVE: 'REMOVE_METRIC',
  UPDATE: 'UPDATE_METRIC',
}

const metricsReducer = (state, action) => {
  switch (action.type) {
    case ACTION_TYPES.LIST: {
      return { metrics: action.data }
    }
    case ACTION_TYPES.ADD: {
      return { metrics: [...state.metrics, action.data] }
    }
    case ACTION_TYPES.REMOVE: {
      return { metrics: state.metrics.filter(({ id }) => id !== action.id) }
    }
    case ACTION_TYPES.UPDATE: {
      return {
        metrics: state.metrics.map((item) =>
          item.id === action.data.id ? { ...item, ...action.data } : item
        ),
      }
    }
    case ACTION_TYPES.SELECT: {
      return state
    }
    default: {
      return state
    }
  }
}

const MetricsStateContext = createContext()
const MetricsDispatchContext = createContext()

const MetricsProvider = ({ children }) => {
  const { projectId } = useParams()
  const [state, dispatch] = useReducer(metricsReducer, { metrics: [] })

  const { isFetching } = useQuery(['metrics', projectId], () => fetchMetrics(projectId), {
    onSuccess: (data) => {
      dispatch({ type: ACTION_TYPES.LIST, data: data.data })
    },
  })

  return (
    <MetricsStateContext.Provider value={{ isLoading: isFetching, metrics: state.metrics }}>
      <MetricsDispatchContext.Provider value={dispatch}>{children}</MetricsDispatchContext.Provider>
    </MetricsStateContext.Provider>
  )
}

MetricsProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

const useMetrics = (projectId) => {
  const context = useContext(MetricsStateContext)

  if (!context) {
    throw new Error('useMetrics must be used within an MetricsProvider')
  }

  if (projectId) {
    const { metrics } = context
    return {
      ...context,
      metrics: metrics.filter((item) => item.project_id === projectId),
    }
  }

  return context
}

const useDispatch = () => {
  const dispatch = useContext(MetricsDispatchContext)
  if (dispatch === undefined) {
    throw new Error('useDispatch must be used within a MetricsProvider')
  }
  return dispatch
}

const useUpdateMetric = (id, options = {}) => {
  const dispatch = useDispatch()
  
  return useMutation((data) => updateMetric(id, data), {
    ...options,
    onSuccess: (data) => {
      dispatch({ type: ACTION_TYPES.UPDATE, data })
    },
  })
}

const useRemoveMetric = (id, options = {}) => {
  const dispatch = useDispatch()

  return useMutation((id) => removeMetric(id), {
    ...options,
    onSuccess: () => dispatch({ type: ACTION_TYPES.REMOVE, id }),
  })
}

const useUpdateTitleMetric = (id, options = {}) => {
  const dispatch = useDispatch()

  return useMutation(data => updateMetric(id, data), {
    ...options,
    onSuccess: (data) => {
      dispatch({ type: ACTION_TYPES.UPDATE, data })
    },
  })
}

const useCreateMetric = (projectId, options = {}) => {
  const dispatch = useDispatch()
  const { onSuccess } = options

  return useMutation((data) => createMetric(projectId, data), {
    ...options,
    onSuccess: (data) => {
      onSuccess(data)
      dispatch({ type: ACTION_TYPES.ADD, data })
    },
  })
}

const useCreateResult = (projectId, options = {}) => {
  const dispatch = useDispatch()
  const { onSuccess } = options

  return useMutation((data) => createResult(projectId, data), {
    ...options,
    onSuccess: (data) => {
      onSuccess(data)
      dispatch({ type: ACTION_TYPES.ADD, data })
    },
  })
}

const useUpdateResult = (id, options = {}) => {
  const dispatch = useDispatch()
  
  return useMutation((data) => updateResult(id, data), {
    ...options,
    onSuccess: (data) => {
      dispatch({ type: ACTION_TYPES.UPDATE, data })
    },
  })
}

export {
  MetricsProvider,
  useMetrics,
  useUpdateMetric,
  useRemoveMetric,
  useUpdateTitleMetric,
  useCreateMetric,
  useCreateResult,
  useUpdateResult
}
