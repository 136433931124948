import PropTypes from 'prop-types'
import { useUpdateStep, useRemoveStep } from 'hooks/steps'
import { EditableField } from 'components'
import StepDialogDisplayRules from 'pages/appBar/step-dialog-display-rules/StepDialogDisplayRules'
import { useState } from 'react'

const StepDrawerItem = ({ id, title, display_rules }) => {
  const [ switchDialogDisplay, setSwitchDialogDisplay ] = useState(false)
  const { mutate: onSave, isLoading: isLoadingUpdate } = useUpdateStep(id, {
    onSuccess: () => {
      setSwitchDialogDisplay(false)
    }
  })
  const { mutate: onRemove, isLoading: isLoadingRemove } = useRemoveStep(id)


  return (
    <>
    <EditableField
      id={id}
      defaultValue={title}
      onRemove={onRemove}
      onVisiblityRules={() => setSwitchDialogDisplay(!switchDialogDisplay)}
      onSave={({ id, value }, options) => onSave({ id, title: value, display_rules }, options)}
      isLoading={isLoadingUpdate || isLoadingRemove}
      fullWidth
    />
    <StepDialogDisplayRules
      open={switchDialogDisplay}
      onClose={() => setSwitchDialogDisplay(!switchDialogDisplay)}
      showConditions={display_rules}
      onSubmit={(display_rules) => onSave({ id, title , ...display_rules })}
    />
    </>
  )
}

StepDrawerItem.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

StepDrawerItem.defaultProps = {}

export default StepDrawerItem
