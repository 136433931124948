import { Typography } from '@material-ui/core'
import { differenceInYears } from 'date-fns'
import React from 'react'

const LabelField = ({ value, customer }) => {
    const replaceFeed = (value) => {
        if (!value) return null;
        const reg = /({{( ?)+\w+.\w+( ?)+}})+/gm

        let replaceValues = value.match(reg)
        
        if (!replaceValues) return value;

        replaceValues = replaceValues.map((val, x) => {
            return { 
                name: eval(val.replaceAll('{', '').replaceAll('}', '')),
                index: val
            }
        })

        replaceValues.forEach(val => {
            value = value.replaceAll(val.index, val?.name || 'não identificado')
        })

        return value
    }

    return (
        <Typography>
            <div dangerouslySetInnerHTML={{ __html: replaceFeed(value) }} />
        </Typography>
    )
}

export default LabelField