import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles';
import { ButtonBase, Typography } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility'


const ImageButton = ({ name, image }) => {
  const classes = useStyles();
  return (
        <ButtonBase
          focusRipple
          key={name}
          className={classes.image}
          focusVisibleClassName={classes.focusVisible}
        >
          <canvas
            width={150}
            height={150}
            className={classes.imageSrc}
            style={{
              backgroundImage: `url(${image})`,
            }}
          />
          <span className={classes.imageBackdrop} />
          <span className={classes.imageButton}>
            <Typography
              component="span"
              variant="subtitle1"
              color="inherit"
              className={classes.imageTitle}
            >
                <VisibilityIcon className={classes.imageMarked}/>
            </Typography>
          </span>
        </ButtonBase>
  );
}

const useStyles = makeStyles((theme) => ({
    image: {
      position: 'relative',
      height: 150,
      [theme.breakpoints.down('xs')]: {
        width: '100% !important', // Overrides inline-style
        height: 100,
      },
      '& $imageMarked': {
        opacity: 0,
      },
      '&:hover, &$focusVisible': {
        zIndex: 1,
        '& $imageBackdrop': {
          opacity: 0.15,
        },
        '& $imageMarked': {
            opacity: 100,
          },
        '& $imageTitle': {
          border: '4px solid currentColor',
        },
      },
    },
    focusVisible: {},
    imageButton: {
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: theme.palette.common.white,
    },
    imageSrc: {
      backgroundSize: 'cover',
      backgroundPosition: 'center 15%',
    },
    imageBackdrop: {
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      backgroundColor: theme.palette.common.black,
      opacity: 0.4,
      transition: theme.transitions.create('opacity'),
    },
    imageTitle: {
      position: 'relative',
      padding: `${theme.spacing(2)}px ${theme.spacing(4)}px ${theme.spacing(1) + 6}px`,
    },
    imageMarked: {
      transition: theme.transitions.create('opacity'),
    },
  }));

  ImageButton.propTypes = {
    image: PropTypes.string.isRequired,
    name: PropTypes.string,

  }
  
  ImageButton.defaultProps = {
    name: null
  }
  

export default ImageButton