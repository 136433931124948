import { useQuery } from 'react-query'
import { fetchProject } from 'api/services/fetchProject'

export const useFetchProject = (id, options = {}) => {
  const { onSuccess = () => {}, all } = options

  return useQuery('project', () => fetchProject(id, all), {
    ...options,
    onSuccess: (data) => {
      onSuccess(data)
    },
  })
}
