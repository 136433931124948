import React, {useEffect, useState} from 'react'
import { makeStyles, createTheme, ThemeProvider } from '@material-ui/core/styles'
import { useParams } from 'react-router-dom'
import {Typography, Paper, Box, Button, Switch, InputLabel} from '@material-ui/core'
import { usePreferences } from 'hooks/preferences'
import { Loader } from 'components'
import {Label} from "@material-ui/icons";

const ThemePreview = () => {

  const { projectId } = useParams();
  const { isFetching: isLoading, preferences } = usePreferences(projectId)
  const classes = useStyles()

  //states
  const [darkModePreview, setDarkModePreview] = useState( false);
  const themes = {
    light: {
      mode:'light',
      palette: {
        secondary: {
          main: preferences.color_theme || '#303f9f',
        },
      },
      // typography: {
      //   h6:{
      //     color: '#000'
      //   }
      // }
    },

    dark: {
      palette: {
        mode:'dark',
        secondary: {
          main: preferences.color_theme_dark || '#1e1e1f', //default dark
          contrastText: '#ffffff'
        },
        background: {
          default: preferences.color_theme_dark,
          box: preferences.color_theme_dark
        }
      },
      // typography: {
      //   h6:{
      //     color: '#ffffff'
      //   }
      // },
    }
  }
  const customTheme = createTheme(darkModePreview ? themes.dark : themes.light);

  useEffect(() => {
    console.log(preferences, customTheme);
  }, [preferences, customTheme]);

  //onChanges

  const onChangeDarkModePreview = (e) => {setDarkModePreview(e.target.checked)}

  if (isLoading) {
    return (
    <Paper elevation={3} className={classes.paper}>
      <Box justifyContent='space-between' alignItems='center' height={"100%"}>
        <Loader minHeight='100%' size={100}/>
      </Box>
    </Paper>
    )
  }

  return (
      <>
      <div className={classes.wrapper} >
          <div style={{margin: '20px', padding: '20px',}}>
            <InputLabel>
              Alternar visualização para Dark
            </InputLabel>
              <Switch
                  defaultChecked={darkModePreview}
                  defaultValue={false}
                  color="primary"
                  onChange={onChangeDarkModePreview}
              />
          </div>
        <Paper elevation={3} className={classes.paper} style={{backgroundColor: darkModePreview ? preferences.color_background_theme_dark : '#ffffff'}}>
        <ThemeProvider theme={customTheme}>
          <Box>
            <div className={classes.previewWrapper}>
                <Typography className={classes.title} variant='h6' style={{color: darkModePreview ? '#ffffff' : '#000000'}}>
                  Prévia
                </Typography>
                <div className={classes.textPreview} style={{fontFamily: preferences.font_family ? preferences.font_family : `'Roboto', sans-serif`, fontSize: preferences.font_size ? preferences.font_size + 'px' : 12 + 'px', textAlign: preferences.text_alignment ? preferences.text_alignment : 'justify', color: darkModePreview ? '#ffffff' : '#000000'}}>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus consequat commodo nisi, molestie bibendum augue consectetur ornare.
                  Maecenas sit amet pellentesque eros. Sed augue mi, congue in lectus a, interdum aliquam ante.
                </div>

                <Button
                    variant='contained'
                    style={{
                      // backgroundColor: darkModePreview ? preferences.color_theme_dark : (preferences.color_theme || '#303f9f'),
                      borderRadius: preferences.border_radius == 0 ? 0 : preferences.border_radius || 5 + 'px'}}
                    color={'secondary'}
                    fullWidth
                >
                  Exemplo
                </Button>
            </div>
          </Box>
        </ThemeProvider>
        </Paper>
      </div>
      </>
  )
}

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    flexDirection: "column",
    height: '200%'
  },
  previewWrapper: {
    marginTop: '30px'
  },
  textPreview: {
    padding: '20px 0'
  },
  paper: {
    margin: '20px 40px',
    padding: '20px',
    width: '20%'
  },
  item: {
    marginBottom: '20px',
  },
}))

ThemePreview.propTypes = {}

ThemePreview.defaultProps = {}

export default ThemePreview
