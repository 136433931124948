import { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Typography,
  IconButton,
  TextField as MUITextField,
  CircularProgress,
} from '@material-ui/core'
import {
  Edit as EditIcon,
  Cancel as CancelIcon,
  CheckCircle as CheckCircleIcon,
  Delete as DeleteIcon,
  Visibility as VisibilityIcon
} from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'

const EditableField = ({ id, defaultValue, onSave, onRemove, fullWidth, isLoading, onVisiblityRules }) => {
  const classes = useStyles({ fullWidth })
  const [value, setValue] = useState(defaultValue)
  const [editField, setEditField] = useState(false)

  const onChangeValue = (e) => setValue(e.target.value)

  const openEditField = () => setEditField(!editField)

  const cancelEditField = () => {
    setValue(defaultValue)
    setEditField(false)
  }

  return editField ? (
    <div className={classes.content}>
      <MUITextField
        id='label'
        name='label'
        placeholder='Digite o titulo do campo'
        value={value}
        disabled={isLoading}
        onChange={onChangeValue}
        onClick={(event) => event.stopPropagation()}
        onFocus={(event) => event.stopPropagation()}
      />
      {isLoading ? (
        <CircularProgress size={25} />
      ) : (
        <>
          <IconButton
            onClick={(event) => {
              event.stopPropagation()
              onSave({id, value}, { onSuccess: () => setEditField(false) })
            }}
            edge='end'
            aria-label='check'
          >
            <CheckCircleIcon />
          </IconButton>
          <IconButton
            onClick={(event) => {
              event.stopPropagation()
              cancelEditField()
            }}
            edge='end'
            aria-label='cancel'
          >
            <CancelIcon />
          </IconButton>
          {onVisiblityRules && (
             <IconButton onClick={() => onVisiblityRules({ id })} edge='end' aria-label='delete'>
              <VisibilityIcon  />
             </IconButton>
          )}
          {onRemove && (
            <IconButton onClick={() => onRemove({ id })} edge='end' aria-label='delete'>
              <DeleteIcon />
            </IconButton>
          )}
        </>
      )}
    </div>
  ) : (
    <div className={classes.content}>
      <Typography className={classes.heading}>{defaultValue}</Typography>
      <IconButton
        onClick={(event) => {
          event.stopPropagation()
          openEditField()
        }}
        edge='end'
        aria-label='edit'
      >
        <EditIcon />
      </IconButton>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: ({ fullWidth }) => (fullWidth ? 'space-between' : 'flex-start'),
  },
}))

EditableField.propTypes = {
  defaultValue: PropTypes.string.isRequired,
  fullWidth: PropTypes.bool,
  id: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  onRemove: PropTypes.func,
  onSave: PropTypes.func.isRequired,
}

EditableField.defaultProps = {
  fullWidth: false,
  isLoading: false,
  onRemove: null,
}

export default EditableField
