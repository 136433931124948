import { useState } from 'react'
import PropTypes from 'prop-types'
import { IconButton } from '@material-ui/core'
import { Delete as DeleteIcon } from '@material-ui/icons'
import { useRemoveForm } from 'hooks/useRemoveForm'
import { Dialog, } from 'components'

const RemoveForm = ({ id, title }) => {
  const [open, setOpenDialog] = useState(false)

  const { mutate: remove, isLoading } = useRemoveForm({
    onSuccess: () => setOpenDialog(false),
  })

  return (
    <>
      <IconButton size='small' onClick={() => setOpenDialog(true)} edge='end' aria-label='delete'>
        <DeleteIcon />
      </IconButton>
      <Dialog
        title='Excluir Formulário'
        contentText={`Você tem certeza que deseja excluir o formulário "${title}"?`}
        open={open}
        onCancel={() => setOpenDialog(false)}
        isLoading={isLoading}
        onSubmit={() => remove(id)}
        submitLabel="Excluir"
      />
    </>
  )
}

RemoveForm.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

RemoveForm.defaultProps = {}

export default RemoveForm
