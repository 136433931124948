

const currencyChange = (newState) => {
    var { value } = newState;
    var selection = { start: -1, end: -1 };
    value = value.replace(/[\sa-zA-Z$]/g, "")
        .replace(/(\d{2})(,\d|$)/, ',$1$2').trim();

    value = value.replace(/^0+/, '')
    if (value.length < 4) {
        value = "0" + value
    }

    if (value != 0) {
        const parts = value.toString().split(",");
        const integerPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        value = `${integerPart},${parts[1]}`;
    }

    value = `R$ ${value}`
    return {
        value,
        selection
    };
}

const numberChange = (newState) => {
    var { value } = newState;
    var selection = { start: -1, end: -1 };

    value = value.replace(/[^0-9]/g, '')

    return {
        value,
        selection
    };
}

const masks = [
    {
        id: 'cpf',
        title: 'CPF',
        mask: '999.999.999-99'
    },
    {
        id: 'rg',
        title: 'RG',
        mask: '99.999.9999-9'
    },
    {
        id: 'phone',
        title: 'Telefone/Celular',
        mask: '(99) 99999-9999'
    },
    {
        id: 'cnpj',
        title: 'CNPJ',
        mask: '99.999.999/9999-99'
    },
    {
        id: 'cep',
        title: 'CEP',
        mask: '99999-999'
    },
    {
        id: 'currency',
        title: 'Moeda',
        mask: 'R$ ***********************',
        permanents: [0, 1, 2],
        beforeMaskedValueChange: currencyChange
    },
    {
        id: 'number',
        title: 'Número',
        mask: '**************************',
        beforeMaskedValueChange: numberChange
    },
    {
        id: 'any',
        title: 'Any',
        mask: '********************************************************************************',
    },
    {
        id: 'imc',
        title: 'IMC',
        mask: '999',
    }
]


export { masks }
