import {  Typography, Toolbar, AppBar, Paper, Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import {  Avatar } from 'components'

const Settings = () => {
    const classes = useStyles()

    return (
        <>
            <AppBar position='static'>
            <Toolbar>
                <Typography className={classes.title} variant='h6' color='inherit'>
                Seja bem-vindo!
                </Typography>
                <Avatar />
            </Toolbar>
            </AppBar>
            <Paper elevation={3} className={classes.paper}>
            <Box display='flex' justifyContent='space-between'>
                <Typography variant='h6'>Configurações</Typography>

            </Box>
            </Paper>
        </>
    )
}

const useStyles = makeStyles(() => ({
  paper: {
    margin: '20px 40px',
    padding: '20px',
  },
  delete: {
    display: 'flex',
    justifyContent: 'space-evenly',
  },
  title: {
    flexGrow: 1,
  },
}))

export default Settings
