import { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { AppBar as AppBarMUI, Toolbar, IconButton, Typography } from '@material-ui/core'
import MenuItem from '@material-ui/icons/Menu'
import { makeStyles } from '@material-ui/core/styles'
import StepDrawer from './step-drawer/StepDrawer'
import { Avatar } from 'components'

const useStyles = makeStyles(() => ({
  title: {
    flexGrow: 1,
  },
}))
const AppBar = ({ project, isLoading, menuLeft }) => {
  const [open, setOpen] = useState(false)
  const classes = useStyles()
  const handleDrawer = useCallback(() => setOpen(!open), [open])

  return (
    <>
      <AppBarMUI position='static'>
        <Toolbar>
          <IconButton
            disabled={isLoading}
            onClick={handleDrawer}
            edge='start'
            color='inherit'
            aria-label='menu'
          >
            
            <MenuItem />
          </IconButton>
          <Typography variant='h6' color='inherit' className={classes.title}>
            {project}
          </Typography>
          {menuLeft}
          <Avatar/>
        </Toolbar>
      </AppBarMUI>

      <StepDrawer open={open} handleDrawer={handleDrawer} />
    </>
  )
}

AppBar.propTypes = {
  isLoading: PropTypes.bool,
  menuLeft: PropTypes.object,
  project: PropTypes.string,
  
}

AppBar.defaultProps = {
  isLoading: false,
  menuLeft: null,
  project: null
}

export default AppBar
