import { makeStyles } from '@material-ui/core/styles'
import { useParams } from 'react-router-dom'
import { isEmpty } from 'lodash'
import { useMetrics } from 'hooks/metrics'
import { useFetchProject } from 'hooks/useProject'
import { DraggableList, Loader, EmptyState } from 'components'

import Group from './group/Group'
import AddMetric from './add-metric/AddMetric'

const Metrics = () => {
  const { projectId } = useParams();
  const { isLoading, metrics } = useMetrics(projectId)
  const { data, isLoading: isLoadingProject } = useFetchProject(projectId, { all: true })
  const classes = useStyles()

  if (isLoading || isLoadingProject) {
    return <Loader size={100}/>
  }

  if (isEmpty(metrics)) {
    return (
      <>
        <EmptyState />
        <AddMetric isEmpty />
      </>
    )
  }

  return (
    <>
      <DraggableList items={metrics}>
        {({ ref, item, snapshotProps, ...rest }) => (
          <div className={classes.item} ref={ref} {...rest}>
            <Group item={item} isDragging={snapshotProps.isDragging} project={data} />
          </div>
        )}
      </DraggableList>
      <AddMetric />
    </>
  )
}

const useStyles = makeStyles(() => ({
  item: {
    marginBottom: '20px',
  },
}))

Metrics.propTypes = {}

Metrics.defaultProps = {}

export default Metrics
