import {InputField} from 'components'
import {MenuItem} from "@material-ui/core";
const SimpleSelectField = ({
     control,
     segment,
     id,
     name,
     label,
     trueLabel = 'Sim',
     falseLabel = 'Não'
    }) => {
    return(
        <>
            <InputField select
                id={`${id}.${segment}.${name}`}
                name={`${id}.${segment}.${name}`}
                label={label}
                control={control}>
                <MenuItem value={1} >{trueLabel}</MenuItem>
                <MenuItem value={0} >{falseLabel}</MenuItem>
            </InputField>
        </>
    )
}

export default SimpleSelectField
