import React, { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { get, isEmpty } from 'lodash'
import { useForm, FormProvider } from 'react-hook-form'
import {Button, FormHelperText} from '@material-ui/core'
import {makeStyles, useTheme} from '@material-ui/core/styles'
import { useFields } from 'hooks/fields'
import { useSteps } from 'hooks/steps'
import FormContext from '../../contexts/FormContext'

import { CustomField, EmptyState } from 'components'
import validateDisplayRules, { validateDisplayRulesCustomer } from 'functions/validateDisplayRules'
import {HelpOutlined} from "@material-ui/icons";

const Form = ({ formSteps, isPreview, customer = {}, defaultAnswers = {}, handleNext, handleSave }) => {
	const { activeStep, steps, nextStepLast, setNextStepIsLast } = useContext(FormContext);
	const { selected: stepId } = useSteps()
	const { fields } = useFields(stepId, true)
	const theme = useTheme();


	const methods = useForm({
		mode: 'onBlur',
		reValidateMode: 'onBlur',
	})

	const {
		watch,
		handleSubmit,
		formState: { isValid, isDirty },
		getValues,
	} = methods

	const classes = useStyles()
	const watchAll = watch();

	const getWatching = () => {
		const items = fields.filter((item) =>
			fields.some((elem) => get(elem, 'display_rules', []).some(({ field }) => field === item.id))
		)
		return items.map((item) => item.id)
	}

	const itemsWatching = getWatching()

	if (!isEmpty(itemsWatching)) {
		watch(itemsWatching)
	}

	const getRequiredFields = () => {
		const items = fields.filter((item) => {
			return item.required === true || item.required === 1
		})

		return [...items.map((item) => item.id), 'height', 'weight']
	}

	const watchFields = getRequiredFields()
	if (!isEmpty(watchFields)) {
		watch(watchFields)
	}

	const requiredFields = () => {
		const items = fields.filter((field) => {
			return (
				field.type == 'imc' || field.type == 'imc_gestacional' || 
				(
					watchFields.find((watched) => watched === field.id) &&
					validateDisplayRules(getValues(), field) &&
					validateDisplayRulesCustomer(customer, field) &&
					field.required
				)
			)
		})
		const values = getValues()

		let disabled = false

		if (items)
			items.map((item) => {
				if (!disabled) {
					if (values[item.id] === undefined && item.type !== 'imc') console.log('undefined')

					else if (typeof values[item.id] == 'object' && values[item.id]?.length == 0) {
						disabled = true;
					}

					// else if (item.type == 'imc' && (parseFloat(values['height']) <= 0 || parseFloat(values['weight']) <= 0)) {
					// 	console.log('dis imc', item.type, item.id, '<<<<||>>>>', values['height'], '<<<<||>>>>', parseFloat(values['height']));
					// 	disabled = true;
					// }

					else if (item.type !== 'imc' && (values[item.id] == '' || values[item.id] == null)) {
						disabled = true;
					}
				}
			})

		return disabled
	}

	const requiredFieldsWatching = requiredFields()

	if (!isEmpty(requiredFieldsWatching)) {
		watch(requiredFieldsWatching)
	}

	const labelComponent = fields.find(({ type }) => type === 'label')

	const oform = document.getElementById("oform")

	useEffect(() => {
		if (oform)
			oform.onkeypress = function (e) {
				var key = e.charCode || e.keyCode || 0;
				if (key == 13) {
					e.preventDefault();
				}
			}
	}, [oform])

  const onSave = () => {
    const values = getValues()
    handleSave(values)
  }

	useEffect(() => {
		const nextIsLastStep = () => {
			const orderedSteps = formSteps.sort((a, b) => a.order > b.order ? 1 : -1)
			if (activeStep + 1 === steps) setNextStepIsLast(true)
			if (!isEmpty(orderedSteps[activeStep + 1])) {
				let isLast = true;

				for (let i = activeStep + 1; i < orderedSteps.length; i++) {
					if (isLast) {
						isLast = !(validateDisplayRules(watchAll, orderedSteps[i]) && validateDisplayRulesCustomer(customer, orderedSteps[i]))
					}
				}
				setNextStepIsLast(isLast)
				// const isLast = orderedSteps.every(step => validateDisplayRules(watchAll, step) && validateDisplayRulesCustomer(customer, step))
				// console.log('islast: ', isLast);
				// setNextStepIsLast(isLast);
				// const nextStep = orderedSteps[activeStep + 1]
				// if (nextStep.display_rules.length === 0) setNextStepIsLast(false)
				// else if (validateDisplayRules(watchAll, nextStep) && validateDisplayRulesCustomer(customer, nextStep, true)) setNextStepIsLast(false)
				// else setNextStepIsLast(true)
			}
		}
		nextIsLastStep()
	}, [watchAll, customer])

	return (
		<div className={classes.wrapper}>
			<FormProvider {...methods}>
				<form id="oform" autoComplete='off' className={classes.form} onSubmit={handleSubmit(handleNext)}>
					{isEmpty(fields) ? (
						<EmptyState text='Ainda não há nenhum campo para esta etapa do formulário.' />
					) : (
						<>
							{!isEmpty(labelComponent) && (
								<CustomField key={labelComponent.id} customer={customer} {...labelComponent} />
							)}
							{fields
								.filter(({ type }) => type !== 'label') // filtra os campos exceto a label do componente
								.map(
									(field) =>
										validateDisplayRules(getValues(), field) &&
										validateDisplayRulesCustomer(customer, field) && (
											<CustomField isPreview={isPreview} defaultAnswers={defaultAnswers} key={field.id} {...field} />
										)
								)}
							<fieldset
								style={{
									borderRadius: '8px', // Rounded corners
									border: !nextStepLast ? `none` : `1px solid rgba(0, 0, 0, 0.2)`, // Border with reduced opacity
									borderColor: `rgba(${theme.palette.primary.main.replace('#', '')}, 0.5)`, // Primary color with opacity
								}}
							>
								<div className={classes.buttonWrapper}>
									<Button
										fullWidth
										type='submit'
										variant='contained'
										color='primary'
										disabled={requiredFieldsWatching}
									>
										{!nextStepLast ? 'Avançar' : 'Enviar'}
									</Button>
								</div>
								{
									nextStepLast && (
										<FormHelperText className={'MuiFormHelperText-contained'} style={{ display: 'flex', alignItems: 'start' }}>
											<HelpOutlined color={'primary'} style={{ fontSize: '16px', marginRight: '8px' }} />
											Ao clicar em "Enviar" os dados preenchidos serão processados. Certifique-se de que todos os campos estejam devidamente preenchidos antes de enviar.
										</FormHelperText>
									)
								}

							</fieldset>

							<fieldset
								style={{
									borderRadius: '8px', // Rounded corners
									border: `1px solid rgba(0, 0, 0, 0.2)`, // Border with reduced opacity
									borderColor: `rgba(${theme.palette.primary.main.replace('#', '')}, 0.5)`, // Primary color with opacity
									marginTop: '30px'
								}}
							>

								<div className={classes.buttonWrapper}>
									<Button
										fullWidth
										variant='contained'
										color='primary'
										onClick={onSave}
										disabled={requiredFieldsWatching}
									>
										Salvar
									</Button>
								</div>
								<FormHelperText className={'MuiFormHelperText-contained'} style={{ display: 'flex', alignItems: 'start' }}>
									<HelpOutlined color={'primary'} style={{ fontSize: '16px', marginRight: '8px' }} />
									Ao clicar em "Salvar", os dados preenchidos serão apenas armazenados. Para finalização e processamento clique em "Enviar".
								</FormHelperText>
							</fieldset>
						</>
					)}
				</form>
			</FormProvider>
		</div>
	)
}

const useStyles = makeStyles(() => ({
	wrapper: {
		padding: '20px 5px',
		display: 'flex',
		justifyContent: 'center',
	},
	form: {
		width: '100%',
		maxWidth: '410px',
	},
	buttonWrapper: {
		display: 'flex',
		justifyContent: 'center',
		marginTop: '20px',
	},
}))

Form.propTypes = {
	customer: PropTypes.func.isRequired,
	formSteps: PropTypes.array.isRequired,
	handleNext: PropTypes.func.isRequired,
}

Form.defaultProps = {}

export default Form
