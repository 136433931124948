import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const EmptyState = ({ text }) => {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <svg
        width='250'
        height='200'
        viewBox='0 0 250 200'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M93 135H176C179.866 135 183 131.866 183 128C183 124.134 179.866 121 176 121C176 121 170 117.866 170 114C170 110.134 173.952 107 178.826 107H189C192.866 107 196 103.866 196 100C196 96.134 192.866 93 189 93H167C170.866 93 174 89.866 174 86C174 82.134 170.866 79 167 79H207C210.866 79 214 75.866 214 72C214 68.134 210.866 65 207 65H109C105.134 65 102 68.134 102 72C102 75.866 105.134 79 109 79H69C65.134 79 62 82.134 62 86C62 89.866 65.134 93 69 93H94C97.866 93 101 96.134 101 100C101 103.866 97.866 107 94 107H54C50.134 107 47 110.134 47 114C47 117.866 50.134 121 54 121H93C89.134 121 86 124.134 86 128C86 131.866 89.134 135 93 135ZM200 100C200 103.866 203.134 107 207 107C210.866 107 214 103.866 214 100C214 96.134 210.866 93 207 93C203.134 93 200 96.134 200 100Z'
          fill='#F3F7FF'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M153.672 64L162.974 131.843L163.809 138.649C164.079 140.842 162.519 142.837 160.327 143.107L101.766 150.297C99.5738 150.566 97.578 149.007 97.3088 146.814L88.2931 73.3868C88.1584 72.2904 88.9381 71.2925 90.0344 71.1579C90.0413 71.1571 90.0483 71.1563 90.0552 71.1555L94.9136 70.6105M98.8421 70.1698L103.429 69.6553L98.8421 70.1698Z'
          fill='white'
        />
        <path
          d='M154.91 63.8302C154.816 63.1463 154.186 62.6678 153.502 62.7616C152.818 62.8554 152.34 63.4859 152.433 64.1698L154.91 63.8302ZM162.974 131.843L164.214 131.69L164.213 131.682L164.212 131.673L162.974 131.843ZM163.809 138.649L165.05 138.497L163.809 138.649ZM160.327 143.107L160.479 144.347L160.327 143.107ZM101.766 150.297L101.919 151.538L101.766 150.297ZM97.3088 146.814L98.5495 146.662L97.3088 146.814ZM88.2931 73.3868L89.5337 73.2344L88.2931 73.3868ZM90.0552 71.1555L90.1945 72.3977L90.0552 71.1555ZM95.0529 71.8527C95.739 71.7758 96.2327 71.1572 96.1558 70.4712C96.0788 69.7851 95.4603 69.2913 94.7742 69.3683L95.0529 71.8527ZM98.7028 68.9276C98.0167 69.0046 97.5229 69.6231 97.5999 70.3092C97.6768 70.9952 98.2954 71.489 98.9814 71.412L98.7028 68.9276ZM103.568 70.8975C104.255 70.8205 104.748 70.202 104.671 69.5159C104.594 68.8299 103.976 68.3361 103.29 68.4131L103.568 70.8975ZM152.433 64.1698L161.735 132.013L164.212 131.673L154.91 63.8302L152.433 64.1698ZM161.733 131.995L162.569 138.801L165.05 138.497L164.214 131.69L161.733 131.995ZM162.569 138.801C162.754 140.309 161.682 141.681 160.174 141.866L160.479 144.347C163.357 143.994 165.403 141.375 165.05 138.497L162.569 138.801ZM160.174 141.866L101.614 149.056L101.919 151.538L160.479 144.347L160.174 141.866ZM101.614 149.056C100.107 149.241 98.7346 148.169 98.5495 146.662L96.0681 146.967C96.4215 149.845 99.0409 151.891 101.919 151.538L101.614 149.056ZM98.5495 146.662L89.5337 73.2344L87.0524 73.5391L96.0681 146.967L98.5495 146.662ZM89.5337 73.2344C89.4833 72.8233 89.7756 72.4491 90.1867 72.3986L89.8821 69.9173C88.1005 70.136 86.8336 71.7576 87.0524 73.5391L89.5337 73.2344ZM90.1867 72.3986C90.1893 72.3983 90.1919 72.398 90.1945 72.3977L89.9159 69.9133C89.9046 69.9145 89.8933 69.9159 89.8821 69.9173L90.1867 72.3986ZM90.1945 72.3977L95.0529 71.8527L94.7742 69.3683L89.9159 69.9133L90.1945 72.3977ZM98.9814 71.412L103.568 70.8975L103.29 68.4131L98.7028 68.9276L98.9814 71.412Z'
          fill='#75A4FE'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M151.14 68.2692L159.56 129.753L160.317 135.921C160.561 137.908 159.167 139.715 157.203 139.956L104.761 146.395C102.798 146.636 101.008 145.22 100.764 143.233L92.6141 76.8568C92.4795 75.7605 93.2591 74.7626 94.3555 74.628L100.843 73.8314'
          fill='#E8F0FE'
        />
        <path
          d='M107.922 54C107.922 52.4812 109.153 51.25 110.672 51.25H156.229C156.958 51.25 157.657 51.5395 158.173 52.0549L171.616 65.4898C172.132 66.0056 172.422 66.7053 172.422 67.4349V130C172.422 131.519 171.191 132.75 169.672 132.75H110.672C109.153 132.75 107.922 131.519 107.922 130V54Z'
          fill='white'
          stroke='#75A4FE'
          strokeWidth='2.5'
        />
        <path
          d='M156.672 52.4028V64C156.672 65.6569 158.015 67 159.672 67H167.605'
          stroke='#75A4FE'
          strokeWidth='2.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M118.672 64.5C117.291 64.5 116.172 65.6193 116.172 67H118.672V64.5ZM144.672 64.5H118.672V67H144.672V64.5ZM147.172 67C147.172 65.6193 146.053 64.5 144.672 64.5V67H147.172ZM144.672 69.5C146.053 69.5 147.172 68.3807 147.172 67H144.672V69.5ZM118.672 69.5H144.672V67H118.672V69.5ZM116.172 67C116.172 68.3807 117.291 69.5 118.672 69.5V67H116.172ZM118.672 76.5C117.291 76.5 116.172 77.6193 116.172 79H118.672V76.5ZM161.672 76.5H118.672V79H161.672V76.5ZM164.172 79C164.172 77.6193 163.053 76.5 161.672 76.5V79H164.172ZM161.672 81.5C163.053 81.5 164.172 80.3807 164.172 79H161.672V81.5ZM118.672 81.5H161.672V79H118.672V81.5ZM116.172 79C116.172 80.3807 117.291 81.5 118.672 81.5V79H116.172ZM118.672 92V89.5C117.291 89.5 116.172 90.6193 116.172 92H118.672ZM118.672 92H116.172C116.172 93.3807 117.291 94.5 118.672 94.5V92ZM161.672 92H118.672V94.5H161.672V92ZM161.672 92V94.5C163.053 94.5 164.172 93.3807 164.172 92H161.672ZM161.672 92H164.172C164.172 90.6193 163.053 89.5 161.672 89.5V92ZM118.672 92H161.672V89.5H118.672V92ZM118.672 102.5C117.291 102.5 116.172 103.619 116.172 105H118.672V102.5ZM161.672 102.5H118.672V105H161.672V102.5ZM164.172 105C164.172 103.619 163.053 102.5 161.672 102.5V105H164.172ZM161.672 107.5C163.053 107.5 164.172 106.381 164.172 105H161.672V107.5ZM118.672 107.5H161.672V105H118.672V107.5ZM116.172 105C116.172 106.381 117.291 107.5 118.672 107.5V105H116.172ZM118.672 118V115.5C117.291 115.5 116.172 116.619 116.172 118H118.672ZM118.672 118H116.172C116.172 119.381 117.291 120.5 118.672 120.5V118ZM144.672 118H118.672V120.5H144.672V118ZM144.672 118V120.5C146.053 120.5 147.172 119.381 147.172 118H144.672ZM144.672 118H147.172C147.172 116.619 146.053 115.5 144.672 115.5V118ZM118.672 118H144.672V115.5H118.672V118Z'
          fill='#A4C3FE'
        />
      </svg>
      <Typography className={classes.text} variant='h6'>{text}</Typography>
    </div>
  )
}

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 auto',
  },
  text: {
    textAlign: 'center'
  },
}))

EmptyState.propTypes = {
  text: PropTypes.string,
}

EmptyState.defaultProps = {
  text: 'Nenhum item para mostrar aqui.'
}

export default EmptyState
