import { MenuItem } from "@material-ui/core"
import { InputField } from "components"
import { genderFields } from "enum/customerFields"
import { operators } from "enum/operators"
import { useFormContext } from "react-hook-form"

export default ({ customer = {}, path}) => {
    const { control, watch, getValues, setValue } = useFormContext()
    const customerFields = Object.keys(customer ?? {});
    const displayRules = watch(`${path}`, [])
    return (
        <>
        <InputField
            id='field'
            size='small'
            fullWidth
            name={`${path}.field`}
            label='Campo'
            defaultValue={getValues(`${path}.field`)}
            disableMargin
            select
            >
            {customerFields.map(
            (opt) => (
                <MenuItem key={opt} value={opt}>
                    {opt}
                </MenuItem>
                )
            )}
        </InputField>
        <InputField
              id='operador'
              name={`${path}.operator`}
              control={control}
              defaultValue={getValues(`${path}.operator`)}
              size='small'
              variant='outlined'
              label='Operador'
              disableMargin
              fullWidth
              select
            >
              {operators.map((opt) => (
                <MenuItem key={opt.value} value={opt.value}>
                  {opt.label}
                </MenuItem>
              ))}
            </InputField>

            <InputField
                size='small'
                variant='outlined'
                fullWidth
                label='Valor'
                select={displayRules.field == 'gender'}
                name={`${path}.value`}
                control={control}
                defaultValue={getValues(`${path}.value`)}
                disableMargin
            >
                <MenuItem value={""}>
                Nenhum valor
                </MenuItem>
                {displayRules.field == 'gender' ?

                    (genderFields.map((opt) => (
                    <MenuItem key={opt.value} value={opt.value}>
                        {opt.title}
                    </MenuItem>
                    )))
                   : ""
                }
            </InputField>
        </>
    )
}