import { useFormContext, useFieldArray } from 'react-hook-form'
import PropTypes from 'prop-types'
import { v4 as uuidv4 } from 'uuid'
import {
  Button,
  Typography,
  MenuItem,
  List,
  ListItem,
  ListItemSecondaryAction,
  IconButton,
  Grid,
} from '@material-ui/core'
import { Delete as DeleteIcon, Add as AddIcon } from '@material-ui/icons/'
import { components, needOptions, needMask } from 'enum/form-field'
import { masks } from 'enum/fieldsMasks'
import { InputField, Checkbox } from 'components'
import { isEmpty } from 'lodash'
import { useFields } from 'hooks/fields'

const FormField = ({ project, data, errors }) => {
  const { control, watch, setValue, getValues } = useFormContext()
  const { fields } = useFields()

  const {
    fields: fieldOptions,
    append,
    remove,
  } = useFieldArray({
    control,
    name: 'options',
    keyName: 'key',
  })
  const watchComponent = watch('type', false)
  const watchOptions = watch('options', [])

  const verifyOptions = (type) => {
    if (!needOptions.includes(watchComponent) && needOptions.includes(type)) {
      setValue('options', [{ label: '', value: uuidv4(), order: 0 }])
    }

    if (needOptions.includes(watchComponent) && !needOptions.includes(type)) {
      setValue('options', [])
      setValue('default_value', null)
    }

    setValue('type', type)
  }

  return (
    <Grid container xs={12}>
      <Grid item xs={12}>
        <Typography variant='h6' color='inherit' align='center'>
          Dados básicos do campo
        </Typography>
      </Grid>
      <Grid container justifyContent='space-evenly' wrap='nowrap' spacing={2}>
        <Grid item>
          <Grid item xs={12}>
            <InputField
              id='index'
              name='index'
              label='Indice de integração'
              helperText={'Este campo sera utilizado para integrações e preenchimentos via API'}
              variant='standard'
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <InputField
              id='tag'
              name='tag'
              label='Tag da Questão'
              variant='standard'
              error={errors?.tag ? true : false}
              helperText={errors?.tag ? errors.tag.message : null}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <InputField
              id='label'
              name='label'
              label='Título da Questão'
              variant='standard'
              error={errors?.label ? true : false}
              helperText={errors?.label ? errors.label.message : null}
              fullWidth
            />
          </Grid>

          <Grid item xs={12}>
            <InputField
              id='type'
              name='type'
              label='Tipo do campo'
              variant='standard'
              onChange={(e) => verifyOptions(e.target.value)}
              fullWidth
              select
            >
              {components.map((opt) => (
                <MenuItem key={opt.label} value={opt.value}>
                  {opt.label}
                </MenuItem>
              ))}
            </InputField>
          </Grid>
          <Grid item xs={12}>
            <Checkbox name='required' label='Obrigatório' control={control} value='1' />
          </Grid>
        </Grid>

        <Grid item>
          <Grid item xs={12}>
            <InputField
              id='default_value'
              name='default_value'
              label='Valor default'
              variant='standard'
              error={errors?.default_value ? true : false}
              helperText={errors?.default_value ? errors.default_value.message : null}
              fullWidth
              inputProps={{ maxLength: 255 }}
              select={needOptions.includes(watchComponent) && !isEmpty(watchOptions)}
            >
              <MenuItem value={null}>Nenhuma</MenuItem>
              {!isEmpty(watchOptions) &&
                watchOptions.map((opt) => (
                  <MenuItem key={opt.label} value={opt.value}>
                    {opt.label}
                  </MenuItem>
                ))}
            </InputField>
          </Grid>
          <Grid item xs={12}>
            <InputField
              id='helper_text'
              name='helper_text'
              label='Campo de ajuda'
              variant='standard'
              fullWidth
            />
          </Grid>
          {needMask.includes(watchComponent) && (
            <Grid item xs={12}>
              <InputField id='mask' name='mask' label='Máscara' variant='standard' select fullWidth>
                {
                  <MenuItem key={-1} value={-1}>
                    &nbsp;
                  </MenuItem>
                }
                {masks.map((mask) => (
                  <MenuItem key={mask.id} value={mask.id}>
                    {mask.title}
                  </MenuItem>
                ))}
              </InputField>
            </Grid>
          )}

          {needOptions.includes(watchComponent) && (
            <Grid item xs={12}>
              <Typography variant='h6' color='inherit'>
                Opções de seleção
              </Typography>
              <List disablePadding>
                {fieldOptions.map((item, index) => (
                  <ListItem key={item.value} disableGutters>
                    <InputField
                      id={`options.${index}.value`}
                      name={`options.${index}.label`}
                      defaultValue={getValues(`options.${index}.label`)}
                      error={errors?.options && errors.options[index] != null ? true : false}
                      helperText={
                        errors?.options && errors.options[index] != null
                          ? errors.options[index].label.message
                          : null
                      }
                      size='small'
                      inputProps={{ maxLength: 255 }}
                      fullWidth
                      disableMargin
                    />
                    <InputField
                      id={`options.${index}.index`}
                      name={`options.${index}.index`}
                      defaultValue={getValues(`options.${index}.index`)}
                      error={errors?.options && errors.options[index] != null ? true : false}
                      helperText={
                        errors?.options && errors.options[index] != null
                          ? errors.options[index].label.message
                          : null
                      }
                      size='small'
                      placeholder={'Indice'}
                      style={{ maxWidth: '100px' }}
                      inputProps={{ maxLength: 255 }}
                      disableMargin
                    />
                    <ListItemSecondaryAction>
                      <IconButton onClick={() => remove(index)} edge='end' aria-label='delete'>
                        <DeleteIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>

              <Button
                variant='contained'
                size='small'
                color='primary'
                startIcon={<AddIcon />}
                onClick={() => {
                  const value = uuidv4()
                  append({
                    label: '',
                    value,
                    order: 0,
                    key: value,
                  })
                }}
              >
                Adicionar
              </Button>
              <br></br>
            </Grid>
          )}
          {watchComponent == 'decimal' && (
            <Grid item xs={12}>
              <Typography variant='h6' color='inherit'>
                Exclusivo Número com Decimal
              </Typography>
              <List disablePadding>
                <InputField
                  id={`extras.decimal_point`}
                  name={`extras.decimal_point`}
                  label={'Qntd. decimal'}
                  fullWidth
                  variant='standard'
                  select
                >
                  <MenuItem value={1} key={1}>
                    1
                  </MenuItem>
                  <MenuItem value={2} key={2}>
                    2
                  </MenuItem>
                  <MenuItem value={3} key={3}>
                    3
                  </MenuItem>
                </InputField>
              </List>
            </Grid>
          )}
          {watchComponent == 'imc_gestacional' && (
            <Grid item xs={12}>
              <Typography variant='h6' color='inherit'>
                Exclusivo IMC Gestacional
              </Typography>
              <List disablePadding>
                <InputField
                  id={`extras.ultrassom.date`}
                  name={`extras.ultrassom.date`}
                  label={'Data do Ultrassom'}
                  fullWidth
                  variant='standard'
                  select
                >
                  {fields.map((fld) => (
                    <MenuItem value={fld.id} key={fld.id}>
                      {fld.tag}
                    </MenuItem>
                  ))}
                </InputField>
                <InputField
                  id={`extras.ultrassom.weeks`}
                  name={`extras.ultrassom.weeks`}
                  label={'Semanas do Ultrassom'}
                  fullWidth
                  variant='standard'
                  select
                >
                  {fields.map((fld) => (
                    <MenuItem value={fld.id} key={fld.id}>
                      {fld.tag}
                    </MenuItem>
                  ))}
                </InputField>
                <InputField
                  id={`extras.ultrassom.days`}
                  name={`extras.ultrassom.days`}
                  label={'Dias do Ultrassom'}
                  fullWidth
                  variant='standard'
                  select
                >
                  {fields.map((fld) => (
                    <MenuItem value={fld.id} key={fld.id}>
                      {fld.tag}
                    </MenuItem>
                  ))}
                </InputField>
              </List>
              <List disablePadding>
                <InputField
                  id={`extras.menstruacao.date`}
                  name={`extras.menstruacao.date`}
                  label={'Data da Menstruação'}
                  fullWidth
                  variant='standard'
                  select
                >
                  {fields.map((fld) => (
                    <MenuItem value={fld.id} key={fld.id}>
                      {fld.tag}
                    </MenuItem>
                  ))}
                </InputField>
              </List>
              <List disablePadding>
                <InputField
                  id={`extras.gestacao.weeks`}
                  name={`extras.gestacao.weeks`}
                  label={'Semanas do Inicio da Gestação'}
                  fullWidth
                  variant='standard'
                  select
                >
                  {fields.map((fld) => (
                    <MenuItem value={fld.id} key={fld.id}>
                      {fld.tag}
                    </MenuItem>
                  ))}
                </InputField>
              </List>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}

FormField.propTypes = {
  errors: PropTypes.object.isRequired,
}

FormField.defaultProps = {}

export default FormField
