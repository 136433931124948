import PropTypes from 'prop-types'
import { QueryProvider } from './queryClient'
import { AuthProvider } from './auth'
import { AccordionProvider } from './accordion'

const AppProvider = ({ children }) => {
  return (
    <QueryProvider>
      <AuthProvider>
        <AccordionProvider>{children}</AccordionProvider>
      </AuthProvider>
    </QueryProvider>
  )
}

AppProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default AppProvider
