import PropTypes from 'prop-types'
import clsx from 'clsx'
import { useFormContext, Controller } from 'react-hook-form'
import {
  RadioGroup as MUIRadioGroup,
  Radio,
  FormControl,
  FormLabel,
  Typography,
  FormControlLabel,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const RadioGroup = ({ id, label, name, defaultValue, disableMargin, options }) => {
  const {
    control,
    formState: { isSubmitting },
  } = useFormContext()
  const classes = useStyles()

  return (
    <FormControl
      id={id}
      component='fieldset'
      className={clsx({
        [classes.root]: !disableMargin,
      })}
    >
      <FormLabel className={classes.legend} component='legend'>{label}</FormLabel>
      <Controller
        render={({ field }) => (
          <MUIRadioGroup {...field} id={id} name={name} aria-label={label}>
            {options.map((opt) => (
              <FormControlLabel
                className={classes.option}
                key={opt.label}
                value={opt.value}
                control={<Radio className={classes.radio} />}
                label={<Typography className={classes.label}>{opt.label}</Typography>}
              />
            ))}
          </MUIRadioGroup>
        )}
        id={id}
        name={name}
        control={control}
        defaultValue={defaultValue}
        disabled={isSubmitting}
      />
    </FormControl>
  )
}

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    margin: '10px 0px',
  },
  option: {
    marginBottom: '20px',
  },
  radio:{
    alignSelf: 'baseline',
  },
  label:{
    textAlign: 'justify'
  },
  legend:{
    lineHeight:'24px',
    marginBottom: '10px'
  }
}))

RadioGroup.propTypes = {
  defaultValue: PropTypes.string,
  disableMargin: PropTypes.bool,
  id: PropTypes.string,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
}

RadioGroup.defaultProps = {
  defaultValue: '',
  disableMargin: false,
  id: '',
}

export default RadioGroup
