import { useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Fab, MenuItem, Button, Typography } from '@material-ui/core'
import { Add as AddIcon } from '@material-ui/icons'
import { useSteps } from 'hooks/steps'
import { useCreateField } from 'hooks/fields'
import { Dialog, InputField, } from 'components'
import { components } from 'enum/form-field'
import normalize from 'functions/normalizeFieldName'
import * as yup from 'yup'

const schema = yup.object().shape({
  label: yup.string().required('Esse campo é obrigatório').min(4, 'O título deve ter no mínimo 4 caracteres').max(255, 'O título deve ter no máximo 255 caracteres'),
  type: yup.string().required('Esse campo é obrigatório'),
})

const AddField = ({ order, isEmpty }) => {
  const { selected: stepId } = useSteps()
  const [open, setOpenDialog] = useState(false)
  const classes = useStyles()

  const methods = useForm({
    mode: 'onTouched',
    resolver: yupResolver(schema),
    shouldUnregister: true,
  })
  const {
    handleSubmit,
    formState: { isValid, isDirty, errors },
  } = methods

  const { mutate, isLoading } = useCreateField(stepId, {
    onSuccess: () => setOpenDialog(false),
  })

  return (
    <>
      {isEmpty ? (
        <Button
          className={classes.button}
          onClick={() => setOpenDialog(true)}
          variant='contained'
          startIcon={<AddIcon />}
          color='primary'
        >
          Criar a primeira pergunta
        </Button>
      ) : (
        <Fab color='primary' aria-label='add' onClick={() => setOpenDialog(true)}>
          <AddIcon />
        </Fab>
      )}

      <FormProvider {...methods}>
        <Dialog
          title='Adicionar Pergunta'
          contentText='Defina o título e o tipo da nova pergunta do formulário. Essas informações poderão ser alteradas depois.'
          open={open}
          onCancel={() => setOpenDialog(false)}
          isLoading={isLoading}
          submitDisabled={!isValid || !isDirty}
          onSubmit={handleSubmit(({ type, label }) =>
            mutate({ step_id: stepId, order, label, type, component: type, title: label, name: normalize(label), required: false })
          )}
        >
          <InputField 
            id='label'
            name='label'
            label='Título da Questão'
            error={errors?.label}
            required
            fullWidth
            inputProps = {{maxLength:255}}
          />
          { errors?.label && <Typography color="error" variant='span'>{errors.label.message}</Typography> }
          <InputField
            id='type'
            name='type'
            label='Tipo do campo'
            error={errors?.type}
            required
            fullWidth
            select
          >
            {components.map((opt) => (
              <MenuItem key={opt.label} value={opt.value}>
                {opt.label}
              </MenuItem>
            ))}
          </InputField>
          { errors?.type && <Typography color="error" variant='span'>{errors.type.message}</Typography> }
        </Dialog>
      </FormProvider>
    </>
  )
}

const useStyles = makeStyles(() => ({
  button: {
    display: 'flex',
    margin: '20px auto',
  },
}))

AddField.propTypes = {
  isEmpty: PropTypes.bool.isRequired,
  order: PropTypes.number.isRequired,
}

AddField.defaultProps = {}

export default AddField
