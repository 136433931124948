import { createContext, useContext, useReducer } from 'react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { createDevolutive, fetchDevolutives, updateDevolutive, removeDevolutive } from 'api/services'

const ACTION_TYPES = {
  LIST: 'LIST_DEVOLUTIVE',
  ADD: 'ADD_DEVOLUTIVE',
  REMOVE: 'REMOVE_DEVOLUTIVE',
  UPDATE: 'UPDATE_DEVOLUTIVE',
}

const devolutivesReducer = (state, action) => {
  switch (action.type) {
    case ACTION_TYPES.LIST: {
      return { devolutives: action.data }
    }
    case ACTION_TYPES.ADD: {
      return { devolutives: [...state.devolutives, action.data] }
    }
    case ACTION_TYPES.REMOVE: {
      return { devolutives: state.devolutives.filter(({ id }) => id !== action.id) }
    }
    case ACTION_TYPES.UPDATE: {
      return {
        devolutives: state.devolutives.map((item) =>
          item.id === action.data.id ? { ...item, ...action.data } : item
        ),
      }
    }
    case ACTION_TYPES.SELECT: {
      return state
    }
    default: {
      return state
    }
  }
}

const DevolutivesStateContext = createContext()
const DevolutivesDispatchContext = createContext()

const DevolutivesProvider = ({ children }) => {
  const { projectId } = useParams()
  const [state, dispatch] = useReducer(devolutivesReducer, { devolutives: [] })

  const { isLoading } = useQuery(['devolutives', projectId], () => fetchDevolutives(projectId), {
    onSuccess: (data) => {
      dispatch({ type: ACTION_TYPES.LIST, data: data.data })
    },
  })

  return (
    <DevolutivesStateContext.Provider value={{ isLoading, devolutives: state.devolutives }}>
      <DevolutivesDispatchContext.Provider value={dispatch}>{children}</DevolutivesDispatchContext.Provider>
    </DevolutivesStateContext.Provider>
  )
}

DevolutivesProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

const useDevolutives = (projectId) => {
  const context = useContext(DevolutivesStateContext)

  if (!context) {
    throw new Error('useDevolutives must be used within an DevolutivesProvider')
  }

  if (projectId) {
    const { devolutives } = context
    return {
      ...context,
      devolutives: devolutives.filter((item) => item.project_id === projectId),
    }
  }

  return context
}

const useDispatch = () => {
  const dispatch = useContext(DevolutivesDispatchContext)
  if (dispatch === undefined) {
    throw new Error('useDispatch must be used within a DevolutivesProvider')
  }
  return dispatch
}

const useUpdateDevolutive = (id, options = {}) => {
  const dispatch = useDispatch()
  
  return useMutation((data) => updateDevolutive(id, data), {
    ...options,
    onSuccess: (data) => {
      dispatch({ type: ACTION_TYPES.UPDATE, data })
    },
  })
}

const useRemoveDevolutive = (id, options = {}) => {
  const dispatch = useDispatch()

  return useMutation((id) => removeDevolutive(id), {
    ...options,
    onSuccess: () => dispatch({ type: ACTION_TYPES.REMOVE, id }),
  })
}

const useUpdateTitleDevolutive = (id, options = {}) => {
  const dispatch = useDispatch()

  return useMutation(data => updateDevolutive(id, data), {
    ...options,
    onSuccess: (data) => {
      dispatch({ type: ACTION_TYPES.UPDATE, data })
    },
  })
}

const useCreateDevolutive = (projectId, options = {}) => {
  const queryClient = useQueryClient()

  const dispatch = useDispatch()
  const { onSuccess } = options

  return useMutation((data) => createDevolutive(projectId, data), {
    ...options,
    onSuccess: (data) => {
      onSuccess(data)
      dispatch({ type: ACTION_TYPES.ADD, data })
      queryClient.invalidateQueries('devolutives')
    },
  })
}

const useUpdateDevolutiveTemplate = () => {
  // dispatch({ type: ACTION_TYPES.UPDATE, data })
}

export {
  DevolutivesProvider,
  useDevolutives,
  useUpdateDevolutive,
  useUpdateDevolutiveTemplate,
  useRemoveDevolutive,
  useUpdateTitleDevolutive,
  useCreateDevolutive,
}
