import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import { Link, useRouteMatch } from 'react-router-dom'
import {
  IconButton,
  Drawer as MUIDrawer,
  Divider,
  ListItem,
  ListItemText,
  Button,
} from '@material-ui/core'
import {
  ChevronLeft as ChevronLeftIcon,
  AddCircleOutlined as AddCircleOutlinedIcon,
  DragIndicator as DragIndicatorIcon,
} from '@material-ui/icons'
import { levelUp } from 'functions/levelUp'

import { DraggableList } from 'components'

const Drawer = ({ open, handleDrawer, items, onAdd, children, onReorder, onClick }) => {
  const classes = useStyles()
  const { url } = useRouteMatch()

  return (
    <MUIDrawer anchor='left' open={open} onClose={handleDrawer}>
      <div className={classes.wrapper}>
        <div className={classes.button}>
          <Button
            id='back-button'
            startIcon={<ChevronLeftIcon />}
            component={Link}
            to={levelUp(url)}
            color='primary'
          >
            Formulários
          </Button>
        </div>

        <Divider />

        <DraggableList items={items} onReorder={onReorder}>
          {({ ref, item, snapshotProps, ...rest }) => (
            <ListItem
              button={!!onClick}
              onClick={() => onClick(item)}
              ref={ref}
              {...rest}
              className={clsx({
                [classes.dragging]: snapshotProps.isDragging,
              })}
            >
              {onReorder && (
                <IconButton className={classes.draggable} edge='start' aria-label='draggable'>
                  <DragIndicatorIcon />
                </IconButton>
              )}

              <ListItemText className={classes.item}>{children(item)}</ListItemText>
            </ListItem>
          )}
        </DraggableList>

        {onAdd && (
          <>
            <Divider />
            <div className={classes.button}>
              <Button
                fullWidth
                color='primary'
                variant='contained'
                onClick={onAdd}
                startIcon={<AddCircleOutlinedIcon />}
              >
                Adicionar
              </Button>
            </div>
          </>
        )}
      </div>
    </MUIDrawer>
  )
}

const useStyles = makeStyles(() => ({
  wrapper: {
    minWidth: '300px',
    maxWidth: '500px',
  },
  button: {
    padding: '10px 20px',
  },
  item: {
    marginRight: '10px',
    cursor: 'pointer',
  },
  draggable: {
    cursor: 'move',
  },
  dragging: {
    background: 'rgb(235,235,235)',
    boxShadow:
      '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
  },
}))

Drawer.propTypes = {
  children: PropTypes.func.isRequired,
  handleDrawer: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired,
  onAdd: PropTypes.func,
  onClick: PropTypes.func,
  onReorder: PropTypes.func,
  open: PropTypes.bool,
}

Drawer.defaultProps = {
  onAdd: null,
  onClick: () => {},
  onReorder: null,
  open: false,
}

export default Drawer
