import { createContext, useContext, useReducer } from 'react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { isEmpty, get } from 'lodash'
import { fetchFields, updateField, createField, removeField } from 'api/services'
import { typeText } from 'enum/form-field'
import { useForm } from 'hooks/formsDisplay'

const ACTION_TYPES = {
  LIST: 'LIST_FIELD',
  ADD: 'ADD_FIELD',
  REMOVE: 'REMOVE_FIELD',
  UPDATE: 'UPDATE_FIELD',
  REORDER: 'REORDER_FIELDS',
  SELECT: 'SELECT_FIELD',
  UPDATE_TEXT: 'UPDATE_TEXT',
}

const fieldsReducer = (state, action) => {
  switch (action.type) {
    case ACTION_TYPES.LIST: {
      return { fields: action.data }
    }
    case ACTION_TYPES.ADD: {
      return { fields: [...state.fields, action.data] }
    }
    case ACTION_TYPES.REMOVE: {
      return {
        fields: state.fields.filter(({ id }) => id !== action.id),
      }
    }
    case ACTION_TYPES.UPDATE: {
      return {
        fields: state.fields.map((item) =>
          item.id === action.data.id ? { ...item, ...action.data } : item
        ),
      }
    }
    case ACTION_TYPES.UPDATE_TEXT: {
      if (action.id) {
        return {
          fields: state.fields.map((item) =>
            item.id === action.data.id ? { ...item, ...action.data } : item
          ),
        }
      }

      return { fields: [...state.fields, action.data] }
    }
    case ACTION_TYPES.SELECT: {
      return state
    }
    case ACTION_TYPES.REORDER: {
      return {
        isLoading: true,
        ...state,
      }
      // const { source, destination } = action.data

      // const result = Array.from(state.fields)
      // const [removed] = result.splice(source, 1)
      // result.splice(destination, 0, removed)

      // return {
      //   fields: result.map((item, index) => ({
      //     ...item,
      //     order: index,
      //   })),
      // }
    }
    default: {
      return state
    }
  }
}

const FieldsStateContext = createContext()
const FieldsDispatchContext = createContext()

const FieldsProvider = ({ children }) => {
  const { formId } = useParams()
  const [state, dispatch] = useReducer(fieldsReducer, { fields: [] })
  let displayForm
  try {
    displayForm = useForm()
  } catch {
    displayForm = null
  }

  if (
    !!displayForm &&
    (get(displayForm, 'isLoading', false) || !!get(displayForm, ['selectedForm', 'builder'], false))
  ) {
    const data = []
    get(displayForm, ['selectedForm', 'builder', 'steps'], []).map((step) => {
      get(step, 'fields', []).map((field) => data.push(field))
    })

    return (
      <FieldsStateContext.Provider
        value={{ isLoading: get(displayForm, 'isLoading', false), fields: data }}
      >
        <FieldsDispatchContext.Provider value={dispatch}>{children}</FieldsDispatchContext.Provider>
      </FieldsStateContext.Provider>
    )
  }
  const { isLoading } = useQuery(['fields', formId], () => fetchFields(formId), {
    onSuccess: (data) => {
      dispatch({ type: ACTION_TYPES.LIST, data })
    },
  })

  return (
    <FieldsStateContext.Provider value={{ isLoading, fields: state.fields }}>
      <FieldsDispatchContext.Provider value={dispatch}>{children}</FieldsDispatchContext.Provider>
    </FieldsStateContext.Provider>
  )
}

FieldsProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

const useFields = (stepId, all) => {
  const context = useContext(FieldsStateContext)

  if (!context) {
    throw new Error('useFields must be used within an FieldsProvider')
  }

  if (stepId) {
    const { fields } = context

    if (all) {
      return {
        ...context,
        fields: fields
          .filter((item) => item.step_id === stepId)
          .sort((a, b) => {
            return a.order > b.order ? 1 : -1
          }),
      }
    }
    return {
      ...context,
      fields: fields
        .filter((item) => item.step_id === stepId && item.type !== typeText)
        .sort((a, b) => {
          return a.order > b.order ? 1 : -1
        }),
    }
  }

  return context
}

const useTexts = (stepId) => {
  const context = useContext(FieldsStateContext)

  if (!context) {
    throw new Error('useTexts must be used within an FieldsProvider')
  }

  if (stepId) {
    const { fields } = context

    return {
      ...context,
      text: fields.find((item) => item.step_id === stepId && item.type === typeText),
    }
  }

  return context
}

const useDispatch = () => {
  const dispatch = useContext(FieldsDispatchContext)
  if (dispatch === undefined) {
    throw new Error('useDispatch must be used within a FieldsProvider')
  }
  return dispatch
}

const useUpdateField = (id, stepId, options = {}) => {
  const dispatch = useDispatch()
  const { onSuccess, onError } = options

  return useMutation((data) => updateField(stepId, id, data), {
    ...options,
    onSuccess: (data) => {
      onSuccess(data), dispatch({ type: ACTION_TYPES.UPDATE, data })
    },
    onError: (data) => {
      const errors = data.response.data.errors
      const errorList = []
      Object.keys(errors).forEach((formKey) => errorList.push(`${formKey} - ${errors[formKey]}`))
      onError(errorList)
    },
  })
}

const useUpdateText = (id, stepId, options = {}) => {
  const { onSuccess } = options
  const dispatch = useDispatch()

  return useMutation(
    (data) => (isEmpty(id) ? createField(stepId, data) : updateField(stepId, id, data)),
    {
      ...options,
      onSuccess: (data) => {
        dispatch({ type: ACTION_TYPES.UPDATE_TEXT, data, id })
        if (onSuccess) {
          onSuccess(data)
        }
      },
    }
  )
}

const useUpdateTitleField = (id, stepId, options = {}) => {
  const dispatch = useDispatch()

  return useMutation((data) => updateField(stepId, id, data), {
    ...options,
    onSuccess: (data) => {
      dispatch({ type: ACTION_TYPES.UPDATE, data })
    },
  })
}

const useRemoveField = (id, stepId, options = {}) => {
  const dispatch = useDispatch()

  return useMutation(() => removeField(stepId, id), {
    ...options,
    onSuccess: () => dispatch({ type: ACTION_TYPES.REMOVE, id }),
  })
}

const useCreateField = (stepId, options = {}) => {
  const dispatch = useDispatch()
  const { onSuccess } = options

  return useMutation((data) => createField(stepId, data), {
    ...options,
    onSuccess: (data) => {
      if (onSuccess) {
        onSuccess(data)
      }
      dispatch({ type: ACTION_TYPES.ADD, data })
    },
  })
}

const useReorderFields = (stepId, options = {}) => {
  const queryClient = useQueryClient()
  const dispatch = useDispatch()

  return useMutation(({ id, data }) => updateField(stepId, id, data), {
    ...options,
    onSuccess: () => {
      dispatch({ type: ACTION_TYPES.REORDER })
      queryClient.invalidateQueries('fields')
    },
  })
}

export {
  FieldsProvider,
  useFields,
  useUpdateField,
  useUpdateTitleField,
  useCreateField,
  useRemoveField,
  useReorderFields,
  useTexts,
  useUpdateText,
}
