import queryString from 'query-string'
import api from 'api'

export const fetchForms = async (projectId, queryParams, cursor) => {
  const { data } = await api.get(
    `/project/${projectId}/form?${queryString.stringify({
      ...queryParams,
      ...cursor,
    })}`
  )

  return data
}
