import PropTypes from 'prop-types'
import { AttributesProvider as Provider } from './customerAttributes'

const CustomerAttributesProvider = ({ children }) => {
  return (
    <Provider>{children}</Provider>
  )
}

CustomerAttributesProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default CustomerAttributesProvider
