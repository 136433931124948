import { useState } from 'react'
import { useInfiniteQuery } from 'react-query'
import { fetchForms } from 'api/services'
import { DEFAULT_PARAMS } from 'enum/infiniteQuery'

export const useForms = (projectId, initialFetchInfo = {}) => {
  const [fetchInfo, setFetchInfo] = useState({
    ...DEFAULT_PARAMS,
    ...initialFetchInfo,
  })

  return {
    ...useInfiniteQuery(
      ['forms', fetchInfo],
      ({ pageParam }) => fetchForms(projectId, fetchInfo, pageParam),
      {
        getNextPageParam: ({current_page, last_page}) => {
          if (current_page < last_page) {
            return { page: current_page + 1 }
          }

          return false
        }
      }
    ),
    fetchInfo,
    setFetchInfo,
  }
}
