import PropTypes from 'prop-types'

import { Container } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import { Header, Footer } from 'components'
import LockDrag from 'components/lock-drag/LockDrag'

const MainLayout = ({ children }) => {
  const classes = useStyles()
  return (
    <>
      <Header />
      <LockDrag />
      <Container maxWidth={false} className={classes.root}>
        {children}
      </Container>
      <Footer />
    </>
  )
}

const useStyles = makeStyles(() => ({
  root: {
    padding: 0,
  },
}))

MainLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default MainLayout
