import PropTypes from 'prop-types'
import { ResultsProvider } from './results'
import { MetricsProvider } from './metrics'

const ResultProvider = ({ children }) => {
  return (
    <MetricsProvider>
      <ResultsProvider>{children}</ResultsProvider>
    </MetricsProvider>
  )
}

ResultProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default ResultProvider
