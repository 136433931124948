import faker from 'faker'

export const auth = ({ email }) => ({
  email,
  name: faker.name.findName(),
  gender: faker.datatype.boolean(),
  id: faker.datatype.number().toString(),
})

export const fields = () => [
  {
    stepId: '1',
    order: 0,
    title: 'Altura',
    id: 'altura',
    component: 'input',
    type: 'text',
    label: 'Você ja teve alguma  \n doença\n nos olhos?',
    name: 'altura',
    defaultValue: '',
    mask: '9.99',
  },
  {
    id: 'sexo',
    title: 'Sexo',
    component: 'select',
    label: 'Sexo *',
    stepId: '1',
    order: 1,
    options: [
      {
        label: 'Não quero informar',
        value: 'sem-informacao',
      },
      {
        label: 'Masculino',
        value: 'masculino',
      },
      {
        label: 'Feminina',
        value: 'feminina',
      },
    ],
    defaultValue: '',
    type: null,
    mask: null,
    name: 'sexo',
  },

  {
    id: 'nivel-saude',
    title: 'Nivel da saúde',
    name: 'nivel-saude',
    component: 'radio',
    order: 2,
    stepId: '1',
    defaultValue: '',
    label: 'Em geral, você diria que sua saúde é:',
    options: [
      {
        label: 'Excelente',
        value: 'excelente',
      },
      {
        label: 'Muito Boa',
        value: 'muito-boa',
      },
      {
        label: 'Boa',
        value: 'boa',
      },
      {
        label: 'Média',
        value: 'media',
      },
      {
        label: 'Ruim',
        value: 'ruim',
      },
    ],
  },
  {
    id: 'doenca-olhos',
    name: 'doenca-olhos',
    title: 'Doença nos olhos',
    defaultValue: ['catarata'],
    order: 3,
    stepId: '1',
    component: 'checkbox',
    label: 'Você é ou já foi portador de alguma doença dos olhos?',
    options: [
      {
        label: 'Catarata',
        value: 'catarata',
      },
      {
        label: 'Doença da retina',
        value: 'doenca-retina',
      },
      {
        label: 'Ceratocone',
        value: 'ceratocone',
      },
      {
        label: 'Outros',
        value: 'outros',
      },
      {
        label: 'Não possuo nenhuma doença',
        value: 'nenhuma',
      },
    ],
  },
  {
    stepId: '2',
    order: 0,
    id: '1-olhos',
    name: '1-olhos',
    title: 'Doença maluca',
    defaultValue: ['catarata'],
    component: 'checkbox',
    label: 'Você é ou já foi portador de alguma doença dos olhos?',
    options: [
      {
        label: 'Catarata',
        value: 'catarata',
      },
      {
        label: 'Doença da retina',
        value: 'doenca-retina',
      },
      {
        label: 'Ceratocone',
        value: 'ceratocone',
      },
      {
        label: 'Outros',
        value: 'outros',
      },
      {
        label: 'Não possuo nenhuma doença',
        value: 'nenhuma',
      },
    ],
  },
  {
    stepId: '2',
    order: 1,
    id: '1dxoenca-olhos',
    name: '1dxoenca-olhos',
    title: 'Doença maluca',
    defaultValue: ['catarata'],
    component: 'checkbox',
    label: 'Você é ou já foi portador de alguma doença dos olhos?',
    options: [
      {
        label: 'Catarata',
        value: 'catarata',
      },
      {
        label: 'Doença da retina',
        value: 'doenca-retina',
      },
      {
        label: 'Ceratocone',
        value: 'ceratocone',
      },
      {
        label: 'Outros',
        value: 'outros',
      },
      {
        label: 'Não possuo nenhuma doença',
        value: 'nenhuma',
      },
    ],
  },
]

export const steps = () => [
  { id: '1', title: 'step1', order: 0 },
  { id: '2', title: 'step2', order: 1 },
]

export const createStep = ({ title, order }) => ({
  id: faker.datatype.number().toString(),
  title,
  order,
})

export const createField = ({ title, stepId, order, name, ...rest }) => {
  const id = faker.datatype.number().toString()
  return {
    id,
    title,
    stepId,
    order,
    name: `${name}-${id}`,
    options: [],
    defaultValue: '',
    ...rest,
  }
}

export const metric = () => [
  {
    id: faker.datatype.number().toString(),
    title: 'Sedentarismo',
  },
  {
    id: faker.datatype.number().toString(),
    title: 'Obesidade',
  },
  {
    id: faker.datatype.number().toString(),
    title: 'Diabetes',
  },
]

export const texts = () => [
  {
    id: '10062',
    stepId: '1',
    data: '<p>aaaaaaaa</p>',
  },
  {
    id: '7895612',
    stepId: '2',
    data: '<p>stepId2 bbbbbbbbbb</p>',
  },
]

export const form = () => []

// {
//   meta: {
//     pagination:{
//       hasNextPage: false,
//       nextCursor: null
//     },
//   },
//   data:

export const projects = {
  meta: {
    pagination: {
      hasNextPage: false,
      nextCursor: null,
    },
  },
  data: [
    {
      id: '1',
      name: 'Projeto test',
      type: 'step-by-step',
      updatedAt: new Date(),
      createdAt: new Date(),
    },
    {
      id: '2',
      name: 'Projeto test 4',
      type: 'step-by-step',
      updatedAt: new Date(),
      createdAt: new Date(),
    },
    {
      id: '3',
      name: 'Projeto test 3',
      type: 'step-by-step',
      updatedAt: new Date(),
      createdAt: new Date(),
    },
    {
      id: '4',
      name: '4Projeto test 3',
      type: 'step-by-step',
      updatedAt: new Date(),
      createdAt: new Date(),
    },
    {
      id: '5',
      name: 'P5rojeto test 3',
      type: 'step-by-step',
      updatedAt: new Date(),
      createdAt: new Date(),
    },
    {
      id: '6',
      name: 'Projeto test 6',
      type: 'step-by-step',
      updatedAt: new Date(),
      createdAt: new Date(),
    },
    {
      id: '7',
      name: 'Projeto test 7',
      type: 'step-by-step',
      updatedAt: new Date(),
      createdAt: new Date(),
    },
    {
      id: '8',
      name: 'Projeto test 8',
      type: 'step-by-step',
      updatedAt: new Date(),
      createdAt: new Date(),
    },
    {
      id: '9',
      name: 'Projeto test 9',
      type: 'step-by-step',
      updatedAt: new Date(),
      createdAt: new Date(),
    },
  ],
}

export const users = {
  meta: {
    pagination: {
      hasNextPage: false,
      nextCursor: null,
    },
  },
  data: [
    {
      id: '1',
      name: 'Xesquedele',
      email: 'email@xqdl.com',
      createdAt: new Date(),
    },
    {
      id: '2',
      name: 'Birutinha',
      email: 'email@email.com',
      createdAt: new Date(),
    },
  ],
}