import PropTypes from 'prop-types'
import { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { IconButton, Typography } from '@material-ui/core'
import { Message } from '@material-ui/icons'
import { useUpdateForm } from 'hooks/useUpdateForm'
import { Dialog, InputField } from 'components'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

const schema = yup.object().shape({
  title: yup.string().required('Esse campo é obrigatório').min(4, 'O título deve ter no mínimo 4 caracters').max(255, 'O título deve ter no máximo 255 caracteres'),
  tag: yup.string().required('Esse campo é obrigatório').matches(/^[^0-9][\w&._]+$/, "A tag não pode começar com números e não pode possuir caracteres especiais ou espaços").max(255, 'A tag deve ter no máximo 255 caracteres'),
})

const UpdateForm = ({id, title, tag}) => {
  const [open, setOpenDialog] = useState(false)

  const methods = useForm({
    mode: 'onTouched',
    resolver: yupResolver(schema),
    defaultValues: {
      title: title ? title : '',
      tag: tag ? tag : '',
    },
  })

  const {
    handleSubmit,
    formState: { isValid, isDirty, errors },
  } = methods

  const { mutate, isLoading } = useUpdateForm(id, {
    onSuccess: () => setOpenDialog(false),
    onError: () => console.log('Erro')
  })

  return (
    <>
      <IconButton size='small' edge='end' color='primary' aria-label='edit'>
        <Message 
          onClick={() => setOpenDialog(true)}
          variant='contained'
        />
      </IconButton>
      <FormProvider {...methods}>
        <Dialog
          title={`Alterar "${title}"`}
          open={open}
          onCancel={() => setOpenDialog(false)}
          isLoading={isLoading}
          submitDisabled={!isValid || !isDirty}
          onSubmit={handleSubmit((data) => mutate(data))}
        >
          <InputField
            id='title'
            name='title'
            label='Nome do Formulário'
            variant='outlined'
            error={errors?.title}
            required
            fullWidth
            InputProps = {{ maxLength:255 }}
          />
          { errors?.title && <Typography color="error" variant='span'>{errors.title.message}</Typography> }
          <InputField
            id='tag'
            name='tag'
            label='Tag do Formulário'
            variant='outlined'
            error={errors?.tag}
            required
            fullWidth
            InputProps = {{ maxLength:255 }}
          />
          { errors?.tag && <Typography color="error" variant='span'>{errors.tag.message}</Typography> }
          {/* <InputField
            id='type'
            name='type'
            required
            label='Escolha o template do formulário'
            fullWidth
            select
          >
            {templates.map((opt) => (
              <MenuItem key={opt.label} value={opt.value}>
                {opt.label}
              </MenuItem>
            ))}
          </InputField> */}
        </Dialog>
      </FormProvider>
    </>
  )
}

UpdateForm.propTypes = {
  id: PropTypes.string.isRequired,
  tag: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

UpdateForm.defaultProps = {}

export default UpdateForm
