import PropTypes from 'prop-types'
import { AccordionSummary, IconButton, Typography } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { makeStyles } from '@material-ui/core/styles'
import { EditableField } from 'components'

const Summary = ({ item, onSave, isLoading }) => {
  const classes = useStyles()
  
  return (
    <AccordionSummary
      expandIcon={
        <IconButton>
          <ExpandMoreIcon />
        </IconButton>
      }
      aria-controls={`${item.id}-content`}
      id={item.id}
    >
      {onSave ? (
        <EditableField 
          fullWidth 
          id={item.id}
          isLoading={isLoading}
          defaultValue={item.title || item.name}
          onSave={(title, options) => onSave({ ...item, title: title.value, name: title.value }, options)}
        />
      ) : (
        <div className={classes.content}>
          <Typography className={classes.heading}>{item.title || item.name}</Typography>
        </div>
      )}
    </AccordionSummary>
  )
}

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: ({ fullWidth }) => (fullWidth ? 'space-between' : 'flex-start'),
  },
}))

Summary.propTypes = {
  isLoading: PropTypes.bool,
  item: PropTypes.object.isRequired,
  onSave: PropTypes.func,
}

Summary.defaultProps = {
  isLoading: false,
  onSave: null,
}

export default Summary
