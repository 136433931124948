import PropTypes from 'prop-types'
import { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { Tooltip, IconButton, Typography } from '@material-ui/core'
import { Message } from '@material-ui/icons'
import { yupResolver } from '@hookform/resolvers/yup'
import { useUpdateProject } from 'hooks/useUpdateProject'
import { Dialog, InputField } from 'components'
import * as yup from 'yup'

const schema = yup.object().shape({
  title: yup.string().required('Esse campo é obrigatório').max(255, 'O nome deve ter no máximo 255 caracteres').min(4, 'O nome deve ter no mínimo 4 caracteres'),
})

const UpdateProject = ({id, title}) => {
  const [open, setOpenDialog] = useState(false)

  const methods = useForm({
    mode: 'onTouched',
    resolver: yupResolver(schema),
    defaultValues: {
      title: title ? title : null,
    },
  })
  const {
    handleSubmit,
    formState: { isValid, isDirty, errors },
  } = methods

  const { mutate, isLoading } = useUpdateProject(id, {
    onSuccess: () => setOpenDialog(false),
  })

  return (
    <>
      <Tooltip title="Renomear projeto">
        <IconButton size='small' edge='end' color='primary' aria-label='edit'>
          <Message 
            onClick={() => setOpenDialog(true)}
            variant='contained'
          />
        </IconButton>
      </Tooltip>
      <FormProvider {...methods}>
        <Dialog
          title={`Renomear ${title}`}
          contentText='Escolha um novo nome para o projeto. Você sempre pode alterá-lo depois.'
          open={open}
          onCancel={() => setOpenDialog(false)}
          isLoading={isLoading}
          submitDisabled={!isValid || !isDirty}
          onSubmit={handleSubmit((data) => mutate(data))}
        >
          <InputField
            id='title'
            name='title'
            label='Nome do projeto'
            variant='outlined'
            error = { errors?.title }
            required
            fullWidth
          />
          { errors.title && <Typography color="error" variant='span'>{errors.title.message}</Typography> }
        </Dialog>
      </FormProvider>
    </>
  )
}

UpdateProject.propTypes = {
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
}

UpdateProject.defaultProps = {}

export default UpdateProject
