import { createContext, useReducer, useContext, useState } from "react";
import { useMutation, useQuery } from "react-query";

import { fetchFormDisplay } from 'api/services/fetchFormDisplay';
import { postEvaluation, saveEvaluation } from "api/services/postEvaluation";

import { useParams } from "react-router-dom";
import { get, isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import { makeStyles } from "@material-ui/styles";

const ACTION_TYPES = {
	LIST: 'LIST_FORM',
	SELECT: 'SELECT_FORM',
}

const formReducer = (state, action) => {
	switch (action.type) {
		case ACTION_TYPES.LIST: {
			return {
				...state,
				form: action.data
			}
		}
		case ACTION_TYPES.SELECT: {
			return {
				...state,
				selected: action.formId,
				selectedForm: state.form
			}
		}
		default: {
			return state
		}
	}
}

const FormsDisplayStateContext = createContext();
const FormsDisplayDispatchContext = createContext();

const FormsDisplayProvider = ({ children }) => {

	const { formId } = useParams()
	const [error, setError] = useState(null);
	const [state, dispatch] = useReducer(formReducer, { form: [], selected: null, selectedForm: {}, complementForm: { last_published: null } })

	const { isLoading } = useQuery(['form', formId], () => fetchFormDisplay(formId), {
		select: (data) => get(data, 'data', []),
		onSuccess: (data) => {
			if (isEmpty(data)) {
				return dispatch({ type: ACTION_TYPES.LIST, data })
			}

			dispatch({ type: ACTION_TYPES.LIST, data })
			dispatch({ type: ACTION_TYPES.SELECT, formId })
		},
		onError: (err) => {
			setError(err.message)
		}
	})


	const classes = useStyles();

	if (error) 
		return (
			<div className={classes.container}>
				O Formulário que você esta tentando acessar já foi preenchido.
			</div>
		)

	return (
		<FormsDisplayStateContext.Provider value={{ isLoading, error, ...state }}>
			<FormsDisplayDispatchContext.Provider value={dispatch}>{children}</FormsDisplayDispatchContext.Provider>
		</FormsDisplayStateContext.Provider>
	)
}

const useStyles = makeStyles(() => ({
	container: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		margin: '0 auto',
		fontWeight: 'bold'
	},
	text: {
		textAlign: 'center'
	},
}))

FormsDisplayProvider.propTypes = {
	children: PropTypes.node.isRequired,
}

const useForm = () => {
	const context = useContext(FormsDisplayStateContext)

	if (!context) {
		throw new Error('useForm must be used within an FormsDisplayProvider')
	}

	return context
}

const useDispatch = () => {
	const dispatch = useContext(FormsDisplayDispatchContext)
	if (dispatch === undefined) {
		throw new Error('useDispatch must be used within a FormsProvider')
	}

	return dispatch
}

const useCreateEvaluation = (options = {}) => {
	const { onSuccess } = options

	return useMutation((data) => postEvaluation(data), {
		...options,
		onSuccess: (data) => {
			if (onSuccess)
				onSuccess(data)
		},
	})
}

const useSaveEvaluation = (options = {}) => {
	const { onSuccess } = options

	return useMutation((data) => saveEvaluation(data), {
		...options,
		onSuccess: (data) => {
			if (onSuccess)
				onSuccess(data)
		},
	})
}

export {
	FormsDisplayProvider,
	useForm,
	useDispatch,
	useCreateEvaluation,
	useSaveEvaluation
}
