import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useParams } from 'react-router-dom'
import { FormProvider, useForm } from 'react-hook-form'
import { Typography, MenuItem, Paper, Box, Button } from '@material-ui/core'
import { usePreferences, useCreatePreference, useUpdatePreference } from 'hooks/preferences'
import { Loader, InputField } from 'components'
import { fonts } from 'enum/fonts'
import { yupResolver } from '@hookform/resolvers/yup'
import brandings from '../../../enum/brandings';
import * as yup from 'yup'

const schema = yup.object().shape({
  color_theme: yup.string().required('Esse campo é obrigatório').matches(/^#([0-9a-f]{3}|[0-9a-f]{6})$/i, { excludeEmptyString: true, message: 'Insira um código hexadecimal válido'}),
  color_theme_dark: yup.string().required('Esse campo é obrigatório').matches(/^#([0-9a-f]{3}|[0-9a-f]{6})$/i, { excludeEmptyString: true, message: 'Insira um código hexadecimal válido'}),
  color_background_theme_dark: yup.string().required('Esse campo é obrigatório').matches(/^#([0-9a-f]{3}|[0-9a-f]{6})$/i, { excludeEmptyString: true, message: 'Insira um código hexadecimal válido'}),
  font_family: yup.string(),
  font_size: yup.number('Insira um número válido').required('Esse campo é obrigatório').positive('Por favor, insira um número positivo').min(10, 'Escolha um tamanho de, no mínimo, 10px').max(42, 'Escolha um tamanho até 42px'),
  border_radius: yup.number('Insira um número válido').required('Esse campo é obrigatório'),
})

const ThemeForm = () => {
  const { projectId } = useParams();
  const { isFetching: isLoading, preferences } = usePreferences(projectId)
  const classes = useStyles()
  
  const methods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
  })

  const {
    handleSubmit,
    setError,
    clearErrors,
    trigger,
    setValue,
    getValues,
    formState: { errors, isValid },
  } = methods

  const { mutate: onCreate, isLoading: isLoadingCreate } = useCreatePreference(projectId, {
    onSuccess: () => { },
    onError: () => {
      setError('general', { message: 'Configurações inválidas' })
    }

  })

  const { mutate: onUpdate, isLoading: isLoadingUpdate } = useUpdatePreference(projectId, {
    onSuccess: () => { },
    onError: () => {
      setError('general', { message: 'Configurações inválidas' })
    }
  })
  
  if (isLoading || isLoadingCreate || isLoadingUpdate) {
    return (
      <Paper elevation={3} className={classes.paper}>
        <Box justifyContent='space-between' alignItems='center' height={"100%"}>
          <Loader minHeight='100%' size={100} />
        </Box>
      </Paper>
    )
  }
  
  const changeBranding = (e) => {
    clearErrors();

    let currentBranding = brandings[e.target.value] || {};
    
    for (let attribute of Object.keys(currentBranding)) {
      setValue(attribute, currentBranding[attribute]);
    }
    
    setValue('branding_style', e.target.value);
    trigger();
  }

  const changeField = (e) => {
    clearErrors();

    let newValue = e.target.value

    if (getValues('branding_style') != 'custom')
      setValue('branding_style', 'custom');

    if (e.target.type == "number" && (e.target.value < 0 || isNaN(parseInt(e.target.value)))) newValue = 0
    
    setValue(e.target.name, newValue);
    trigger();
  }

  const toFixed = (str) => {
    return str[0].toUpperCase() + str.replace(/^./, '').replace("_", " ");
  }

  return (
    <Paper elevation={3} className={classes.paper}>
      <Box justifyContent='space-between'>
        {
          isLoadingCreate || isLoadingUpdate ? <Loader size={100} />
            :
            <FormProvider {...methods} >
              <form
                autoComplete='off'
                onSubmit={handleSubmit((data) => onUpdate(data))}
              >
                <InputField
                  id='branding_style'
                  name='branding_style'
                  label='Branding'
                  variant='standard'
                  defaultValue={preferences && preferences.branding_style || 'custom'}
                  onChange={changeBranding}
                  select
                  fullWidth
                >
                  <MenuItem key={null} value='custom'>
                    &nbsp;
                  </MenuItem>
                  {Object.keys(brandings).map((branding) => {
                    return (
                      <MenuItem key={branding} value={branding}>
                        {toFixed(branding)}
                      </MenuItem>
                    )
                  })}
                </InputField>
                <InputField
                  id='color_theme'
                  name='color_theme'
                  label='Cor principal'
                  helperText={"Exemplo: #FAFAFA"}
                  variant='standard'
                  defaultValue={preferences && preferences.color_theme}
                  onChange={changeField}
                  error={errors?.color_theme}
                  required
                  fullWidth
                />
                  {errors?.color_theme && <Typography color="error" variant="span">{errors.color_theme.message}</Typography>}
                <InputField
                    id='color_theme'
                    name='color_theme_dark'
                    label='Cor tema escuro'
                    helperText={"Exemplo: #FAFAFA"}
                    variant='standard'
                    defaultValue={preferences && preferences.color_theme_dark}
                    onChange={changeField}
                    error={errors?.color_theme_dark}
                    required
                    fullWidth
                />
                {errors?.color_theme_dark && <Typography color="error" variant="span">{errors.color_theme_dark.message}</Typography>}
                <InputField
                    id='color_theme'
                    name='color_background_theme_dark'
                    label='Cor do fundo tema escuro'
                    helperText={"Exemplo: #FAFAFA"}
                    variant='standard'
                    defaultValue={preferences && preferences.color_background_theme_dark}
                    onChange={changeField}
                    error={errors?.color_background_theme_dark}
                    required
                    fullWidth
                />
                {errors?.color_background_theme_dark && <Typography color="error" variant="span">{errors.color_background_theme_dark.message}</Typography>}
                <InputField
                  id='font_family'
                  name='font_family'
                  label='Fonte de Texto'
                  variant='standard'
                  defaultValue={preferences && preferences.font_family || 'Arial, sans-serif'}
                  onChange={changeField}
                  error={errors?.font_family}
                  required
                  select
                  fullWidth
                >
                  {fonts.map((opt) => (
                    <MenuItem key={opt.label} value={opt.value}>
                      {opt.label}
                    </MenuItem>
                  ))}
                </InputField>
                {errors?.font_family && <Typography color="error" variant="span">{errors.font_family.message}</Typography>}
                <InputField
                  id='font_size'
                  name='font_size'
                  label='Tamanho da fonte em px'
                  variant='standard'
                  defaultValue={preferences && preferences.font_size || 12}
                  onChange={changeField}
                  error={errors?.font_size}
                  required
                  fullWidth
                  number
                />
                {errors?.font_size && <Typography color="error" variant="span">{errors.font_size.message}</Typography>}
                <InputField
                  id='border_radius'
                  name='border_radius'
                  label='Arredondamento das bordas em px'
                  variant='standard'
                  defaultValue={preferences && preferences.border_radius || 5}
                  onChange={changeField}
                  error={errors?.border_radius}
                  required
                  fullWidth
                  number
                />
                <InputField
                  id='text_alignment'
                  name='text_alignment'
                  label='Alinhamento do texto'
                  variant='standard'
                  defaultValue={preferences && preferences.text_alignment || 'justify'}
                  onChange={changeField}
                  error={errors?.text_alignment}
                  required
                  fullWidth
                  select
                >
                    <MenuItem key='justify' value='justify'>
                      Justificado
                    </MenuItem>
                    <MenuItem key='center' value='center'>
                      Centralizado
                    </MenuItem>
                    <MenuItem key='right' value='right'>
                      À direita
                    </MenuItem>
                    <MenuItem key='left' value='left'>
                      À esquerda
                    </MenuItem>
                </InputField>
                
                <InputField
                  id='columns'
                  name='columns'
                  label='Número de colunas'
                  variant='standard'
                  defaultValue={preferences && preferences.columns || '1'}
                  error={errors?.columns}
                  required
                  fullWidth
                  select
                >
                    <MenuItem key='1' value='1'>
                      1
                    </MenuItem>
                    <MenuItem key='2' value='2'>
                      2
                    </MenuItem>
                    <MenuItem key='3' value='3'>
                      3
                    </MenuItem>
                    <MenuItem key='4' value='4'>
                      4
                    </MenuItem>
                </InputField>

                {errors?.general && <Typography color="error" variant="span">{errors.general.message}</Typography>}
                
                <Button
                  variant='contained'
                  color='primary'
                  fullWidth
                  onClick={handleSubmit((data) => (preferences.id) ? onUpdate(data) : onCreate(data))}
                  disabled={!isValid || isLoadingCreate || isLoadingUpdate}
                >
                  Atualizar preferências
                </Button>

              </form>
            </FormProvider>
        }
      </Box>
    </Paper>
  )
}

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
  },
  previewWrapper: {
    marginTop: '30px'
  },
  textPreview: {
    padding: '20px 0'
  },
  paper: {
    margin: '20px 40px',
    padding: '20px',
    width: '20%'
  },
  item: {
    marginBottom: '20px',
  },
}))

ThemeForm.propTypes = {}

ThemeForm.defaultProps = {}

export default ThemeForm
