import { useState } from 'react'
import PropTypes from 'prop-types'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { IconButton, Tooltip } from '@material-ui/core'
import { Edit as EditIcon, } from '@material-ui/icons'
import { useUpdateUser } from 'hooks/useUpdateUser'
import { Dialog, InputField } from 'components'
import * as yup from 'yup'

const schema = yup.object().shape({
    name: yup.string().required(),
    email: yup.string().email().required()
  })

const UpdateUser = ({ userId, name, email }) => {
  const [open, setOpenDialog] = useState(false)

  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: {
      name: name,
      email: email,
    },
    resolver: yupResolver(schema),
  })
  const {
    handleSubmit,
    formState: { isValid, isDirty },
  } = methods

  const { mutate, isLoading } = useUpdateUser(userId, schema, {
    onSuccess: () => setOpenDialog(false),
  })

  return (
    <>
      <Tooltip title="Atualizar usuário">
            <IconButton color='primary' size='small' onClick={() => setOpenDialog(true)} edge='end' aria-label='token'>
                <EditIcon />
            </IconButton>
        </Tooltip>
      <FormProvider {...methods}>
        <Dialog
          title='Atualizar Usuário'
          contentText='Altera os dados do usuário.'
          open={open}
          onCancel={() => setOpenDialog(false)}
          isLoading={isLoading}
          submitDisabled={!isValid || !isDirty}
          onSubmit={handleSubmit((data) => mutate(data))}
        >
          <InputField
            id='name'
            name='name'
            label='Nome'
            variant='outlined'
            required
            fullWidth
          />
          <InputField
            id='email'
            name='email'
            label='E-mail'
            variant='outlined'
            required
            fullWidth
          />
        </Dialog>
      </FormProvider>
    </>
  )
}

UpdateUser.propTypes = {
  email: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
}

UpdateUser.defaultProps = {}

export default UpdateUser
