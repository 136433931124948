import { makeStyles } from '@material-ui/core/styles'
import { useParams } from 'react-router-dom'
import { isEmpty } from 'lodash'
import { useDevolutives } from 'hooks/devolutives'
import { useFetchProject } from 'hooks/useProject'
import { DraggableList, Loader, EmptyState } from 'components'

import Group from './group/Group'
import AddDevolutive from './add-devolutive/AddDevolutive'

const Devolutives = () => {
  const { projectId } = useParams();
  const { isLoading, devolutives } = useDevolutives(projectId)
  const { data, isLoading: isLoadingProject } = useFetchProject(projectId, { all: true })
  const classes = useStyles()

  if (isLoading || isLoadingProject) {
    return <Loader size={100}/>
  }

  if (isEmpty(devolutives)) {
    return (
      <>
        <EmptyState />
        <AddDevolutive isEmpty />
      </>
    )
  }

  return (
    <>
      <DraggableList items={devolutives}>
        {({ ref, item, snapshotProps, ...rest }) => (
          <div className={classes.item} ref={ref} {...rest}>
            <Group item={item} isDragging={snapshotProps.isDragging} project={data} />
          </div>
        )}
      </DraggableList>
      <AddDevolutive />
    </>
  )
}

const useStyles = makeStyles(() => ({
  item: {
    marginBottom: '20px',
  },
}))

Devolutives.propTypes = {}

Devolutives.defaultProps = {}

export default Devolutives
