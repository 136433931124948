import { useMutation, useQueryClient } from 'react-query'
import { removeProject } from 'api/services'

export const useRemoveProject = (options = {}) => {
  const queryClient = useQueryClient()
  const { onSuccess = () => {} } = options

  return useMutation(removeProject, {
    ...options,
    onSuccess: (data) => {
      queryClient.invalidateQueries('projects')
      onSuccess(data)
    },
  })
}
