import { useMemo } from 'react'
import { Link, Typography, Toolbar, AppBar, Paper, Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Link as RouterLink, useRouteMatch } from 'react-router-dom'
import { PaginationList, Avatar } from 'components'
import { useUsers } from 'hooks/useUsers'
import formatDate from 'functions/formatDate'
import NewUser from './new-user/NewUser'
import UpdateUser from './update-user/UpdateUser'
import RemoveUser from './remove-user/RemoveUser'

const Users = () => {
    const classes = useStyles()
		const { url } = useRouteMatch()
    const query = useUsers()

    const columns = useMemo(
      () => [
        {
          Header: 'Nome',
          accessor: 'name',
          Cell: ({
            /* eslint-disable react/prop-types */
            value,
            row: {
              original: { id },
            },
            /* eslint-enable react/prop-types */
          }) => (
            <Link component={RouterLink} to={`${url}/${id}/user`}>
              {value}
            </Link>
          ),
        },
        {
          Header: 'E-mail',
          accessor: 'email',
          Cell: ({ value }) => value,
        },
				{
					Header: 'Criado em',
					accessor: 'created_at',
					Cell: ({ value }) => formatDate(value),
				},
        {
          id: 'edit-delete',
          Cell: ({
            /* eslint-disable react/prop-types */
            row: {
              original: { id, name, email },
            },
            /* eslint-enable react/prop-types */
          }) => (
            <div className={classes.delete}>
              <UpdateUser 
                userId={id}
                name={name}
                email={email}
              />
              <RemoveUser 
                userId={id}
                name={name}
              />
            </div>
          ),
        },
      ],
      [url, classes.delete]
    )

    return (
        <>
            <AppBar position='static'>
            <Toolbar>
                <Typography className={classes.title} variant='h6' color='inherit'>
                Seja bem-vindo!
                </Typography>
                <Avatar />
            </Toolbar>
            </AppBar>
            <Paper elevation={3} className={classes.paper}>
            <Box display='flex' justifyContent='space-between'>
                <Typography variant='h6'>Lista de Usuários</Typography>
                <NewUser />
            </Box>
            <PaginationList query={query} columns={columns} />
            </Paper>
        </>
    )
}

const useStyles = makeStyles(() => ({
  paper: {
    margin: '20px 40px',
    padding: '20px',
  },
  delete: {
    display: 'flex',
    justifyContent: 'space-evenly',
  },
  title: {
    flexGrow: 1,
  },
}))

export default Users
