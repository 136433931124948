import PropTypes from 'prop-types'
import clsx from 'clsx'
import { useFormContext, Controller } from 'react-hook-form'
import { FormGroup, Checkbox, FormControl, FormLabel, FormControlLabel, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const CheckboxList = ({ id, label, name, defaultValue, disableMargin, options }) => {
  const {
    control,
    getValues,
    setValue,
    formState: { isSubmitting },
  } = useFormContext()
  const classes = useStyles()

  const onChange = (value) => {
    const fieldValue = getValues(name) || []

    if (fieldValue.includes(value)) {
      setValue(
        name,
        fieldValue.filter((item) => item !== value)
      )
    } else {
      setValue(name, [...fieldValue, value])
    }
  }

  const isChecked = (value) => {
    const fieldValue = getValues(name) || []

    return fieldValue.includes(value)
  }

  return (
    <FormControl
      component='fieldset'
      className={clsx({
        [classes.root]: !disableMargin,
      })}
    >
      <FormLabel className={classes.legend} component='legend'>{label}</FormLabel>
      <Controller
        render={({ field: { ref } }) => (
          <FormGroup ref={ref} id={id} name={name} aria-label={label}>
            {options.map((opt) => (
              <FormControlLabel
                className={classes.option}
                key={opt.label}
                value={opt.value}
                control={
                  <Checkbox
                    value={opt.value}
                    className={classes.checkbox}
                    onChange={() => onChange(opt.value)}
                    name={opt.label}
                    checked={isChecked(opt.value)}
                  />
                }
                label={<Typography className={classes.label}>{opt.label}</Typography>}
              />
            ))}
          </FormGroup>
        )}
        id={id}
        name={name}
        control={control}
        defaultValue={defaultValue ? typeof defaultValue == 'object' ? defaultValue : [defaultValue] : []}
        disabled={isSubmitting}
      />
    </FormControl>
  )
}

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    margin: '10px 0px',
  },
  option: {
    marginBottom: '20px'
  },
  checkbox: {
    alignSelf: 'baseline',
  },
  label: {
    textAlign: 'justify'
  },
  legend: {
    lineHeight: '24px',
    marginBottom: '10px'
  }
}))

CheckboxList.propTypes = {
  defaultValue: PropTypes.array,
  disableMargin: PropTypes.bool,
  id: PropTypes.string,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
}

CheckboxList.defaultProps = {
  defaultValue: [],
  disableMargin: false,
  id: '',
}

export default CheckboxList
