import { useMemo } from 'react'
import { Link, Paper, IconButton, Box, Button } from '@material-ui/core'
import {
  Edit as EditIcon,
  ChevronLeft as ChevronLeftIcon,
} from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'
import { Link as RouterLink, useRouteMatch, useParams } from 'react-router-dom'
import { PaginationList } from 'components'
import { useForms } from 'hooks/useForms'
import formatDate from 'functions/formatDate'
import NewForm from './new-form/NewForm'
import UpdateForm from './update-form/UpdateForm'
import RemoveForm from './remove-form/RemoveForm'
import ReplicateForm from './replicate'
import { useFormDuplicate } from 'hooks/forms'

const Projects = () => {
  const classes = useStyles()
  const { url } = useRouteMatch()
  const { projectId } = useParams()
  const query = useForms(projectId)

  const { mutateAsync: onDuplicate } = useFormDuplicate({
    onSuccess: () => query.refetch(),
    onError: (data) => console.log(data)
  })
  const columns = useMemo(
    () => [
      {
        Header: 'Formulário',
        accessor: 'title',
        Cell: ({
          /* eslint-disable react/prop-types */
          value,
          row: {
            original: { id },
          },
          /* eslint-enable react/prop-types */
        }) => (
          <Link component={RouterLink} to={{ pathname: `${url}/${id}`, state: { formName: value } }}>
            {value}
          </Link>
        ),
      },
      {
        Header: 'Tag',
        accessor: 'tag',
      },
      // {
      //   Header: 'Template',
      //   accessor: 'type',
      //   Cell: ({ value }) => getTemplate(value),
      // },
      {
        Header: 'Criado em',
        accessor: 'created_at',
        Cell: ({ value }) => formatDate(value),
      },
      {
        Header: 'Última atualização',
        accessor: 'updated_at',
        Cell: ({ value }) => formatDate(value),
      },
      {
        id: 'edit-delete',
        Cell: ({
          /* eslint-disable react/prop-types */
          row: {
            original: { id, title, tag },
          },
          /* eslint-enable react/prop-types */
        }) => (
          <div className={classes.delete}>
            <UpdateForm id={id} title={title} tag={tag}/>
             <ReplicateForm formId={id} saveHandle={onDuplicate}/>
            <IconButton size='small' edge='end' color='primary' aria-label='edit'>
              <Link component={RouterLink} to={`${url}/${id}`}>
                <EditIcon />
              </Link>
            </IconButton>
            <RemoveForm id={id} title={title} />
          </div>
        ),
      },
    ],
    [url, classes.delete]
  )

  return (
    <Paper elevation={3} className={classes.paper}>
      <Box display='flex' justifyContent='space-between'>
        <Button
          id='back-button'
          startIcon={<ChevronLeftIcon />}
          component={RouterLink}
          to='/projects'
          color='primary'
        >
          Projetos
        </Button>
        <Box display='flex' justifyContent='space-around'>
          <NewForm />
        </Box>
      </Box>
      <PaginationList query={query} columns={columns} />
    </Paper>
  )
}

const useStyles = makeStyles(() => ({
  paper: {
    margin: '20px 10px',
    padding: '20px',
  },
  delete: {
    display: 'flex',
    justifyContent: 'space-evenly',
  },
}))

export default Projects
