import { useMemo } from 'react'
import { useSteps } from 'hooks/steps'
import { Typography } from '@material-ui/core/'
import { makeStyles } from '@material-ui/core/styles'
import { Stepper } from 'components'
import FormProvider from 'providers/FormProvider'
import Form from '../forms/Form'

const Preview = () => {
  const classes = useStyles()
  const { steps } = useSteps()

  const sortSteps = (steps) => {
    return steps.sort((a, b) => {
      return (a.order > b.order) ? 1 : -1
    });
  }

  const receipt = useMemo(
    () => (
      <Typography className={classes.instructions}>Muito obrigado pela sua participação!
        <br /><br />
        Em breve você receberá em seu WhatsApp conteúdos de saúde e qualidade de vida.</Typography>
    ),
    [classes.instructions]
  )
  return (
    <div className={classes.wrapper}>
      <FormProvider steps={steps?.length || 0}>
        <Stepper steps={sortSteps(steps)} send={false} receipt={receipt}>
          {(props) => <Form formSteps={steps} isPreview {...props} />}
        </Stepper>
      </FormProvider>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  wrapper: {
    backgroundColor: '#fff',
    padding: '20px',
    margin: '100px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  instructions: {
    textAlign: 'center',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}))

Preview.propTypes = {}

Preview.defaultProps = {}

export default Preview
