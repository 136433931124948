import { createContext, useContext, useReducer } from 'react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import { useQuery, useMutation } from 'react-query'
import { fetchResults, createResult, updateResult } from 'api/services/results'

const ACTION_TYPES = {
  LIST: 'LIST_METRIC',
  ADD: 'ADD_METRIC',
  UPDATE: 'UPDATE_METRIC',
}

const resultsReducer = (state, action) => {
  switch (action.type) {
    case ACTION_TYPES.LIST: {
      return { results: action.data }
    }
    case ACTION_TYPES.ADD: {
      return { results: [...state.results, action.data] }
    }
    case ACTION_TYPES.UPDATE: {
      return {
        results: state.results.map((item) =>
          item.id === action.data.id ? { ...item, ...action.data } : item
        ),
      }
    }
    default: {
      return state
    }
  }
}

const ResultsStateContext = createContext()
const ResultsDispatchContext = createContext()

const ResultsProvider = ({ children }) => {
  const { projectId } = useParams()
  const [state, dispatch] = useReducer(resultsReducer, { results: [] })

  const { isFetching } = useQuery(['results', projectId], () => fetchResults(projectId), {
    onSuccess: (data) => {
      dispatch({ type: ACTION_TYPES.LIST, data: data.data })
    },
  })

  return (
    <ResultsStateContext.Provider value={{ isLoading: isFetching, results: state.results }}>
      <ResultsDispatchContext.Provider value={dispatch}>{children}</ResultsDispatchContext.Provider>
    </ResultsStateContext.Provider>
  )
}

ResultsProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

const useResults = (projectId) => {
  const context = useContext(ResultsStateContext)

  if (!context) {
    throw new Error('useResults must be used within an ResultsProvider')
  }

  if (projectId) {
    const { results } = context
    
    return {
      ...context,
      results: results ? results.filter((item) => item.project_id === projectId) : [],
    }
  }

  return context
}

const useDispatch = () => {
  const dispatch = useContext(ResultsDispatchContext)
  if (dispatch === undefined) {
    throw new Error('useDispatch must be used within a ResultsProvider')
  }
  return dispatch
}

const useCreateResult = (projectId, options = {}) => {
  const dispatch = useDispatch()
  const { onSuccess } = options

  return useMutation((data) => createResult(projectId, data), {
    ...options,
    onSuccess: (data) => {
      onSuccess(data)
      dispatch({ type: ACTION_TYPES.ADD, data })
    },
  })
}

const useUpdateResult = (id, options = {}) => {
  const dispatch = useDispatch()
  
  return useMutation((data) => updateResult(id, data), {
    ...options,
    onSuccess: (data) => {
      dispatch({ type: ACTION_TYPES.UPDATE, data })
    },
  })
}

export {
  ResultsProvider,
  useResults,
  useCreateResult,
  useUpdateResult
}
