import { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { Button, Typography } from '@material-ui/core'
import { Add as AddIcon } from '@material-ui/icons'
import { yupResolver } from '@hookform/resolvers/yup'
import { useCreateProject } from 'hooks/useCreateProject'
import { Dialog, InputField } from 'components'
import * as yup from 'yup'

const schema = yup.object().shape({
  title: yup.string().required('Esse campo é obrigatório').max(255, 'O nome deve ter no máximo 255 caracteres').min(4, 'O nome deve ter no mínimo 4 caracteres'),
})

const NewProject = () => {
  const [open, setOpenDialog] = useState(false)

  const methods = useForm({
    mode: 'onTouched',
    resolver: yupResolver(schema),
    defaultValues: {
      title: '',
    },
  })
  const {
    handleSubmit,
    formState: { isValid, isDirty, errors },
  } = methods

  const { mutate, isLoading } = useCreateProject({
    onSuccess: () => setOpenDialog(false),
  })

  return (
    <>
      <Button
        onClick={() => setOpenDialog(true)}
        variant='contained'
        startIcon={<AddIcon />}
        color='primary'
      >
        Novo Projeto
      </Button>
      <FormProvider {...methods}>
        <Dialog
          title='Novo Projeto'
          contentText='Escolha um nome para o novo Projeto. Essa informação é apenas para visualização e você poderá alterar depois.'
          open={open}
          onCancel={() => setOpenDialog(false)}
          isLoading={isLoading}
          submitDisabled={!isValid || !isDirty}
          onSubmit={handleSubmit((data) => mutate(data))}
        >
          <InputField
            id='title'
            name='title'
            label='Nome do projeto'
            variant='outlined'
            error={errors?.title}
            required
            fullWidth
            inputProps={{maxLength:255}}
          />
          { errors?.title && <Typography color="error" variant='span'>{errors.title.message}</Typography> }
        </Dialog>
      </FormProvider>
    </>
  )
}

NewProject.propTypes = {}

NewProject.defaultProps = {}

export default NewProject
