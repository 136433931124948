import PropTypes from 'prop-types'
import { CircularProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const Loader = ({ size, minHeight, minWidth }) => {
  const classes = useStyles({ minHeight, minWidth })
  return (
    <div className={classes.centered}>
      <CircularProgress size={size} />
    </div>
  )
}

const useStyles = makeStyles(() => ({
  centered: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: ({ minWidth }) => minWidth,
    minHeight: ({ minHeight }) => minHeight,
  },
}))

Loader.propTypes = {
  minHeight: PropTypes.string,
  minWidth: PropTypes.string,
  size: PropTypes.number.isRequired,
}

Loader.defaultProps = {
  minHeight: 'auto',
  minWidth: 'auto',
}

export default Loader
