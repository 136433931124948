import { useMemo } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { List } from '@material-ui/core'
import { useAccordion } from 'hooks/accordion'

const DraggableList = ({ onReorder, droppableId, items, children }) => {
  const isDragDisabled = useMemo(() => !onReorder, [onReorder])
  const {lockAccordion} = useAccordion();

  const onDragEnd = ({ draggableId, destination: { index } }) => {
    if (!onReorder || typeof index === 'undefined') {
      return
    }
    const field = items.find(({ id }) => id === draggableId)
    const newPosition = get(items, [index, 'order'], index)

    onReorder({ id: draggableId, data: { ...field, order: (field.order > newPosition) ? newPosition : newPosition + 1 } })
  }

  return (
    <DragDropContext onDragEnd={onDragEnd} >
      <Droppable droppableId={droppableId} >
        {(provided) => (
          <List ref={provided.innerRef} {...provided.droppableProps}>
            {items.map((item, index) => (
              <Draggable
                key={item.id}
                draggableId={item.id}
                index={index}
                isDragDisabled={isDragDisabled || lockAccordion}
              >
                {(providedDraggable, snapshot) =>
                  children({
                    ref: providedDraggable.innerRef,
                    ...providedDraggable.draggableProps,
                    ...providedDraggable.dragHandleProps,
                    snapshotProps: snapshot,
                    item,
                  })
                }
              </Draggable>
            ))}
            {provided.placeholder}
          </List>
        )}
      </Droppable>
    </DragDropContext>
  )
}

DraggableList.propTypes = {
  children: PropTypes.func.isRequired,
  droppableId: PropTypes.string,
  items: PropTypes.array.isRequired,
  onReorder: PropTypes.func,
}

DraggableList.defaultProps = {
  droppableId: 'droppable',
  onReorder: null,
}

export default DraggableList
