import route from "../index-fill"

export const postEvaluation = async (payload) => {
  const { data } = await route.post(`/builder`, payload)

  return data
}

export const saveEvaluation = async (payload) => {
  const { data } = await route.post(`/save`, payload)

  return data
}
