import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Switch } from '@material-ui/core'
import { useAccordion } from 'hooks/accordion'

const useStyles = makeStyles(() => ({
  title: {
    flexGrow: 1,
  },
  dragConf: {
    display: 'flex',
    position: 'fixed',
    left: 0,
    bottom: 0,
    zIndex: 9999
  },
  dragConfBox: {
    display: 'flex',
    height: '40px',
    background: '#E0E0E0',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '20px',
    margin: '20px',
    padding: '0 20px',
  },
}))

const LockDrag = () => {
  const { lockAccordion, setLockAccordion } = useAccordion();
  const classes = useStyles()

  return (
    <div className={classes.dragConf}>
      <div className={classes.dragConfBox}>
        Travar ordenação
        <Switch defaultChecked={lockAccordion} defaultValue={lockAccordion} color="primary" onChange={({target}) => setLockAccordion(target.checked)}/>
      </div>
    </div>
  )
}

export default LockDrag