import { makeStyles } from '@material-ui/core/styles'
import { Tabs } from 'components'
import Informations from './informations/Informations'
import RegisteredFields from './registered-fields/RegisteredFields'
import ConclusionMessage from './conclusion-message/ConclusionMessage'

const Builder = ({project, form}) => {
  const classes = useStyles()

  return (
    <div className={classes.wrapper}>
      <Tabs
        items={[
          {
            name: 'Informações textuais',
            component: <Informations customer={project.customer} />,
          },
          {
            name: 'Formulário',
            component: <RegisteredFields project={project} />,
          },
          {
            name: 'Mensagem de conclusão',
            component: <ConclusionMessage form={form} />,
          },
        ]}
      />
    </div>
  )
}

const useStyles = makeStyles(() => ({
  wrapper: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
}))

export default Builder
