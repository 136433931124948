import { useMutation } from 'react-query'
import { fetchProjectToken } from 'api/services/project'

export const useProjectToken = (projectId, options = {}) => {
  const { onSuccess = () => {} } = options

  return useMutation(() => fetchProjectToken(projectId), {
    ...options,
    onSuccess: (data) => {
      onSuccess(data)
    },
  })
}
