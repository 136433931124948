import { useMemo } from 'react'
import { Tooltip, Link, Typography, Toolbar, AppBar, Paper, IconButton, Box } from '@material-ui/core'
import { Edit as EditIcon } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'
import { Link as RouterLink, useRouteMatch } from 'react-router-dom'
import { PaginationList, Avatar } from 'components'
import { useProjects } from 'hooks/useProjects'
import formatDate from 'functions/formatDate'
import NewProject from './new-project/NewProject'
import RemoveProject from './remove-project/RemoveProject'
import UpdateProject from './update-project/UpdateProject'
import CreateToken from './create-token/CreateToken'

const Projects = () => {
  const classes = useStyles()
  const { url } = useRouteMatch()
  const query = useProjects()

  const columns = useMemo(
    () => [
      {
        Header: 'Projeto',
        accessor: 'title',
        Cell: ({
          /* eslint-disable react/prop-types */
          value,
          row: {
            original: { id },
          },
          /* eslint-enable react/prop-types */
        }) => (
          <Link component={RouterLink} to={`${url}/${id}/form`}>
            {value}
          </Link>
        ),
      },
      {
        Header: 'Criado em',
        accessor: 'created_at',
        Cell: ({ value }) => formatDate(value),
      },
      {
        Header: 'Última atualização',
        accessor: 'updated_at',
        Cell: ({ value }) => formatDate(value),
      },
      {
        id: 'edit-delete',
        Cell: ({
          /* eslint-disable react/prop-types */
          row: {
            original: { id, title },
          },
          /* eslint-enable react/prop-types */
        }) => (
          <div className={classes.delete}>
            <CreateToken projectId={id} />
            <UpdateProject id={id} title={title} />
            <Tooltip title="Editar projeto">
            <IconButton size='small' edge='end' color='primary' aria-label='edit'>
              <Link component={RouterLink} to={`${url}/${id}/form`}>
                <EditIcon />
              </Link>
            </IconButton>
            </Tooltip>
            <RemoveProject id={id} title={title} />
          </div>
        ),
      },
    ],
    [url, classes.delete]
  )

  return (
    <>
      <AppBar position='static'>
        <Toolbar>
          <Typography className={classes.title} variant='h6' color='inherit'>
            Seja bem-vindo!
          </Typography>
          <Avatar />
        </Toolbar>
      </AppBar>
      <Paper elevation={3} className={classes.paper}>
        <Box display='flex' justifyContent='space-between'>
          <Typography variant='h6'>Lista de Projetos</Typography>
          <NewProject />
        </Box>
        <PaginationList query={query} columns={columns} />
      </Paper>
    </>
  )
}

const useStyles = makeStyles(() => ({
  paper: {
    margin: '20px 40px',
    padding: '20px',
  },
  delete: {
    display: 'flex',
    justifyContent: 'space-evenly',
  },
  title: {
    flexGrow: 1,
  },
}))

export default Projects
