import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Box, TextField, MenuItem } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useFormContext } from 'react-hook-form'
import { get} from 'lodash'
import { Editor } from 'ckeditor5-custom-build/build/ckeditor'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import { useFields } from 'hooks/fields'
import { useFeeds } from 'hooks/texts'
import { InputField } from 'components'

const useStyles = makeStyles(() => ({
  wrapper: {
    cursor: 'default'
  },
  editor: {
    width: '100px',
  },
  input: {
    maxWidth: '350px',
    marginBottom: '20px'
  }
}))

let CKE_EDITOR = null;
const TextForOptions = ({ options }) => {
  const [selected, setOption] = useState(options[0].value)
  const { setValue, getValues } = useFormContext()
  const { fields } = useFields()
  const feed = useFeeds(fields)
  console.log(selected)

  const classes = useStyles()

  const editorConfiguration = {
    toolbar: {
      shouldNotGroupWhenFull: true,
      items: [
        'heading',
        '|',
        'alignment',
        '|',
        'bold',
        'italic',
        'strikethrough',
        'underline',
        '|',
        'link',
        '|',
        'bulletedList',
        'numberedList',
        '-',
        'fontFamily',
        'fontSize',
        'fontColor',
        'fontBackgroundColor',
        '|',
        'outdent',
        'indent',
        '|',
        'undo',
        'redo',
        '|',
        'removeFormat',
        'horizontalLine',
      ],
    },
    image: {
      styles: ['alignLeft', 'alignCenter', 'alignRight'],
      resizeOptions: [
        {
          name: 'resizeImage:original',
          label: 'Original',
          value: null,
        },
        {
          name: 'resizeImage:50',
          label: '50%',
          value: '50',
        },
        {
          name: 'resizeImage:75',
          label: '75%',
          value: '75',
        },
      ],
      toolbar: [
        'imageStyle:alignLeft',
        'imageStyle:alignCenter',
        'imageStyle:alignRight',
        '|',
        'resizeImage',
        '|',
        'imageTextAlternative',
      ],
    },
    table: {
      contentToolbar: [
        'tableColumn',
        'tableRow',
        'mergeTableCells',
        'tableCellProperties',
        'tableProperties',
      ],
    },
    licenseKey: '',
    fontSize: {
      options: [12, 14, 16, 18, 21, 24],
      supportAllValues: true,
    },
    uicolor: { height: '800px' },
    mediaEmbed: {
      removeProviders: ['instagram', 'twitter', 'googleMaps', 'flickr', 'facebook'],
    },
    mention: {
      feeds: [
          {
              marker: '{',
              feed,
          }
      ]
    }
  }

  useEffect(() => {
    if (!CKE_EDITOR) return;

    const data = options.find(item => item.value === selected)
    data.text = data.text == null ? '' : data.text;

    CKE_EDITOR.setData(get(data, 'text', ''))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected])
  
  return (
    <Box className={classes.wrapper}>
      <TextField
        id="options"
        select
        fullWidth
        label="Selecione uma opção para editar o seu texto."
        value={selected}
        onChange={(e) => { 
          setOption(e.target.value)
         
        }}
        variant='standard'
        className={classes.input}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
      <InputField
        value={getValues(`options.${options.findIndex(({ value }) => value === selected)}.text`)}
        onChange={(e, editor) => {
          const data = e.target.value
          // const data = editor.getData().
          const index = options.findIndex(({ value }) => value === selected)
          setValue(`options.${index}.text`, data)
        }}
        multiline
      />
    </Box>
  )
}

TextForOptions.propTypes = {
  options: PropTypes.array.isRequired,
}

TextForOptions.defaultProps = {
  // texts: null,
}

export default TextForOptions
