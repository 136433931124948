import { useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { get,isEmpty } from 'lodash'
import {
  Drawer,
  AppBar,
  Toolbar,
  List,
  Typography,
  Divider,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core'
import {
  Home as HomeIcon,
  AssignmentLate as AssignmentLateIcon,
  Description as DescriptionIcon,
  PersonPin as CustomerIcon,
  Settings as PreferencesIcon,
  Poll as PollIcon,
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  Menu as MenuIcon,
} from '@material-ui/icons'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { useHistory, useRouteMatch, useParams } from 'react-router-dom'
import { Avatar } from 'components'
import { levelUp } from 'functions/levelUp'
import { useFetchProject } from 'hooks/useProject'
import { useEffect } from 'react'

const Menu = ({ children }) => {
  const classes = useStyles()
  const theme = useTheme()
  const history = useHistory()
  const { url } = useRouteMatch()
  const { projectId} = useParams()
  const { data } = useFetchProject(projectId)
  const [open, setOpen] = useState(false)
  const [title, setTitle] = useState('')

  useEffect(() => {
    if (isEmpty(title)) {
      setTitle(get(data, 'title', null) ? get(data, 'title', null) : get(data, ['project', 'name'], null))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  
  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  return (
    <div className={classes.root}>
      <AppBar
        position='absolute'
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color='inherit'
            aria-label='open drawer'
            onClick={handleDrawerOpen}
            edge='start'
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant='h6' noWrap className={classes.title}>
            {!isEmpty(data)  && `Projeto: ${title}`}
          </Typography>
          <Avatar />
        </Toolbar>
      </AppBar>
      <Drawer
        variant='permanent'
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx(classes.drawerPaper, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={clsx(classes.toolbarMenu, classes.toolbar)}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? (
              <ChevronRightIcon className={classes.chevron} />
            ) : (
              <ChevronLeftIcon className={classes.chevron} />
            )}
          </IconButton>
          <Typography variant='h6' noWrap>
            Menu
          </Typography>
        </div>
        <Divider />
        <List>
          <ListItem button onClick={() => history.push(`${levelUp(url)}/form`)} selected={url.includes('/form')}>
            <ListItemIcon>{<HomeIcon />}</ListItemIcon>
            <ListItemText primary='Formulários' />
          </ListItem>
          <ListItem button onClick={() => history.push(`${levelUp(url)}/metrics`)} selected={url.includes('/metrics')}>
            <ListItemIcon>{<AssignmentLateIcon />}</ListItemIcon>
            <ListItemText primary='Métricas' />
          </ListItem>
          <ListItem button onClick={() => history.push(`${levelUp(url)}/results`)} selected={url.includes('/results')}>
            <ListItemIcon>{<PollIcon />}</ListItemIcon>
            <ListItemText primary='Resultados' />
          </ListItem>
          <ListItem button onClick={() => history.push(`${levelUp(url)}/devolutives`)} selected={url.includes('/devolutives')}>
            <ListItemIcon>{<DescriptionIcon />}</ListItemIcon>
            <ListItemText primary='Devolutiva' />
          </ListItem>
          <ListItem button onClick={() => history.push(`${levelUp(url)}/customer-attributes`)} selected={url.includes('/customer-attributes')}>
            <ListItemIcon>{<CustomerIcon />}</ListItemIcon>
            <ListItemText primary='Informações de Integração' />
          </ListItem>
          <ListItem button onClick={() => history.push(`${levelUp(url)}/preferences`)} selected={url.includes('/preferences')}>
            <ListItemIcon>{<PreferencesIcon />}</ListItemIcon>
            <ListItemText primary='Customização' />
          </ListItem>
        </List>
      </Drawer>
      <div className={classes.content}>
        <div className={classes.toolbar} />
        {children}
      </div>
    </div>
  )
}

const drawerWidth = 290

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    top: 'unset',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerPaper: {
    top: 'unset',
    position: 'absolute',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  toolbarMenu: {
    backgroundColor: '#3f51b5',
    justifyContent: 'flex-start',
    color: 'white',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(1, 2),
  },
  chevron: {
    color: 'white',
  },
  title: {
    flexGrow: 1,
  },
}))

Menu.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Menu
