import { createContext, useContext, useReducer, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import { useMutation } from 'react-query'
import { isEmpty } from 'lodash'
import { updateText } from 'api/services'

const ACTION_TYPES = {
  UPDATE: 'UPDATE_TEXT',
  ADD: 'ADD_TEXT',
}

const textsReducer = (state, action) => {
  switch (action.type) {
    case ACTION_TYPES.ADD: {
      return {
        texts: [...state.texts, ...action.data],
      }
    }
    case ACTION_TYPES.UPDATE: {
      return {
        texts: state.texts.map((item) =>
          item.id === action.data.id ? { ...item, ...action.data } : item
        ),
      }
    }
    default: {
      return state
    }
  }
}

const TextsStateContext = createContext()
const TextsDispatchContext = createContext()

const TextsProvider = ({ children }) => {
  // const { formId } = useParams()
  const [state, dispatch] = useReducer(textsReducer, { texts: [] })
  // const { isLoading } = useQuery(['texts', formId], () => fetchTexts(formId), {
  //   onSuccess: (data) => {
  //     dispatch({ type: ACTION_TYPES.ADD, data })
  //   },
  // })

  return (
    <TextsStateContext.Provider value={{ isLoading: false, texts: state.texts }}>
      <TextsDispatchContext.Provider value={dispatch}>{children}</TextsDispatchContext.Provider>
    </TextsStateContext.Provider>
  )
}

TextsProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

const useDevolutiva = () => {
  const context = useContext(TextsStateContext)

  if (!context) {
    throw new Error('useDevolutiva must be used within an TextsProvider')
  }

  return context
}

const useDispatch = () => {
  const dispatch = useContext(TextsDispatchContext)
  if (dispatch === undefined) {
    throw new Error('useDispatch must be used within a TextsProvider')
  }
  return dispatch
}

const useUpdateDevolutiva = (options = {}) => {
  const { formId } = useParams()
  const dispatch = useDispatch()

  return useMutation((data) => updateText(formId, data), {
    ...options,
    onSuccess: (data) => {
      dispatch({ type: ACTION_TYPES.UPDATE, data })
    },
  })
}

const useFeeds = fields => 
  useMemo(
    () => {
      const feeds = ['{{user.name}}', '{{user.email}}']

      if (isEmpty(fields)) {
        return feeds 
      }

      fields.forEach(({ name }) => {
        feeds.push(`{{${name}}}`)
      });

      return feeds
    },
    [fields]
  )

export { TextsProvider, useDevolutiva, useUpdateDevolutiva, useFeeds }
