import PropTypes from 'prop-types'
import { MetricsProvider } from './metrics'

const MetricProvider = ({ children }) => {
  return (
    <MetricsProvider>{children}</MetricsProvider>
  )
}

MetricProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default MetricProvider
