import api from 'api'

const ATTRIBUTES_ROUTE = "/attributes";

export async function GET_ATTRIBUTES(projectId) {
	const { data } = await api.get(`project/${projectId}${ATTRIBUTES_ROUTE}`);

	return data;
}

export async function POST_ATTRIBUTE(projectId, payload) {
	const { data } = await api.post(`${ATTRIBUTES_ROUTE}/${projectId}`, payload);

	return data;
}

export async function UPDATE_ATTRIBUTE(id, payload) {
	const { data } = await api.put(`${ATTRIBUTES_ROUTE}/${id}`, payload);

	return data;
}

export async function DELETE_ATTRIBUTE(id) {
	const { data } = await api.delete(`${ATTRIBUTES_ROUTE}/${id}`);

	return data;
}