import { MenuItem } from "@material-ui/core"
import { InputField } from "components";
import { operators } from "enum/operators";
import { useFormContext } from "react-hook-form";
import { get, isEmpty } from "lodash";

export default ({forms = {}, path}) => {
    const { control, watch, getValues } = useFormContext()
    
    const getFieldsByFormId = (formTag) => {
        if (formTag && forms[formTag]) {
            return Object.keys(forms[formTag].fields).map((item) => ({tag: item, ...forms[formTag].fields[item]}))
        }
        return []
    }
    const getOptions = () => {
        const { field, value_of_id } = getValues(`${path}`)
        return get(
          getFieldsByFormId(value_of_id).find((item) => item.id === field || item.tag === field),
          'options',
          []
        )
      }
    return (
        <>
        <InputField
            id='value_of_id'
            size='small'
            fullWidth
            name={`${path}.value_of_id`}
            label='Formulario'
            defaultValue={getValues(`${path}.value_of_id`)}
            disableMargin
            select
        >
            {Object.keys(forms).map(
                (opt) => (
                    <MenuItem key={opt} value={opt}>
                    {forms[opt].name}
                    </MenuItem>
                )
            )}
            
        </InputField>
        <InputField
            id='field'
            size='small'
            fullWidth
            name={`${path}.field`}
            label='Campo'
            defaultValue={getValues(`${path}.field`)}
            disableMargin
            select
            >
            
                {getFieldsByFormId(
                getValues(`${path}.value_of_id`)
                ).map(
                (opt) => (
                    <MenuItem key={opt.id} value={opt.id}>
                        {opt.title}
                    </MenuItem>
                    )
                )}
        </InputField>
        <InputField
            id='operador'
            name={`${path}.operator`}
            control={control}
            defaultValue={getValues(`${path}.operator`)}
            size='small'
            variant='outlined'
            label='Operador'
            disableMargin
            fullWidth
            select
        >
            {operators.map((opt) => (
            <MenuItem key={opt.value} value={opt.value}>
                {opt.label}
            </MenuItem>
            ))}
        </InputField>

        <InputField
            size='small'
            variant='outlined'
            fullWidth
            label='Valor'
            select={!isEmpty(getOptions())}
            name={`${path}.value`}
            control={control}
            defaultValue={getValues(`${path}.value`)}
            disableMargin
        >
            <MenuItem value={""}>
            Nenhum valor
            </MenuItem>
            {
            (getOptions().map((opt) => (
                <MenuItem key={opt.value} value={opt.value}>
                {opt.label}
                </MenuItem>
            )))
            }
        </InputField>
        </>
    )
}