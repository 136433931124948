import queryString from 'query-string'
import api from 'api'

export const fetchProjects = async (queryParams, cursor) => {
  const { data } = await api.get(
    `/project?${queryString.stringify({
      ...queryParams,
      ...cursor,
    })}`
  )

  return data
}
