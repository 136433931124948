import { createContext, useContext, useReducer, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { get, isEmpty } from 'lodash'
import { fetchSteps, updateStep, createStep, removeStep } from 'api/services'
import { useForm } from 'hooks/formsDisplay'

const ACTION_TYPES = {
  LIST: 'LIST_STEP',
  ADD: 'ADD_STEP',
  REMOVE: 'REMOVE_STEP',
  UPDATE: 'UPDATE_STEP',
  REORDER: 'REORDER_STEPS',
  SELECT: 'SELECT_STEP',
}

const stepsReducer = (state, action) => {
  switch (action.type) {
    case ACTION_TYPES.LIST: {
      return { ...state, steps: action.data }
    }
    case ACTION_TYPES.ADD: {
      return { ...state, steps: [...state.steps, action.data] }
    }
    case ACTION_TYPES.REMOVE: {
      return { ...state, steps: state.steps.filter(({ id }) => id !== action.id) }
    }
    case ACTION_TYPES.UPDATE: {
      return {
        ...state,
        steps: state.steps.map((item) => (item.id === action.data.id ? action.data : item)),
      }
    }
    case ACTION_TYPES.SELECT: {
      return {
        ...state,
        selected: action.id,
      }
    }
    case ACTION_TYPES.REORDER: {
      const { source, destination } = action.data

      const result = Array.from(state.steps)
      const [removed] = result.splice(source, 1)
      result.splice(destination, 0, removed)

      return {
        ...state,
        steps: result.map((item, index) => ({ ...item, order: index })),
      }
    }
    default: {
      return state
    }
  }
}

const StepsStateContext = createContext()
const StepsDispatchContext = createContext()

const StepsProvider = ({ children }) => {
  const { formId } = useParams()
  const [state, dispatch] = useReducer(stepsReducer, { steps: [] })
  let displayForm
  try {
    displayForm = useForm()
  } catch {
    displayForm = null
  }

  if (
    !!displayForm &&
    (get(displayForm, 'isLoading', false) || !!get(displayForm, ['selectedForm', 'builder'], false))
  ) {
    const data = []
    get(displayForm, ['selectedForm', 'builder', 'steps'], []).map((step) => data.push(step))
    let id = null
    if (data.length > 0) {
      const minOrder = data.map(({ order }) => order).reduce((a, b) => Math.min(a, b))
      id = data.find(({ order }) => order === minOrder)
      id = id.id
    }

    return (
      <StepsStateContext.Provider
        value={{
          isLoading: get(displayForm, 'isLoading', false),
          steps: data,
          selected: state.selected ? state.selected : id,
        }}
      >
        <StepsDispatchContext.Provider value={dispatch}>{children}</StepsDispatchContext.Provider>
      </StepsStateContext.Provider>
    )
  }

  const { isLoading } = useQuery(['steps', formId], () => fetchSteps(formId), {
    select: (data) => get(data, 'data', []),
    onSuccess: (data) => {
      if (isEmpty(data)) {
        return dispatch({ type: ACTION_TYPES.LIST, data })
      }
      const minOrder = data.map(({ order }) => order).reduce((a, b) => Math.min(a, b))
      const { id } = data.find(({ order }) => order === minOrder)

      dispatch({ type: ACTION_TYPES.LIST, data })
      dispatch({ type: ACTION_TYPES.SELECT, id })
    },
  })

  return (
    <StepsStateContext.Provider value={{ isLoading, ...state }}>
      <StepsDispatchContext.Provider value={dispatch}>{children}</StepsDispatchContext.Provider>
    </StepsStateContext.Provider>
  )
}

StepsProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

const useSteps = () => {
  const context = useContext(StepsStateContext)

  if (!context) {
    throw new Error('useSteps must be used within an StepsProvider')
  }

  return context
}

const useDispatch = () => {
  const dispatch = useContext(StepsDispatchContext)
  if (dispatch === undefined) {
    throw new Error('useDispatch must be used within a StepsProvider')
  }
  return dispatch
}

const useUpdateStep = (id, options = {}) => {
  const { formId } = useParams()
  const { onSuccess } = options
  const dispatch = useDispatch()

  return useMutation((data) => updateStep(formId, id, data), {
    ...options,
    onSuccess: (data) => {
      if (onSuccess) onSuccess(data)

      dispatch({ type: ACTION_TYPES.UPDATE, data })
    },
  })
}

const useRemoveStep = (id, options) => {
  const { formId } = useParams()
  const dispatch = useDispatch()

  return useMutation(() => removeStep(formId, id), {
    ...options,
    onSuccess: () => dispatch({ type: ACTION_TYPES.REMOVE, id }),
  })
}

const useCreateStep = (options = {}) => {
  const { formId } = useParams()
  const dispatch = useDispatch()
  const { onSuccess } = options

  return useMutation((data) => createStep(formId, data), {
    ...options,
    onSuccess: (data) => {
      onSuccess(data)
      dispatch({ type: ACTION_TYPES.ADD, data })
    },
  })
}

const useReorderSteps = (options = {}) => {
  const queryClient = useQueryClient()
  const dispatch = useDispatch()
  const { formId } = useParams()

  return useMutation(({ id, data }) => updateStep(formId, id, data), {
    ...options,
    onSuccess: (data) => {
      dispatch({ type: ACTION_TYPES.REORDER, data })
      queryClient.invalidateQueries('steps')
    },
  })
}

const useSelectStep = () => {
  const dispatch = useDispatch()

  return useCallback(
    (id) => {
      dispatch({ type: ACTION_TYPES.SELECT, id })
    },
    [dispatch]
  )
}

export {
  StepsProvider,
  useSteps,
  useUpdateStep,
  useCreateStep,
  useRemoveStep,
  useReorderSteps,
  useSelectStep,
}
