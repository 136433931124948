import { useMutation, useQueryClient } from 'react-query'
import { removeUser } from 'api/services/user'

export const useRemoveUser = (options = {}) => {
  const queryClient = useQueryClient()
  const { onSuccess = () => {} } = options

  return useMutation(removeUser, {
    ...options,
    onSuccess: (data) => {
      queryClient.invalidateQueries('projects')
      onSuccess(data)
    },
  })
}
