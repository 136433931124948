import { Button, IconButton, List, ListItem, ListItemIcon, MenuItem } from "@material-ui/core";
import { InputField } from "components";
import { useFieldArray, useFormContext } from "react-hook-form"
import { Add as AddIcon, Delete as DeleteIcon } from '@material-ui/icons/'
import { v4 as uuidv4 } from 'uuid'
import { createElement, useEffect } from "react";
import CustomerForm from "./components/CustomerForm";
import FieldForm from "./components/FieldForm";
import MetricForm from "./components/MetricForm";
const components = {
  Metric: MetricForm,
  Field: FieldForm,
  Customer: CustomerForm
}
const WithFormHooks = ({forms = {}, customer = {}, metrics= {}, path, style}) => {
    const { control, watch, getValues } = useFormContext()
    const classes = style
    const {
      fields: conditions,
      append,
      remove,
    } = useFieldArray({
      control,
      name: `${path}.display_rules`,
      defaultValue: []
    })
    const displayRules = watch(`${path}.display_rules`, [])
    const watchAll = watch()

    useEffect(() => {
      const subscription = watch((value, { name, type }) => console.log(value, type, name));
      return () => subscription.unsubscribe();
    }, [displayRules, watchAll])

    return (
        <List disablePadding className={classes.nested}>
        {conditions.map((rule, index) => (
          <ListItem key={rule.id} disableGutters dense>
            <ListItemIcon className={classes.root}>
              <IconButton onClick={() => remove(index)} edge='start' aria-label='delete'>
                <DeleteIcon />
              </IconButton>
            </ListItemIcon>

            <InputField
              id='type'
              size='small'
              fullWidth
              name={`${path}.display_rules.${index}.type`}
              label='Tipo'
              control={control}
              disableMargin
              defaultValue={getValues(`${path}.display_rules.${index}.type`)}
              select
            >
              <MenuItem value='Customer'>
                Usuário
              </MenuItem>
              <MenuItem value='Field'>
                Campo
              </MenuItem>
              <MenuItem value='Metric'>
               Metrica
              </MenuItem>
            </InputField>
            { getValues(`${path}.display_rules.${index}.type`) && 
            createElement(components[getValues(`${path}.display_rules.${index}.type`)], {
              forms, customer, metrics, path: `${path}.display_rules.${index}`
            })}
          </ListItem> 
        ))}  
        <Button
              className={classes.button}
              variant='contained'
              size='small'
              color='primary'
              startIcon={<AddIcon />}
              onClick={() => {
                append({
                  id: uuidv4(),
                  field: '',
                  operator: '',
                  value: '',
                  type: 'Field',
                })
              }}
            >
              Condição
            </Button>
         </List>
    )
}

export default WithFormHooks;
