import {useFormContext} from 'react-hook-form'
import {graduation} from "../../enum/physicalExamOptions";
import SimpleSelectField from "../SimpleSelectField";
import MultiOptionSelectField from "../MultiOptionSelectField";
import React, {useEffect} from "react";
import {Button, Divider, Grid} from "@material-ui/core";
import {InputField} from "components";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import generateProgression from "../../functions/generateProgression";

const Mao = ({id, segment, segmentName, removeItems}) => {
    const {control, watch, setValue} = useFormContext()
    const watchAmputationField = watch(`${id}.${segment}.01-amputation`)

    useEffect(()=>{
                    setValue(`${id}.${segment}.02-amputationLevel`, null);
    },[watchAmputationField]);
    return (
        <>
            <Button style={{width: '100%', fontWeight: '900', fontSize: 'medium', backgroundColor: 'red'}}
                    variant={'contained'}
                    size='large'
                    color='secondary'
                    onClick={() => removeItems(segmentName,segment)}
            >
                Excluir Exame
            </Button>
            <Grid container spacing={3} className="form-grid" justifyContent="center">
                <Grid item sm={10} md={5}>
                    <SimpleSelectField
                        segment={segment}
                        id={id}
                        name={'01-amputation'}
                        label={'Amputação'}
                        control={control}/>
                </Grid>

                {
                    watchAmputationField === 1 &&
                    <Grid item sm={10} md={5}>
                        <InputField
                            id={id}
                            name={`${id}.${segment}.02-amputationLevel`}
                            label={'Nível de Amputação'}
                            control={control}/>
                    </Grid>
                }

                <Grid item sm={10} md={5}>
                    <SimpleSelectField
                        segment={segment}
                        id={id}
                        name={'03-palmarTweezers'}
                        label={'Pinça Palmar'}
                        trueLabel={'Normal'}
                        falseLabel={'Alterado'}
                        control={control}/>
                </Grid>

                <Grid item sm={10} md={5}>
                    <SimpleSelectField
                        segment={segment}
                        id={id}
                        name={'04-digitalCompression'}
                        label={'Compressão Digital'}
                        trueLabel={'Normal'}
                        falseLabel={'Alterado'}
                        control={control}/>
                </Grid>

                <Grid item sm={10} md={5}>
                    <SimpleSelectField
                        segment={segment}
                        id={id}
                        name={'05-sideClamp'}
                        label={'Pinça Lateral'}
                        trueLabel={'Normal'}
                        falseLabel={'Alterado'}
                        control={control}/>
                </Grid>

                <Grid item sm={10} md={5}>
                    <SimpleSelectField
                        segment={segment}
                        id={id}
                        name={'06-pulpForceps'}
                        label={'Pinça Pulpar'}
                        trueLabel={'Normal'}
                        falseLabel={'Alterado'}
                        control={control}/>
                </Grid>

                <Grid item sm={10} md={5}>
                    <SimpleSelectField
                        segment={segment}
                        id={id}
                        name={'07-palmarCompression'}
                        label={'Compreessão Palmar'}
                        trueLabel={'Normal'}
                        falseLabel={'Alterado'}
                        control={control}/>
                </Grid>

                <Grid item sm={10} md={5}>
                    <SimpleSelectField
                        segment={segment}
                        id={id}
                        name={'08-hold'}
                        label={'Preensão'}
                        trueLabel={'Normal'}
                        falseLabel={'Alterado'}
                        control={control}/>
                </Grid>

                <Grid item sm={10} md={5}>
                    <SimpleSelectField
                        segment={segment}
                        id={id}
                        name={'09-atrophies'}
                        label={'Presença Atrofias'}
                        control={control}/>
                </Grid>

                <Grid item sm={10} md={5}>
                    <SimpleSelectField
                        segment={segment}
                        id={id}
                        name={'10-operativeScar'}
                        label={'Cicatriz operatória'}
                        trueLabel={'Existente'}
                        falseLabel={'Inexistente'}
                        control={control}/>
                </Grid>

                <Grid item sm={10} md={5}>
                    <SimpleSelectField
                        segment={segment}
                        id={id}
                        name={'11-sensitivity'}
                        label={'Sensibilidade'}
                        trueLabel={'Normal'}
                        falseLabel={'Anormal'}
                        control={control}/>
                </Grid>

                <Grid item sm={10} md={5}>
                    <SimpleSelectField
                        segment={segment}
                        id={id}
                        name={'12-coloring'}
                        label={'Coloração'}
                        trueLabel={'Normal'}
                        falseLabel={'Anormal'}
                        control={control}/>
                </Grid>

                <Grid item sm={10} md={5}>
                    <SimpleSelectField
                        segment={segment}
                        id={id}
                        name={'13-temperature'}
                        label={'Temperatura'}
                        trueLabel={'Normal'}
                        falseLabel={'Anormal'}
                        control={control}/>
                </Grid>

                <Grid item sm={10} md={5}>
                    <SimpleSelectField
                        segment={segment}
                        id={id}
                        name={'14-inflammatorySigns'}
                        label={'Sinais Inflamatórios'}
                        trueLabel={'Existente'}
                        falseLabel={'Inexistente'}
                        control={control}/>
                </Grid>

                <Grid item sm={10} md={5}>
                    <SimpleSelectField
                        segment={segment}
                        id={id}
                        name={'15-muscleChanges'}
                        label={'Alterações Musculares'}
                        trueLabel={'Existente'}
                        falseLabel={'Inexistente'}
                        control={control}/>
                </Grid>

                <Grid item sm={10} md={5}>
                    <SimpleSelectField
                        segment={segment}
                        id={id}
                        name={'16-motorChanges'}
                        label={'Alterações Motoras'}
                        trueLabel={'Existente'}
                        falseLabel={'Inexistente'}
                        control={control}/>
                </Grid>

                <Grid item sm={10} md={5}>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon/>}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography variant={'h6'}>Hiperextensão</Typography>
                        </AccordionSummary>
                        <AccordionDetails>

                            <Grid container spacing={3} justifyContent={'center'} alignContent={'space-between'}>
                                <Grid item sm={10} md={5}>
                                    <MultiOptionSelectField
                                        segment={segment}
                                        id={id}
                                        name={'17-hyperextension.01-firstFinger'}
                                        label={'Graduação 1º dedo'}
                                        control={control}
                                        options={generateProgression(0, 10, 2)}/>
                                </Grid>

                                <Grid item sm={10} md={5}>
                                    <MultiOptionSelectField
                                        segment={segment}
                                        id={id}
                                        name={'17-hyperextension.02-secondFinger'}
                                        label={'Graduação 2º dedo'}
                                        control={control}
                                        options={generateProgression(0, 10, 2)}/>
                                </Grid>

                                <Grid item sm={10} md={5}>
                                    <MultiOptionSelectField
                                        segment={segment}
                                        id={id}
                                        name={'17-hyperextension.03-thirdFinger'}
                                        label={'Graduação 3º dedo'}
                                        control={control}
                                        options={generateProgression(0, 10, 2)}/>
                                </Grid>

                                <Grid item sm={10} md={5}>
                                    <MultiOptionSelectField
                                        segment={segment}
                                        id={id}
                                        name={'17-hyperextension.04-fourthFinger'}
                                        label={'Graduação 4º dedo'}
                                        control={control}
                                        options={generateProgression(0, 10, 2)}/>
                                </Grid>

                                <Grid item sm={10} md={5}>
                                    <MultiOptionSelectField
                                        segment={segment}
                                        id={id}
                                        name={'17-hyperextension.05-fifthFinger'}
                                        label={'Graduação 5º dedo'}
                                        control={control}
                                        options={generateProgression(0, 10, 2)}/>
                                </Grid>
                            </Grid>

                        </AccordionDetails>
                    </Accordion>

                    <Accordion style={{marginTop: '10%'}}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon/>}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography variant={'h6'}>Articulação Metacarpofalangeana</Typography>
                        </AccordionSummary>
                        <AccordionDetails>

                            <Grid container spacing={3} justifyContent={'center'} alignContent={'space-between'}>
                                <Grid item sm={10} md={5}>
                                    <MultiOptionSelectField
                                        segment={segment}
                                        id={id}
                                        name={'18-metacarpophalangealJoint.01-firstFinger'}
                                        label={'Graduação 1º dedo'}
                                        control={control}
                                        options={generateProgression(0, 90, 10)}/>
                                </Grid>

                                <Grid item sm={10} md={5}>
                                    <MultiOptionSelectField
                                        segment={segment}
                                        id={id}
                                        name={'18-metacarpophalangealJoint.02-secondFinger'}
                                        label={'Graduação 2º dedo'}
                                        control={control}
                                        options={generateProgression(0, 90, 10)}/>
                                </Grid>

                                <Grid item sm={10} md={5}>
                                    <MultiOptionSelectField
                                        segment={segment}
                                        id={id}
                                        name={'18-metacarpophalangealJoint.03-thirdFinger'}
                                        label={'Graduação 3º dedo'}
                                        control={control}
                                        options={generateProgression(0, 90, 10)}/>
                                </Grid>

                                <Grid item sm={10} md={5}>
                                    <MultiOptionSelectField
                                        segment={segment}
                                        id={id}
                                        name={'18-metacarpophalangealJoint.04-fourthFinger'}
                                        label={'Graduação 4º dedo'}
                                        control={control}
                                        options={generateProgression(0, 90, 10)}/>
                                </Grid>

                                <Grid item sm={10} md={5}>
                                    <MultiOptionSelectField
                                        segment={segment}
                                        id={id}
                                        name={'18-metacarpophalangealJoint.05-fifthFinger'}
                                        label={'Graduação 5º dedo'}
                                        control={control}
                                        options={generateProgression(0, 90, 10)}/>
                                </Grid>
                            </Grid>

                        </AccordionDetails>
                    </Accordion>
                </Grid>

                <Grid item sm={10} md={5}>
                    <SimpleSelectField
                        segment={segment}
                        id={id}
                        name={'19-segmentOrMember'}
                        label={'A lesão se limita ao segmento ou membro superior?'}
                        trueLabel={'Segmento'}
                        falseLabel={'Membro'}
                        control={control}/>
                </Grid>

                <Grid item sm={10} md={5}>
                    <MultiOptionSelectField
                        segment={segment}
                        id={id}
                        name={'20-graduation'}
                        label={'Graduação'}
                        control={control}
                        options={graduation}/>
                </Grid>
            </Grid>
        </>
    )
}

export default Mao
