import api from 'api'

export const fetchUsers = async (payload) => {
  const { data } = await api.get('/user', payload)

  return data
}

export const fetchUser = async (id) => {
  const { data } = await api.get(`/user/${id}`)

  return data
}

export const createUser = async (payload) => {
	const { data } = await api.post('/user', payload)

	return data
}

export const updateUser = async (id, payload) => {
	const { data } = await api.put(`/user/${id}`, payload)

	return data
}

export const removeUser = async (id) => {
  const { data } = await api.delete(`/user/${id}`)

  return data
}

export const recoverUserPassword = async (payload) => {
  const { data } = await api.post(`/user/recover-password`, payload)

  return data
}

export const newUserPassword = async (payload) => {
  const { data } = await api.put(`/user/recover-password`, payload)

  return data
}