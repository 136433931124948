import PropTypes from 'prop-types'
import { useTable, usePagination,  } from 'react-table'

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core'
import { EmptyState } from 'components'
import {
  checkEmptyState,
  defaultRowIdGetter,
  useFetchNextPage,
  useTableColumns,
  useTableData,
  useUpdateQueryParams,
  useGetCount,
} from './utils'

const PaginationList = ({
  query: {
    data,
    fetchInfo,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching: isLoading,
    setFetchInfo,
  },
  columns,
  getRowId,
  getRowProps,
  rowIdPrefix,
}) => {
  const tableData = useTableData({ data, isLoading })
  const tableColumns = useTableColumns({
    columns,
    isLoading,
  })
  const {
    getTableBodyProps,
    getTableProps,
    headerGroups,
    rows,
    page,
    pageCount,
    prepareRow,
    setPageSize,
    gotoPage,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      autoResetPage: false,
      columns: tableColumns,
      initialState: fetchInfo,
      data: tableData,
      manualSortBy: true,
      disableMultiSort: true,
      disableSortRemove: true,
    },
    usePagination
  )
  useUpdateQueryParams({ pageSize, setFetchInfo })
  useFetchNextPage({ isLoading, pageIndex, pageCount, hasNextPage, fetchNextPage })

  const isEmptyState = checkEmptyState({
    isLoading,
    isFetchingNextPage,
    hasNextPage,
    rows,
  })

  const count = useGetCount(data)

  const handleChangePage = (_, newPage) => {
    gotoPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setPageSize(Number(event.target.value))
  }

  return (
    <TableContainer>
      <Table {...getTableProps()} data-loading={isLoading || isFetchingNextPage}>
        <TableHead>
          {headerGroups.map((headerGroup) => (
            /* eslint-disable react/jsx-key */
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <TableCell {...column.getHeaderProps()}>{column.render('Header')}</TableCell>
              ))}
            </TableRow>
            /* eslint-enable react/jsx-key */
          ))}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row)
            return (
              /* eslint-disable react/jsx-key */
              <TableRow {...row.getRowProps(getRowProps(row))} id={getRowId(row, rowIdPrefix)}>
                {row.cells.map((cell) => {
                  return <TableCell {...cell.getCellProps()}>{cell.render('Cell')}</TableCell>
                })}
              </TableRow>
              /* eslint-enable react/jsx-key */
            )
          })}
        </TableBody>
        {!isEmptyState && (
          <TableFooter>
            <TableRow>
              <TablePagination
                count={count}
                page={pageIndex}
                onPageChange={handleChangePage}
                labelRowsPerPage='Exibir:'
                labelDisplayedRows={labelDisplayedRows}
                rowsPerPageOptions={[10, 30, 50, 100]}
                rowsPerPage={pageSize}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        )}
      </Table>
      {isEmptyState && <EmptyState />}
    </TableContainer>
  )
}

const labelDisplayedRows = ({ from, to, count }) => {
  const id = 'displayed-rows-label'
  return (
    <span id={id} data-testid={id}>
      {`${from}-${to} de ${count}`}
    </span>
  )
}

PaginationList.propTypes = {
  columns: PropTypes.array.isRequired,
  getRowId: PropTypes.func,
  getRowProps: PropTypes.func,
  query: PropTypes.shape({
    hasNextPage: PropTypes.bool,
    data: PropTypes.shape({
      pages: PropTypes.array.isRequired,
    }),
    fetchNextPage: PropTypes.func.isRequired,
    fetchInfo: PropTypes.object,
    isFetchingNextPage: PropTypes.bool,
    isFetching: PropTypes.bool,
    setFetchInfo: PropTypes.func.isRequired,
  }).isRequired,
  rowIdPrefix: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium']),
  skeletonAnimation: PropTypes.oneOf([false, 'pulse', 'wave']),
  skeletonRows: PropTypes.number,
}

PaginationList.defaultProps = {
  getRowId: defaultRowIdGetter,
  getRowProps: () => {},
  rowIdPrefix: 'row',
  size: 'medium',
  skeletonAnimation: 'wave',
  skeletonRows: 5,
}

export default PaginationList
