const generateProgression = (startRange, endRange, progress) => {
    let result = [];
    let currentValue = startRange;

    while (currentValue <= endRange) {
        result.push({label: currentValue + 'º', value: currentValue});
        currentValue += progress;
    }

    return result;
}

export default generateProgression
