import { useState } from 'react'
import { useParams } from 'react-router-dom'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Fab, Button, Typography } from '@material-ui/core'
import { Add as AddIcon } from '@material-ui/icons'
import { useCreateDevolutive } from 'hooks/devolutives'
import { Dialog, InputField } from 'components'
import * as yup from 'yup'

const schema = yup.object().shape({
  title: yup.string().required('Esse campo é obrigatório'),
  tag: yup.string().required('Esse campo é obrigatório').min(2, 'A tag deve ter no mínimo 2 caracteres').matches(/^[^0-9][\w&._]+$/, "A tag não pode começar com números e não pode possuir caracteres especiais ou espaços."),
})

const AddDevolutive = ({ isEmpty }) => {
  const { projectId } = useParams();
  const [open, setOpenDialog] = useState(false)
  const classes = useStyles()
  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    shouldUnregister: true,
  })

  const {
    handleSubmit,
    formState: { errors, isValid, isDirty },
  } = methods

  const { mutate: onSubmit, isLoading } = useCreateDevolutive(projectId, {
    onSuccess: () => setOpenDialog(false),
  })

  return (
    <>
      {isEmpty ? (
        <Button
          className={classes.button}
          onClick={() => setOpenDialog(true)}
          variant='contained'
          startIcon={<AddIcon />}
          color='primary'
        >
          Adicionar a primeira Devolutiva
        </Button>
      ) : (
        <Fab color='primary' aria-label='add' onClick={() => setOpenDialog(true)}>
          <AddIcon />
        </Fab>
      )}

      <FormProvider {...methods}>
        <Dialog
          title='Adicionar Devolutiva'
          contentText='Preencha as informações para cadastrar a nova Devolutiva. Essas informações poderão ser alteradas depois.'
          open={open}
          onCancel={() => setOpenDialog(false)}
          isLoading={isLoading}
          submitDisabled={!isValid || !isDirty}
          onSubmit={handleSubmit(onSubmit)}
        >
          <InputField id='title' name='title' label='Título' error={errors?.title} required fullWidth />
          { errors?.title && <Typography color="error" variant="span">{errors.title.message}</Typography> }
          
          <InputField
            id='tag'
            name='tag'
            label='Tag da Devolutiva'
            variant='outlined'
            error={errors?.tag}
            required
            fullWidth
          />
          { errors?.tag && <Typography color="error" variant="span">{errors.tag.message}</Typography> }
        </Dialog>
      </FormProvider>
    </>
  )
}

const useStyles = makeStyles(() => ({
  button: {
    display: 'flex',
    margin: '20px auto',
  },
}))

AddDevolutive.propTypes = {
  isEmpty: PropTypes.bool,
}

AddDevolutive.defaultProps = {
  isEmpty: false
}

export default AddDevolutive
