import { MenuItem } from "@material-ui/core"
import { InputField } from "components"
import { operators } from "enum/operators"
import { get } from "lodash"
import { useFormContext } from "react-hook-form"



export default ({ metrics = {}, path}) => {
    const { control, getValues } = useFormContext()

    const getResultsByMetricId = (metricId) => {
        if (metricId) {
          return Object.keys(metrics).map((m) => ({
              id: metrics[m].id, 
              results: Object.keys(metrics[m].results).map((r) => (
                  {label: r, value: metrics[m].results[r]}) 
                  )}
            )).find(metric => metric.id === metricId)
        }
        return []
      }
      const getResults = (metric) => {
          return get(getResultsByMetricId(metric),
            "results", 
            []
           );
      }
    return (
        <>
        <InputField
            id='value_of_id'
            size='small'
            fullWidth
            name={`${path}.value_of_id`}
            label='Metricas'
            defaultValue={getValues(`${path}.value_of_id`)}
            
            disableMargin
            select
        >
            {Object.keys(metrics).map(
                (opt) => (
                    <MenuItem key={opt} value={metrics[opt].id}>
                        {metrics[opt].name}
                    </MenuItem>
                )
            )}

        </InputField>
        <InputField
            id='operador'
            name={`${path}.operator`}
            control={control}
            defaultValue={getValues(`${path}.operator`)}
            size='small'
            variant='outlined'
            label='Operador'
            disableMargin
            fullWidth
            select
        >
            {operators.map((opt) => (
            <MenuItem key={opt.value} value={opt.value}>
                {opt.label}
            </MenuItem>
            ))}
        </InputField>
        <InputField
            size='small'
            variant='outlined'
            fullWidth
            label='Valor'
            select
            name={`${path}.value`}
            control={control}
            defaultValue={getValues(`${path}.value`)}
            disableMargin
        >
            <MenuItem value={""}>
            Nenhum valor
            </MenuItem>
            {
            (getResults(getValues(`${path}.value_of_id`)).map((opt) => (
                <MenuItem key={opt.value} value={opt.value}>
                {opt.label}
                </MenuItem>
            )))
            }
        </InputField>
        </>
    )
}