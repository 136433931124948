import { useState } from 'react'
import PropTypes from 'prop-types'
import { Tooltip, IconButton } from '@material-ui/core'
import { Delete as DeleteIcon } from '@material-ui/icons'
import { useRemoveUser } from 'hooks/useRemoveUser'
import { Dialog, } from 'components'

const RemoveUser = ({ userId, name }) => {
  const [open, setOpenDialog] = useState(false)

  const { mutate: remove, isLoading } = useRemoveUser({
    onSuccess: () => setOpenDialog(false),
  })

  return (
    <>
      <Tooltip title="Excluir usuário">
        <IconButton size='small' onClick={() => setOpenDialog(true)} edge='end' aria-label='delete'>
          <DeleteIcon />
        </IconButton>
      </Tooltip>
      <Dialog
        title='Excluir Usuário'
        contentText={`Você tem certeza que deseja excluir o usuário "${name}"?`}
        open={open}
        onCancel={() => setOpenDialog(false)}
        isLoading={isLoading}
        onSubmit={() => remove(userId)}
        submitLabel="Excluir"
      />
    </>
  )
}

RemoveUser.propTypes = {
  name: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
}

RemoveUser.defaultProps = {}

export default RemoveUser
