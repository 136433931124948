import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useFormContext } from 'react-hook-form'
import { isEmpty } from 'lodash'
import { Editor } from 'ckeditor5-custom-build/build/ckeditor'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import { useFields } from 'hooks/fields'
import { useFeeds } from 'hooks/texts'
import TextForOptions from './TextForOptions'
import { InputField } from 'components'
import { getKeyValue } from 'eslint-plugin-react/lib/util/ast'
const useStyles = makeStyles(() => ({
  wrapper: {
    cursor: 'default'
  },
  editor: {
    width: '100px',
  },
}))

const Texts = () => {
  const { setValue, watch, getValues } = useFormContext()
  const { fields } = useFields()
  const feed = useFeeds(fields)

  const classes = useStyles()

  const editorValue = watch('text');
  const options = watch('options');

  const editorConfiguration = {
    toolbar: {
      shouldNotGroupWhenFull: true,
      items: [
        'heading',
        '|',
        'alignment',
        '|',
        'bold',
        'italic',
        'strikethrough',
        'underline',
        '|',
        'link',
        '|',
        'bulletedList',
        'numberedList',
        '-',
        'fontFamily',
        'fontSize',
        'fontColor',
        'fontBackgroundColor',
        '|',
        'outdent',
        'indent',
        '|',
        'undo',
        'redo',
        '|',
        'removeFormat',
        'horizontalLine',
      ],
    },
    image: {
      styles: ['alignLeft', 'alignCenter', 'alignRight'],
      resizeOptions: [
        {
          name: 'resizeImage:original',
          label: 'Original',
          value: null,
        },
        {
          name: 'resizeImage:50',
          label: '50%',
          value: '50',
        },
        {
          name: 'resizeImage:75',
          label: '75%',
          value: '75',
        },
      ],
      toolbar: [
        'imageStyle:alignLeft',
        'imageStyle:alignCenter',
        'imageStyle:alignRight',
        '|',
        'resizeImage',
        '|',
        'imageTextAlternative',
      ],
    },
    table: {
      contentToolbar: [
        'tableColumn',
        'tableRow',
        'mergeTableCells',
        'tableCellProperties',
        'tableProperties',
      ],
    },
    licenseKey: '',
    fontSize: {
      options: [12, 14, 16, 18, 21, 24],
      supportAllValues: true,
    },
    uicolor: { height: '800px' },
    mediaEmbed: {
      removeProviders: ['instagram', 'twitter', 'googleMaps', 'flickr', 'facebook'],
    },
    mention: {
      feeds: [
          {
              marker: '{',
              feed,
          }
      ]
    }
  }

  return (
    <Box className={classes.wrapper}>

      {!isEmpty(options) ? (
        <TextForOptions options={options} />
      ) : (
        <InputField
          value={getValues('text')}
          onChange={(e, editor) => {
            const data = e.target.value
            // const data = editor.getData()
            setValue('text', data)
          }}
          multiline
        />
      )}
    </Box>
  )
}

Texts.propTypes = {
  // texts: PropTypes.node,
}

Texts.defaultProps = {
  // texts: null,
}

export default Texts
