import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { Paper } from '@material-ui/core'
import { useForm, FormProvider } from 'react-hook-form'
import { isEmpty } from 'lodash'
import { useUpdateResult, useRemoveMetric } from 'hooks/metrics'
import { Accordion, Loader } from 'components'
import ResultForm from './result-form/ResultForm'

const Group = ({ item, isDragging, project, results }) => {
  const methods = useForm({
    mode: 'onTouched',
    defaultValues: item,
  })
  const { id, title } = item

  const { mutate: onSubmit, isLoading: isLoadingUpdate } = useUpdateResult(id)
  const { mutate: onRemove, isLoading: isLoadingRemove } = useRemoveMetric(id)

  const classes = useStyles()

  const metricResults = (metricId) => {
    const elements = results.filter(result => { 
      return result.metric_id === metricId
    })

    return elements
  }

  const isDeleted = (data, value, prop) => {
    const elements = []
    const newData = data.filter(elem => !value.some(newItem => newItem[prop] === elem[prop]))

    value.forEach(elem => {
      const newValue = data.find(newItem => newItem[prop] === elem[prop])
      
      if (isEmpty(newValue)) {
        elements.push({ ...elem, deleted_at: new Date })
      } else {
        elements.push({ ...elem, ...newValue })
      }
    })

    newData.forEach(el => elements.push(el))
    
    return elements
  }

  return (
    <Accordion
      key={id}
      id={id}
      title={title}
      onSave={
        methods.handleSubmit((data) => {
          onSubmit(data)
        })
      }
      onDelete={() => onRemove(id)}
      isDragging={isDragging}
      summaryProps={{
        id,
        item,
      }}
    >
      <FormProvider {...methods}>
        <Paper className={classes.paper}>
          <div className={classes.content}>
            {isLoadingUpdate || isLoadingRemove ? <Loader size={80} /> : <ResultForm data={item} project={project} results={metricResults(id)} />}
          </div>
        </Paper>
      </FormProvider>
    </Accordion>
  )
}

const useStyles = makeStyles(() => ({
  paper: {
    padding: '20px',
    paddingBottom: '5px',
  },
  content: {
    '& > :first-child': {
      minHeight: '255px',
      marginBottom: '20px',
    },
  },
}))

Group.propTypes = {
  isDragging: PropTypes.bool.isRequired,
  item: PropTypes.object.isRequired,
  project: PropTypes.object.isRequired,
  results: PropTypes.array.isRequired,
}

Group.defaultProps = {}

export default Group
