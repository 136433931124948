import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import TabContent from "../../tabs/TabContent";

import './exam.css';
import { Button } from '@material-ui/core';
import { useBreakpoint } from 'use-breakpoint';

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

const a11yProps = (index) => {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        maxWidth: '480px',
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
}));

const BREAKPOINTS = { responsive: 0, desktop: 982 }

const ExamTabs = ({ items, tabsHandler }, ...props) => {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const { breakpoint } = useBreakpoint(
        BREAKPOINTS,
        'desktop',
    )

    return (
            <div className="exam-form">
                {
                    breakpoint == 'responsive' &&
                        <Box className="back-button">
                            <Button size='small' color='primary' variant='contained' onClick={() => tabsHandler.setOpen(false)}>Voltar</Button>
                        </Box>
                }
                <div className={classes.root} >
                    <AppBar position="static" color="default">
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="scrollable"
                            scrollButtons="auto"
                            aria-label="scrollable auto tabs example"
                        >
                            {items.map((item, index) => (
                                <Tab key={item.name} label={item.name} {...a11yProps(0)} />
                            ))}
                        </Tabs>
                    </AppBar>
                    {items.map((item, index) => (
                        <TabContent key={item.name} value={value} index={index} style={{width: '65.5vh', boxShadow: 'rgba(0, 0, 0, 0.1) 0px 6px 9px 1px' }} >
                            {item.component}
                        </TabContent>
                    ))}
                </div>
            </div>
    );
}

export default ExamTabs

