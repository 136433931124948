import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { useFormContext, useFieldArray } from 'react-hook-form'
import { isEmpty } from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import { makeStyles } from '@material-ui/core/styles'
import {
  List,
  ListItem,
  ListItemIcon,
  Button,
  IconButton,
  Typography,
  Divider
} from '@material-ui/core'
import { Add as AddIcon, Edit as EditIcon, Delete as DeleteIcon } from '@material-ui/icons/'


import { Dialog, InputField } from 'components'

import DisplayRules from 'components/display-rules/DisplayRules'

const ResultForm = ({ data, project }) => {
  const [open, setOpen] = React.useState(0)
  const { id: metric_id } = data
  const { control } = useFormContext()
  const { forms, customer, metrics } = project
  const {
    fields: fieldRules,
    append,
    remove,
  } = useFieldArray({
    control,
    name: 'values',
  })
  const classes = useStyles()

  if (isEmpty(fieldRules)) {
    return (
      <div>
        <Typography variant='h6' color='inherit' noWrap>
          Nenhuma composição cadastrada
        </Typography>

        <Button
          className={classes.button}
          variant='contained'
          size='small'
          color='primary'
          startIcon={<AddIcon />}
          onClick={() => {
            append({
              id: uuidv4(),
              metric_id: metric_id,
              value: '',
            })
          }}
        >
          Adicionar composição
        </Button>
      </div>
    )
  }


  return (
    <div className={classes.wrapper}>
      <Typography variant='h6' color='inherit' noWrap>
        Composição da Métrica:
      </Typography>

      <List disablePadding>
        {fieldRules.map((rule, index) => (
          <>
          <Fragment key={rule.id}>
            <ListItem disableGutters dense alignItems='center'>
              <ListItemIcon className={classes.root}>
                <IconButton onClick={() => remove(index)} edge='start' aria-label='delete'>
                  <DeleteIcon />
                </IconButton>
              </ListItemIcon>
            <InputField
              fullWidth
              className={classes.inputDescription}
              size='small'
              variant='outlined'
              label='Descrição'
              name={`values.${index}.description`}
              control={control}
              disableMargin
            />
            <Button
              className={classes.buttonEdit}
              variant='contained'
              size='small'
              color='primary'
              startIcon={<EditIcon />}
              onClick={() => setOpen(index + 1)}
            >
              Editar valores
            </Button>
            </ListItem>
              <Dialog
                width="500px"
                title='Editar Valores da Regra'
                noSubmit
                cancelLabel='Fechar'
                open={open == (index + 1)}
                onCancel={() => setOpen(0)}
                onSubmit={() => console.log('jeej')}
              >
              <ListItem disableGutters dense>
                <InputField
                  fullWidth
                  className={classes.inputResult}
                  size='small'
                  variant='outlined'
                  type='number'
                  label='Valor'
                  name={`values.${index}.value`}
                  control={control}
                  disableMargin
                />
              </ListItem>
              <DisplayRules forms={forms} customer={customer} metrics={metrics} formHooks={{enabled: true, basePath: `values.${index}`}}/>
            </Dialog>
          </Fragment>
          <Divider style={{marginTop: '15px'}}/>
          </>
        ))}
      </List>
      <Button
        className={classes.buttonAdd}
        variant='contained'
        size='small'
        color='primary'
        startIcon={<AddIcon />}
        onClick={() => {
          append({
            metric_id: metric_id,
            id: uuidv4(),
            value: '',
          })
        }}
      >
        Adicionar composição
      </Button>
    </div>
  )
}

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  button: {
    margin: '10px 0px',
    maxWidth: '250px',
  },
  buttonAdd: {
    marginTop: '25px',
    maxWidth: '250px',
  },
  buttonEdit: {
    marginTop: 'auto',
    marginBottom: 'auto',
    marginLeft: '20px',
    maxWidth: '250px',
  },
  root: {
    '&.MuiListItemIcon-root': {
      minWidth: 'auto',
    },
  },
  inputOperator: {
    maxWidth: '130px',
  },
  inputValue: {
    maxWidth: '100px',
  },
  inputResult: {
    maxWidth: '200px',
  },
  inputDescription: {
    maxWidth: '450px',
    marginTop: '15px',
    marginBottom: '15px',
  },
}))

ResultForm.propTypes = {
  data: PropTypes.object.isRequired,
  project: PropTypes.object.isRequired,
  results: PropTypes.array.isRequired,
}

ResultForm.defaultProps = {}

export default ResultForm
