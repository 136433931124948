import PropTypes from 'prop-types'
import clsx from 'clsx'
import { isEmpty, get } from 'lodash'
import { useFormContext, Controller } from 'react-hook-form'
import RIMInputMask from 'react-input-mask'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'
import inputStyle from './input-style'

const InputMask = ({ defaultValue, name, variant, disableMargin, mask, beforeMaskedValueChange, inputProps, ...rest }) => {
  const {
    control,
    errors,
    formState: { isSubmitting },
  } = useFormContext()
  const classes = useStyles()

  const helperText = get(errors, [name, 'message'], null)

  return (
    <Controller
      render={({ field }) => (
        <RIMInputMask {...field} mask={mask} alwaysShowMask={false} maskChar='' beforeMaskedValueChange={beforeMaskedValueChange}>
          {(inputMaskProps) => (
            <TextField
              className={clsx({
                [classes.customTextField]: !disableMargin,
              })}
              InputLabelProps={ { style: {
                  position: 'relative', 
                  marginBottom: '5px',
                  transform: 'translate(0, 0) scale(1)',
                  textAlign: 'justify',
                  lineHeight: '24px'
                }, 
                shrink: false,
              }}
              variant={variant}
              error={!isEmpty(helperText)}
              helperText={helperText}
              InputProps={{
                ...inputMaskProps,
                ...inputProps,
              }}
              {...rest}
            />
          )}
        </RIMInputMask>
      )}
      name={name}
      control={control}
      defaultValue={defaultValue}
      disabled={isSubmitting}
    />
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '10px 0px',
  },
  customTextField: inputStyle(theme)
}))

InputMask.propTypes = {
  defaultValue: PropTypes.string,
  disableMargin: PropTypes.bool,
  inputProps: PropTypes.object,
  mask: PropTypes.string,
  name: PropTypes.string.isRequired,
  variant: PropTypes.string,
}

InputMask.defaultProps = {
  defaultValue: '',
  disableMargin: false,
  inputProps: {},
  mask: '*',
  variant: 'outlined',
}

export default InputMask
