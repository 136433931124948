import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useParams } from 'react-router-dom'
import { usePreferences } from 'hooks/preferences'
import { Loader } from 'components'
import ThemeForm from './ThemeForm'
import ThemePreview from './ThemePreview'

const Preferences = () => {

  const { projectId } = useParams();
  const { isLoading } = usePreferences(projectId)
  const classes = useStyles()

  if (isLoading) {
    return <Loader size={100}/>
  }

  return (
    <div className={classes.wrapper}>
      <ThemeForm />
      <ThemePreview />
    </div>
  )
}

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
  },
  previewWrapper: {
    marginTop: '30px'
  },
  textPreview: {
    padding: '20px 0'
  },
  paper: {
    margin: '20px 40px',
    padding: '20px',
    width: '20%'
  },
  item: {
    marginBottom: '20px',
  },
}))

Preferences.propTypes = {}

Preferences.defaultProps = {}

export default Preferences
