import { useMemo } from 'react'
import { isEmpty } from 'lodash'
import { makeStyles } from '@material-ui/core/styles'
import { useSteps } from 'hooks/steps'
import { useFields, useReorderFields } from 'hooks/fields'
import { DraggableList, EmptyState, Loader } from 'components'

import RegisteredItem from './registered-item/RegisteredItem'
import AddField from './add-field/AddField'

const RegisteredFields = ({project}) => {
  const { selected: stepId } = useSteps()
  const { fields } = useFields(stepId)
  const { mutate: onReorder, isLoading } = useReorderFields(stepId)
  const classes = useStyles()

  const order = useMemo(() => {
    if (isEmpty(fields)) return 1

    const order = fields.map(({ order }) => order).reduce((a, b) => Math.max(a, b))
    return order + 1
  }, [fields])

  return (
    <>
      {isEmpty(fields) ? (
        <EmptyState />
      ) : (
        <>
        {isLoading ?
        <Loader size={100} minHeight='150px' minWidth='550px' />
        :
        <DraggableList items={fields} onReorder={onReorder}>
          {({ ref, item, snapshotProps, ...rest }) => (
            <div className={classes.item} ref={ref} {...rest}>
              <RegisteredItem project={project} item={item} isDragging={snapshotProps.isDragging} />
            </div>
          )}
        </DraggableList>
        }
        </>
      )}
      <AddField order={order} isEmpty={isEmpty(fields)} />
    </>
  )
}

const useStyles = makeStyles(() => ({
  item: {
    marginBottom: '20px',
  },
}))

RegisteredFields.propTypes = {}

RegisteredFields.defaultProps = {}

export default RegisteredFields
