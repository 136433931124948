import {InputField} from "../../index";
import {MenuItem} from "@material-ui/core";
const MultiOptionSelectField = ({
     control,
     segment,
     id,
     name,
     label,
     options = [{label:'label 1', value: '1'}]
    }) =>{
    return(
        <>
            <InputField select
                        id={`${id}.${segment}.${name}`}
                        name={`${id}.${segment}.${name}`}
                        label={label}
                        control={control}>
                {options.map((option) => (
                    <MenuItem value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </InputField>
        </>
    )
}

export default MultiOptionSelectField
