import PropTypes from 'prop-types'
import clsx from 'clsx'
import { isEmpty, get } from 'lodash'
import { useFormContext, Controller } from 'react-hook-form'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'
import inputStyle from './input-style'

const InputField = ({ defaultValue, name, variant, disableMargin, multiline, number, ...rest }) => {
  const {
    control,
    errors,
    formState: { isSubmitting },
  } = useFormContext()
  const classes = useStyles()

  const helper_text = get(errors, [name, 'message'], null)

  return (
    <Controller
      render={({ field }) => (
        <TextField
          className={clsx({
            [classes.customTextField]: !disableMargin,
          })}
          InputLabelProps={ { style: {
              position: 'relative', 
              marginBottom: '5px',
              transform: 'translate(0, 0) scale(1)',
              textAlign: 'justify',
              lineHeight: '24px'
            }, 
            shrink: false,
          }}
          variant={variant}
          error={!isEmpty(helper_text)}
          helperText={helper_text}
          disabled={isSubmitting}
          multiline={multiline}
          minRows={4}
          type={number && 'number' }
          fullWidth
          {...field}
          {...rest}
        />
      )}
      name={name}
      control={control}
      defaultValue={defaultValue}
    />
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(10),
    marginRight: theme.spacing(1),
    width: '10%'
  },
  dense: {
    marginTop: theme.spacing(2),
  },
  menu: {
    width: 100,
  },
  root: {
    margin: '10px 0px',
  },
  customTextField: inputStyle(theme)
}));

// const useStyles = makeStyles(() => ({
// }))

InputField.propTypes = {
  defaultValue: PropTypes.string,
  disableMargin: PropTypes.bool,
  multiline: PropTypes.bool,
  name: PropTypes.string.isRequired,
  number: PropTypes.bool,
  variant: PropTypes.string,
}

InputField.defaultProps = {
  defaultValue: '',
  disableMargin: false,
  multiline: false,
  number: false,
  variant: 'outlined',
}

export default InputField
