import { Dialog, InputField, Checkbox } from 'components'
import {IconButton, MenuItem, Tooltip} from "@material-ui/core";
import {ControlPointDuplicateRounded as DuplicateIcon} from "@material-ui/icons";
import PropTypes from "prop-types";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import useAllProjects from 'hooks/useAllProjects';
import { useParams } from 'react-router-dom';


const ReplicateForm = ({ formId, saveHandle}) => {
    const [ open, setOpenDialog ] = useState(false)
    const [ loading, setLoading ] = useState(false)
    const { projectId } = useParams()
    const { data } = useAllProjects({
        onSuccess: (data) => console.log(data)
      })
    const methods = useForm({
        defaultValues: {
            results: false,
            newName: "",
            projectId: projectId
        }
    })
    const {
        handleSubmit,
        formState: { errors },
      } = methods
    
    const mutate = async (data) => {
        setLoading(true)
        const ret = await saveHandle({
            formId,
            ...data
        })
        if (ret) {
            setOpenDialog(false)
        }
        setLoading(false)
    }

    return (
        <>
            <Tooltip title="Duplicar projeto">
                <IconButton size='small' edge='end' color='primary' aria-label='edit' onClick={() => setOpenDialog(!open)}>
                    <DuplicateIcon/>
                </IconButton>
            </Tooltip>
            <FormProvider {...methods}>
            <Dialog
            title={"Duplicar"}
            contentText={"Duplicar formulario completo com questoes e metricas associadas as questões"}
            open={open}
            isLoading={loading}
            onCancel={() => setOpenDialog(false)}
            onSubmit={handleSubmit((data) => mutate(data))}
            >
                <InputField
                    id='newName'
                    name='newName'
                    label='Nome do novo Formulário'
                    variant='outlined'
                    error={errors?.newName ? true : false}
                    required
                    fullWidth
                    InputProps = {{ maxLength:255 }}
                />

                <Checkbox 
                    id='results'
                    name='results'
                    label='Duplicar tambem resultados + metricas associadas a este formulario'
                    variant='outlined'
                    error={errors?.results ? true : false}
                    fullWidth
                />
                {(data && data.projects && data.projects.length > 1) &&
                        <InputField id='projectId' 
                            name='projectId' 
                            label='Enviar copia para outro projeto' 
                            required 
                            fullWidth
                            select>
                            {data.projects.map((p) => (
                                <MenuItem key={p.id} value={p.id}>
                                    {p.title}    
                                </MenuItem>
                            ))}
                            
                        </InputField>
                    }
                
            </Dialog>
            </FormProvider>
            </>
    )
}

ReplicateForm.propTypes = {
    formId: PropTypes.string.isRequired,
    saveHandle: PropTypes.func.isRequired,
}

ReplicateForm.defaultProps = {}

export default ReplicateForm;